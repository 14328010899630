@import "style-guide/_typography";
$shadowLength: 20px;

.notification-holder {
    position: absolute;
    top: 200px;
    right: 0;
    z-index: 199;
    max-width: 600px;
    overflow: hidden;
    padding: $shadowLength;
    padding-right: 0;
    min-height: 75px;
    transition-duration: .4s;
    transition-property: height, min-height, opacity;
    transition-timing-function: ease-out;

    @include breakpoint-tablet-down() {
        padding: 0 0 $shadowLength 0;
        max-width: unset;
        width: 100%;
        bottom: unset;
        top: var( --page-header-height );
        right: unset;
    }

    .overlay-notification {
        display: flex;
        position: relative;
        width: 100%;
        background-color: white;
        transition-duration: .4s;
        transition-property: transform;
        transition-timing-function: ease-out;
        box-shadow: 0px 0px $shadowLength 2px black;

        .notification-image {
            max-height: 145px;
        }

        @include mq(tablet) {
            min-height: 93px;
        }

        .notification-content {
            padding: 15px;
            margin-right: 25px;
            line-height: normal;

            @include mq(tablet) {
                text-align: center;
            }
        }

        .closeBtn {
            width: 15px;
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;

            img {
                width: 100%;
                filter: invert(1); //make white png black
            }
        }

        button {
            position: relative;
            top: 0;
            left: 0;
            margin: 10px;
            max-width: 100%;
            min-width: 150px;
            white-space: normal;
            height: unset;
            font-size: 15px;
            line-height: 17px;
            padding: 8px;
            //font-family: "Open Sans", sans-serif;
            //letter-spacing: normal;
            //word-spacing: 2px;
            .button-wrap {
                margin-left: 10px;
                margin-right: 10px;
            }
            &:hover {
                top: -1px;
                left: -1px;
            }
        }
    }


    &.closed {
        pointer-events: none;
        opacity: 0;

        .overlay-notification {
            transform: translateX(110%);
        }

        @include mq(tablet) {
            min-height: 0;
            height: 0;

            .overlay-notification {
                transform: translateY(-110%);
            }
        }
    }

    /////
    .overlay-notification {
        &.pg-login, &.pg-link, &.pg-claim, &.pg-in-game {
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                font-weight: bold;
                color: rgb(61, 61, 61);
                font-size: 16px;
                margin-bottom: 0;
            }

            button {
                color: white;
                background-color: rgb(100, 65, 165);
                margin-bottom: 0;

                &:hover {
                    background-color: rgb(110, 71, 182);
                }
            }
        }

        &.pg-in-game {
            @include mq(tablet) {
                background-image: url('../images/notifications/primeGaming/pgKeyArt.png');

                .closeBtn {
                    top: 20px;

                    img {
                        filter: invert(0);
                    }
                }
            }

            .lotusContainer {
                align-items: center;

                &.desktop {
                    display: flex;
                    @include mq(tablet) {
                        display: none;
                    }
                }

                &.mobile {
                    display: none;
                    @include mq(tablet) {
                        display: flex;
                    }
                }
            }

            .notificationWrapper {
                display: grid;
                grid-template-columns: 45.5px 1fr;
                gap: 15px;

                @include mq(tablet) {
                    p {
                        color: white;
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                    border-radius: 10px;
                }

                p {
                    display: flex;
                    align-items: center;
                }
            }
        }

        &.prime-resurgence {
            font-weight: bold;
            .cta {
                button {
                    background: linear-gradient(0.35turn, #f9f9d6, #ac9945, #f9f9d6, #ac9945);
                    box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.2);
                    .button-wrap {
                        color: black;
                        font-weight: bold;
                    }
                }
            }
        }

        &.yir2024 {
            $COLOR_YIR_ACCENT: #DDC57D;
            --color-button-background: #{ $COLOR_YIR_ACCENT };
            --color-button-background-hover: white;
            --color-button-text: black;
            --color-button-text-hover: black;
            background-color: #121212;
            overflow: hidden;
            position: relative;

            &::before {
                content: '';
                aspect-ratio: 24/13;
                width: 460px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate( -152px, -53% );
                background-image: url( '/images/promo/year-in-review/2024/starchart-only-lines.svg' );
                background-repeat: no-repeat;
                background-size: cover;
            }

            &, p {
                color: white;
                font-weight: 700;
                font-size: $FONT_SIZE_H6;
                text-transform: uppercase;
                text-align: left;
            }
            p > span {
                color: $COLOR_YIR_ACCENT;
            }
            .closeBtn img {
                filter: none;
            }
            a.cta {
                margin-top: $SPACE_MEDIUM;
                font-size: $FONT_SIZE_H6;
                cursor: pointer;
                &:hover {
                    text-decoration: none;
                }
                &:visited {
                    color: black;
                }
            }
            .notification-image-wrapper {
                width: 150px;
                display: flex;
                justify-content: center;
                align-content: center;
                position: relative;
                &::before {
                    content: '';
                    width: 80px;
                    height: 80px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate( -50%, -50% );
                    background-image: radial-gradient(
                        closest-side,
                        rgba( $COLOR_YIR_ACCENT, 0.4 ) 0,
                        rgba( $COLOR_YIR_ACCENT, 0 ) 80%
                    );
                }
                &> img {
                    position: relative;
                    width: 80px;
                    height: auto;
                    max-height: none;
                }
            }
            .notification-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-right: 50px;
                padding-top: $SPACE_BASE * 3;
                padding-bottom: $SPACE_BASE * 3;
            }
        }
    }
}
