//@import "compass/css3/transition";

.cd-header {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #21272c;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@media only screen and (min-width: 768px) {
    .cd-header {
        height: 70px;
        background-color: transparent;
    }
}

#cd-logo {
    float: left;
    margin: 13px 0 0 5%;

    img {
        display: block;
    }
}
@media only screen and (min-width: 768px) {
    #cd-logo {
        margin: 23px 0 0 5%;
    }
}

.cd-primary-nav {
    /* mobile first - navigation hidden by default, triggered by tap/click on navigation icon */
    float: right;
    margin-right: 5%;
    width: 44px;
    height: 100%;

    ul {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        //@include transform(translateY(-100%));
        transform: translateY(-100%);

        &.is-visible {
            box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
            //@include transform(translateY(50px));
            transform: translateY(50px);
        }
    }

    a {
        display: block;
        height: 50px;
        line-height: 50px;
        padding-left: 5%;
        background: #21272c;
        border-top: 1px solid #333c44;
        color: #ffffff;
    }
}

@media only screen and (min-width: 768px) {
    .cd-primary-nav {
        /* reset navigation values */
        width: auto;
        height: auto;
        background: none;

        ul {
            position: static;
            width: auto;
            //@include transform(translateY(0));
            transform: translateY(0);
            line-height: 70px;

            &.is-visible {
                //@include transform(translateY(0));
                transform: translateY(0);
            }
        }

        li {
            display: inline-block;
            margin-left: 1em;
        }

        a {
            display: inline-block;
            height: auto;
            font-weight: 600;
            line-height: normal;
            background: transparent;
            padding: .6em 1em;
            border-top: none;
        }
    }
}

.cd-hero {
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cd-hero-slider {
    position: relative;
    height: 521px;
    overflow: hidden;

    @include mq(hd){
        height: 660px;
    }

    li {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //@include transform(translateX(100%));
        transform: translateX(100%);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

        &.selected {
            /* this is the visible slide */
            position: relative;
            //@include transform(translateX(0));
            transform: translateX(0);
        }

        &.move-left {
            /* slide hidden on the left */
            //@include transform(translateX(-100%));
            transform: translateX(-100%);
        }

        &.is-moving,&.selected{
            /* the is-moving class is assigned to the slide which is moving outside the viewport */
            -webkit-transition: -webkit-transform 0.5s;
            -moz-transition: -moz-transform 0.5s;
            transition: transform 0.5s;
        }

        &:first-of-type {
            background-color: #2c343b;
        }
        &:nth-of-type(2) {
            background-color: #3d4952;
        }
        &:nth-of-type(3) {
            background-color: #586775;
        }
        &:nth-of-type(4) {
            background-color: #2c343b;
        }
        &:nth-of-type(5) {
            background-color: #2c343b;
        }
    }

    .cd-full-width,.cd-half-width{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        left: 0;
        top: 0;
        /* this padding is used to align the text */
        padding-top: 100px;
        text-align: center;
        /* Force Hardware Acceleration in WebKit */
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        //@include transform(translateZ(0));
        transform: translateZ(0);
    }

    .cd-img-container{
        display: none;

        img{
            position: absolute;
            left: 50%;
            top: 50%;
            bottom: auto;
            right: auto;
            //@include transform(translateX(-50%) translateY(-50%));
            transform: translateX(-50%) translateY(-50%);
        }
    }

    .cd-bg-video-wrapper {
        /* hide video on mobile device */
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        video {
            /* you won't see this element in the html, but it will be injected using js */
            display: block;
            min-height: 100%;
            min-width: 100%;
            max-width: none;
            height: auto;
            width: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            bottom: auto;
            right: auto;
            //@include transform(translateX(-50%) translateY(-50%));
            transform: translateX(-50%) translateY(-50%);
        }
    }

    h2,p{
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        line-height: 1.2;
        margin: 0 auto 14px;
        color: #ffffff;
        width: 90%;
        max-width: 400px;
    }

    h2{
        font-size: 2.4rem;
    }

    p{
        font-size: 1.4rem;
        line-height: 1.4;
    }

    .cd-btn.secondary {
        background-color: rgba(22, 26, 30, 0.8);
    }

    .cd-btn:nth-of-type(2) {
        margin-left: 1em;
    }
}

@media only screen and (min-width: 768px) {
    .cd-hero-slider li:nth-of-type(2) {
        background-image: none;
    }
    .cd-hero-slider li:nth-of-type(3) {
        background-image: none;
    }
    .cd-hero-slider li:nth-of-type(4) {
        background-image: none;
    }
    .cd-hero-slider .cd-full-width,
    .cd-hero-slider .cd-half-width {
        padding-top: 100px;
    }
    .cd-hero-slider .cd-bg-video-wrapper {
        display: block;
    }
    .cd-hero-slider .cd-half-width {
        width: 45%;
    }
    .cd-hero-slider .cd-half-width:first-of-type {
        left: 5%;
    }
    .cd-hero-slider .cd-half-width:nth-of-type(2) {
        right: 5%;
        left: auto;
    }
    .cd-hero-slider .cd-img-container {
        display: block;
    }
    .cd-hero-slider h2, .cd-hero-slider p {
        max-width: 520px;
    }
    .cd-hero-slider h2 {
        font-size: 2.4em;
        font-weight: 300;
    }
    .cd-hero-slider .cd-btn {
        font-size: 1.4rem;
    }
}
@media only screen and (min-width: 1170px) {
    .cd-hero-slider .cd-full-width,
    .cd-hero-slider .cd-half-width {
        padding-top: 160px;
    }
    .cd-hero-slider h2, .cd-hero-slider p {
        margin-bottom: 20px;
    }
    .cd-hero-slider h2 {
        font-size: 3.2em;
    }
    .cd-hero-slider p {
        font-size: 1.6rem;
    }
}

@media only screen and (min-width: 768px) {
    .cd-hero-slider .cd-half-width {
        opacity: 0;
        //@include transform(translateX(40px));
        transform: translateX(40px);
    }
    .cd-hero-slider .move-left .cd-half-width {
        //@include transform(translateX(-40px));
        transform: translateX(-40px);
    }
    .cd-hero-slider .selected .cd-half-width {
        opacity: 1;
        //@include transform(translateX(0));
        transform: translateX(0);
    }
    .cd-hero-slider .is-moving .cd-half-width {
        //@include transition(opacity 0s 0.5s, transform 0s 0.5s);
        transition: opacity 0s 0.5s, transform 0s 0.5s;
    }
    .cd-hero-slider li.selected.from-left .cd-half-width:nth-of-type(2),
    .cd-hero-slider li.selected.from-right .cd-half-width:first-of-type {
        //@include transition(opacity 0.4s 0.2s, transform 0.5s 0.2s);
        transition: opacity 0.4s 0.2s, transform 0.5s 0.2s;
    }
    .cd-hero-slider li.selected.from-left .cd-half-width:first-of-type,
    .cd-hero-slider li.selected.from-right .cd-half-width:nth-of-type(2) {
        //@include transition(opacity 0.4s 0.4s, transform 0.5s 0.4s);
        transition: opacity 0.4s 0.4s, transform 0.5s 0.4s;
    }


    .cd-hero-slider .selected .cd-full-width h2,
    .cd-hero-slider .selected .cd-full-width p {
        opacity: 1;
        //@include transform(translateX(0));
        transform: translateX(0);
    }
    .cd-hero-slider li.is-moving .cd-full-width h2,
    .cd-hero-slider li.is-moving .cd-full-width p {
        //@include transition(opacity 0s 0.5s, transform 0s 0.5s);
        transition: opacity 0s 0.5s, transform 0s 0.5s;
    }
    .cd-hero-slider li.selected h2 {
        //@include transition(opacity 0.4s 0.2s, transform 0.5s 0.2s);
        transition: opacity 0.4s 0.2s, transform 0.5s 0.2s;
    }
    .cd-hero-slider li.selected p {
        //@include transition(opacity 0.4s 0.3s, transform 0.5s 0.3s);
        transition: opacity 0.4s 0.3s, transform 0.5s 0.3s;
    }
}

.cd-main-content {
    width: 90%;
    max-width: 768px;
    margin: 0 auto;
    padding: 2em 0;

    p {
        font-size: 1.4rem;
        line-height: 1.8;
        color: #999999;
        margin: 2em 0;
    }
}

@media only screen and (min-width: 1170px) {
    .cd-main-content {
        padding: 3em 0;
    }
    .cd-main-content p {
        font-size: 1.6rem;
    }
}

.no-js .cd-hero-slider li {
    display: none;

    &.selected {
        display: block;
    }
}

.no-js .cd-slider-nav {
    display: none;
}