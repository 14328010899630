// Creators Program pages
// todo: migrate to theme sheet when page is updated
$imageDir: '/images/creators/';

body.creators_program {
    background-color: #000;
}

body.creator_list {
    .CreatorsHeaderGrid {
        padding: $SPACE_LARGE 0;
        text-align: center;
        --color-title: #{$COLOR_INVERTED_TITLE};
        color: $COLOR_INVERTED_TEXT;

        &-logo {
            width: 120px;
        }
        &-text {
            max-width: 600px;
            margin: 0 auto;
        }
    }
}

#creators2 {
    padding-bottom: 10rem;

    h1, h2, h3 {
        color: #fff;
    }
    p {
        color: #a9a9a9;
        line-height: 1.5;
    }

    a {
        text-decoration: underline;
        color: #fff;

        &:hover {
            text-decoration: none;
        }
    }

    .hashTag {
        color: #fff;
    }

    .TitlePanel {
        position: relative;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        color: #fff;
        text-align: center;

        &-middle {
            position: relative;
            background: linear-gradient(125deg, #d30000, rgba(211,0,0,0) 100%);
            padding: 5px 30px;
            margin: 0 -4px;
            max-width: 90vw;

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 3px;
                bottom: 3px;
                left: 4px;
                right: 4px;
                border: solid 2px #fff;
                pointer-events: none;
            }
        }
        &-left,
        &-right {
            position: relative;
            z-index: 1;

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                height: 2px;
                background-color: #fff;
                transform: translateY(-50%);
                pointer-events: none;
            }
        }
    }

    #masthead {
        text-align: center;
        margin-bottom: -6rem;

        @include mq-tablet-up {
            margin-bottom: -5rem;
        }

        h1, p {
            text-shadow: 0 3px 5px #000;
        }

        .Section-content {
            position: relative;
            //background: linear-gradient(to bottom, #000 10rem, transparent 100%);
            z-index: 1;

            @include mq-mobile-only {
                margin-bottom: -154px;
            }
            @include mq-tablet-up {
                margin-bottom: -20vw;
            }
            @include mq-wide-up {
                margin-bottom: -320px;
            }
        }
        .Section-background {
            position: relative;
            background-image: url($imageDir+'background-masthead.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 100vw;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: linear-gradient(to bottom, #000, transparent 15%, transparent 75%, #000 90%);
                //background: linear-gradient(to bottom, transparent 75%, #000 90%);
            }

            @include mq-tablet-up {
                height: 50vw;
            }
            @include mq-wide-up {
                width: 1600px;
                height: 800px;
                margin: 0 auto;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: radial-gradient(circle at center, transparent 700px, #000 800px);
                }
            }
        }

        .MastheadImage {
            width: 92px;
            height: 92px;
            margin-top: 2rem;
        }
        h1 {
            margin: 1rem 0;
            padding: 0 20px;
        }
        p {
            font-size: 18px;
            color: #fff;
            max-width: 800px;
            padding: 0 20px;
            margin: 0 auto;
        }
    }

    .CreatorsNavButtons {
        margin: 1rem 0 0;
    }
    .CreatorsNavButton {
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 15px;
        margin: 10px;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 18px;
        color: #c0c0c0;
        border: solid 1px #c0c0c0;
        //border: solid 1px rgba(211,0,0,.7);
        //background-color: rgba(0,0,0,.7);
        background-color: rgba(32,0,0,.7);
        text-decoration: none;

        &-icon {
            display: inline-block;
            height: 24px;
            width: 24px;
            background-size: contain;
            margin-right: 0.5rem;
        }

        &.is-active,
        &:hover {
            background-color: #d30000;
            border-color: #d30000;
            color: #fff;
        }

        &--list {
            .CreatorsNavButton-icon {
                background-image: url('/images/community/creators_icons/creators_active.png');
            }
        }
        &--overview {
            .CreatorsNavButton-icon {
                background-image: url('/images/community/creators_icons/apply-now_active.png');
            }
        }
    }

    #overview {
        .ContentWrapper {
            max-width: 600px;
            margin: 0 auto;
            padding: 1.5rem 20px;

            @include mq-desktop-up {
                max-width: 800px;
            }
        }
        p {
            font-size: 18px;
            line-height: 1.5;
            margin: 1.5rem 0;
        }
    }

    #howtojoin {
        position: relative;
        background-color: #1B1019;

        .headerImage {
            display: none;
            width: 100%;
        }
        @include mq-tablet-down {
            .headerImage {
                display: block;
            }
        }

        .Section-background {
            @include mq-tablet-down {
                //background-image: url($imageDir+'background-join.png');
                //height: 58.5vw;
                //background-size: cover;
                //background-position: center;
                //background-repeat: no-repeat;
            }
            @include mq-desktop-up {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                overflow: hidden;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 40%;
                    width: 60%;
                    background-image: url($imageDir+'background-join.png');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 40%;
                    width: 20%;
                    background: linear-gradient(to right, #1B1019 0%, transparent 100%);
                }
            }
            @include mq-wide-up {
                &:before {
                    left: 50%;
                    width: 960px;
                    transform: translateX(-160px);
                }
                &:after {
                    left: 50%;
                    width: 960px;
                    transform: translateX(-160px);
                    background: linear-gradient(to right, #1B1019 0%, transparent 33%, transparent 85%, #1B1019 100%);
                }
            }
        }
        .Section-content {
            position: relative;

            @include mq-tablet-down {
                max-width: 520px;
                margin: 0 auto;
                padding: 2rem 20px;
            }
            @include mq-desktop-up {
                margin: 0 50% 0 auto;
                width: 45vw;
                max-width: 600px;
                padding: 4rem 20px;
            }
        }

        .ContentWrapper {
            //margin: 0 50% 0 5%;
        }

        p {
            margin: 1.5rem 0;
        }

    }

    .JoinGrid {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 20px;
        color: #a9a9a9;
        line-height: 1.5;
    }
    .JoinPanel {
        position: relative;
        color: #fff;
        margin-top: 2rem;

        &-middle {
            position: relative;
            display: block;
            background: linear-gradient(156deg,  #605523 15.99%, rgba(211, 101, 0, 0) 84.49%);
            padding: 15px 25px;
            margin: 0;

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 3px;
                bottom: 3px;
                left: 4px;
                right: 4px;
                border: solid 2px #fff;
                pointer-events: none;
            }
        }
        &-left {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            right: calc(100% - 4px);
            width: 100vw;
            height: 2px;
            background-color: #fff;
            transform: translateY(-50%);
            pointer-events: none;
            z-index: 1;
        }
    }

    #resources {
        position: relative;
        background-color: #151912;
        margin-bottom: 4rem;

        .headerImage {
            display: none;
            width: 100%;
        }
        @include mq-tablet-down {
            .headerImage {
                display: block;
            }
        }

        .Section-background {
            @include mq-tablet-down {
                //background-image: url($imageDir+'background-resources.png');
                //height: 58.5vw;
                //background-size: cover;
                //background-position: center;
                //background-repeat: no-repeat;
            }
            @include mq-desktop-up {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                overflow: hidden;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 40%;
                    width: 60%;
                    background-image: url($imageDir+'background-resources.png');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 40%;
                    width: 20%;
                    background: linear-gradient(to left, #151912 0, transparent 100%);
                }
            }
            @include mq-wide-up {
                &:before {
                    right: 50%;
                    width: 960px;
                    transform: translateX(160px);
                }
                &:after {
                    right: 50%;
                    width: 960px;
                    transform: translateX(160px);
                    background: linear-gradient(to right, #151912 0%, transparent 33%, transparent 85%, #151912 100%);
                }
            }
        }
        .Section-content {
            position: relative;

            @include mq-tablet-down {
                max-width: 520px;
                margin: 0 auto;
                padding: 2rem 20px;
            }
            @include mq-desktop-up {
                margin: 0 auto 0 50%;
                width: 45vw;
                max-width: 600px;
                padding: 4rem 20px;
            }
        }

        p {
            margin: 1.5rem 0;
        }

    }

    .ResourceButtonContainer {
    }
    .ResourceList {
        list-style: unset;
        padding-left: 2rem;
        margin: 1.5rem 0 2rem;
        color: #fff;
    }

    #faq {
    }

    .FAQList {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        max-width: 800px;
        margin: 4rem auto;
        padding: 0 20px;
    }

    .FAQEntry {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        padding: 0;
        gap: 0;

        background: #171717;
        color: #a9a9a9;
        cursor: pointer;

        p:not(:first-child) {
            margin-top: 1.5em;
        }
        p:not(:last-child) {
            margin-bottom: 1.5em;
        }

        ul {
            list-style: unset;
            padding-left: 2rem;
            margin: 1.5rem 0;
            color: #fff;
        }

        &-toggle {
            position: relative;
            width: 12px;
            height: 12px;
            margin: 15px;
        }
        &-open {
            &:before {
                content: '';
                position: absolute;
                left: 50%;
                top: 0;
                bottom: 0;
                width: 2px;
                transform: translateX(-50%);
                background-color: #686868;
            }
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                height: 2px;
                transform: translateY(-50%);
                background-color: #686868;
            }
        }
        &-close {
            display: none;
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                height: 2px;
                transform: translateY(-50%);
                background-color: #fff;
            }
        }
        &-question {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 12px;
            margin: 15px 20px 15px 0;

            @include mq-tablet-up {
                font-size: 14px;
            }
            @include mq-desktop-up {
                font-size: 16px;
            }
        }
        &-answer {
            position: relative;
            grid-column: 1 / span 2;
            overflow: hidden;
            height: 0;
            transition: height 200ms ease-out;
            background-color: #171717;
        }
        &-answerInner {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 15px 20px 30px;
        }

        &.is-open {
            background-color: #2c2c2c;
            .FAQEntry-question {
                color: #fff;
            }
            .FAQEntry-open {
                display: none;
            }
            .FAQEntry-close {
                display: block;
            }
        }
    }

    #report {
        position: relative;
        overflow: hidden;

        .Section-content {
            position: relative;
            max-width: 800px;
            padding: 0 20px;
            margin: 0 auto;
        }

        hr {
            height: 1px;
            background-color: #494949;
            margin: 1rem 0;
        }

        p {
            color: #a9a9a9;
            margin: 1.5rem 0;
        }
    }

    .LeftShark {
        position: absolute;
        width: 347.5px;
        height: 239px;
        z-index: -1;

        @include mq-tablet-down {
            display: none;
        }
        @include mq-desktop-up {
            top: -1rem;
            right: 800px;
        }
    }
    .RightShark {
        position: absolute;
        width: 338px;
        height: 207px;
        z-index: -1;
        display: none;

        @include mq-tablet-down {
            bottom: -4rem;
            left: 78%;
            transform: translate(-50%, 50%);
        }
        @include mq-desktop-up {
            display: block;
            bottom: 0;
            left: 800px;
        }
    }
}
