// CMS page styles
// todo: needs cleanup and modernization
.cms-page, .basic_page_template {

    .wrapper {
        min-width: 100%;
        min-height: 100%;
        background-color: black;
        background-image: url('../images/404/bkg-orbiter-optimized.jpg');
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
    }

    #basicTextContain{
        background-color: #FFFFFFE5;
        line-height: 18px;
        padding: 2rem;
        margin: 80px 0;

        @include mq(tablet) {
            margin: 70px 0 80px 0;
        }

        @include mq(mobile) {
            margin: 20px 0 80px 0;
        }
    }

    #textPage {
        h2 {
          margin-top: 32px;
        }
        p {
            margin-bottom: 16px;
        }

        strong {
            margin-bottom: 15px;
            font-weight: bold;
            text-transform: uppercase;
        }

        input[type="submit"] {
            color: white;
            border: none;
        }

        h4 {
            text-transform: none;
            font-family: "Noto Sans";
            + p {
                margin-bottom: 8px;
            }
        }
        .tableWrap {
            overflow: auto;
        }
        table {
            border: none;
            background-color: white;
            width: 100%;
            line-height: 1.5;
            td {
                border: none;
                padding: 15px 10px !important;
                vertical-align: top;

            }
            tr {
                //&:nth-of-type(odd) {
                //    background-color: rgba(0, 0, 0, 0.15);
                //}
                .tableHeader {
                    font-weight: bold;
                    background-color: #000000;
                    color: #FFFFFF;
                }
            }

        }
        em {
            font-style: italic;
            font-weight: bold;
        }
        a {
            overflow-wrap: break-word;
        }
        hr {
            margin-top: 20px;
            margin-bottom: 20px;
            border-color: #b9b9b9;
        }
        ul {
            list-style-type: disc;
            margin-left: 20px;

            ul {
                list-style-type: circle;
            }

        }
        ol {
            list-style-type: upper-alpha;
            margin-left: 40px;
        }
        .lowerAlphaList {
            list-style-type: lower-alpha;
        }
        .numList {
            list-style-type: decimal;
        }

        .featuredText {
            padding: 15px;
            background-color: white;
            border: 1px solid black;
            margin: 15px 0;
            p {
                margin-bottom: 0;
            }
        }

        .discList {
            list-style-type: disc;
        }

        .lowerRomanList {
            list-style-type: lower-roman;
        }

    }
    #ot-sdk-cookie-policy {

        #ot-sdk-cookie-policy-v2 {
            h3, h4, div, section, p, td, span {
                color: black;
            }
            div, p, th, td {
                font-size: 1rem;
                line-height: 1.5;
            }
            table, td, th {
                border: none;
            }
            section {
                @include mq-tablet-down {
                    // table scroll
                    overflow-x: scroll;
                }
            }
            th {
                 font-weight: bold;
            }
            a {
                color: #B30000;
                text-decoration: none;
            }
            p {
                margin-bottom: 16px;
            }
            h3 {
                margin-top: 16px;
                margin-bottom: 0;
            }
            h4 {
                font-size: 1.125rem;
            }
            div {
                margin: 16px 0;
            }
            .ot-sdk-container {
                min-width: 100%;
            }
        }
    }

}

