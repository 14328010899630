// FIXME: THIS IS THE OLD STYLESHEET
// Any new changes should be made in resources/assets/sass/themes/warframes.scss
// This page should be removed when the migration/conversion is completed
// Don't forget to remove the reference from styles.scss

//@import "compass/css3";
@import "../utils/_mixins";
@import "../style-guide/_responsive";
@import "../style-guide/_variables";

body.warframes{

    &.lang-de, &.lang-es, &.lang-pt-br, &.lang-tr, &.lang-pl {
        .cd-hero.slider .inner p {
            line-height: 19px;
        }
    }

    &.lang-ru, &.lang-fr, &.lang-it, &.lang-uk {
        .cd-hero.slider .inner p {
            line-height: 24px;
        }
    }

    .warframeContent, .primes{
        margin-top: 80px;
    }

    .warframeContent{
        margin-bottom: 80px;
    }

    .lotusLogo{
        background-image: url('../images/mainSiteAssets/splashPage/whiteLotus.png');
    }

    .warframesContain{

        a{
            width:24%;
            float:left;
            position: relative;
            cursor: pointer;
            display: block;
            margin-right: 5px;
            margin-bottom: 40px;

            img{
                border-bottom: 1px solid $pcRed;
                width: 100%;
                height: auto;
                max-height: 278px;
            }

            .wfTitle{
                text-transform: uppercase;
                min-width: 140px;
                height: 30px;
                color:white;
                position: absolute;
                font-weight: bold;
                bottom: 4px;

                .innerWfTitle{
                    padding: 7px 0 0 10px;
                }

                &::after{
                    content: '';
                    position: absolute;
                    left: 100%;
                    bottom: 0;
                }
            }

            &.wf{
                .wfTitle{
                    background-color: $pcRed;
                    &::after{
                        @include corner-triangle('bottom-left',30px,$pcRed);
                    }
                }
                &:hover .wfTitle{
                    color: $pcRed;
                }
            }

            &.primewf{
                .wfTitle{
                    background-color: #201e12;
                    color: #ecc051;

                    &::after{
                        @include corner-triangle('bottom-left',30px,#201e12);
                    }
                }

                img {
                    border-bottom: 1px solid #201e12;
                }
                &:hover .wfTitle{
                    color: #ecc051;
                }
            }

            &:hover {
                &.wf {
                    .wfTitle {
                        background-color: white;
                        &::after {
                            @include corner-triangle('bottom-left', 30px, white);
                        }
                    }
                }
                &.primewf {
                    .wfTitle {
                        background-color: #3A3622;
                        &::after {
                            @include corner-triangle('bottom-left', 30px, #3A3622);
                        }
                    }
                }
            }
        }
    }

    @include mq(hd){
        .cd-hero.slider .slide{
            background-image:none !important;
        }
    }

    .cd-hero.slider {
        .inner {
            float: left;
            position: relative;
            margin-top: 200px;
            margin-left: -20px;
            width: 370px;
            padding: 15px 20px 20px;
            background: rgba(0, 0, 0, 0.6);

            .title {
                color: #d30000;
            }

            p {
                margin-top: 8px;
                padding: 0;
            }
        }
    }

    @include mq(ib){
        .warframesContain .wfTitle{
            font-size: 11px;
            width: 100%;

            &::after{
                display: none !important;
            }

        }
    }

    @include breakpoint-tablet-only {
        .warframesContain .wfTitle{
            font-size: 11px;

            &::after{
                display: none !important;
            }

        }
    }

    .cd-hero .slider-nav {
        ul {
            margin: 0;
            padding: 0 6px;
        }
    }

    @include breakpoint-mobile-only {
        .cd-hero-slider {
            padding: 0;
            width: 100%;

            .inner {
                max-width: 350px;
                padding: 16px !important;

                p {
                    margin-top: 8px;
                }
            }
        }
        .cd-hero .slider-nav {
            ul {
                display: inline-block;
                height: 36px;
            }
        }

        .warframesContain{
            a{
                width: 48% !important;
            }
        }

        .wfTitle{
            text-align: center;
            bottom: -6px !important;
            width: 100%;

            .innerWfTitle{
                padding: 7px 10px !important;
                font-size: 12px;
                //font-family: 'Open Sans',sans-serif;
                font-weight: 100;
                text-align: left;
            }
            &::after{
                display: none;
            }
        }
    }
}

#singleFramePage{
    &.prime {
        h2 {
            color:$primeGold;
        }

        .contentHeaderLines{
            border-top: 1px solid $primeGold;
            border-bottom: 1px solid $primeGold;
        }

        #mainPic .innerMainPic {
            #stats li .num {
                color:$primeGold;
            }
        }

        .lotusLogo {
            background-image: url('../images/goldLotus.png');
        }

        .ProfileVideoButton {
            --color-button-background: #{$primeGold};
            --color-button-background-hover: #{$primeGold};
        }
    }

    #mainPic,#abilities,#video{
        margin: 0 auto;
    }
    #mainPic{
        background-image: url('https://warframe-web-assets.nyc3.cdn.digitaloceanspaces.com/images/warframes/bg/wfSingleMainBG.jpg');
        background-size: cover;
        height: 830px;
        overflow: hidden;
        position: relative;

        @include mq(hd){
            background-image:none !important;
            background-color:#000;
        }

        #wfVideo {
            position: relative;

            video {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                width: 1920px;

                @include breakpoint-wide-up {
                    width: 100%;
                }
            }
        }

        .innerMainPic{
            position: relative;
            z-index: 10;
            padding: 20px;
            background: rgba(0, 0, 0, 0.6);
            bottom: -440px;
            max-width: 500px !important;
            left: -20%;

            p{
                text-transform: uppercase;
                margin-top: 32px;
                color: white;
                line-height: 28px;
                font-size: 15px;
            }

            #stats{
                margin-top: 45px;

                li{
                    display: inline-block;
                    text-align: center;
                    margin-right: 19px;
                    font-weight:bold;

                    .num{
                        @include futureFont();
                        color: $pcRed;
                        font-size: 22px;
                    }

                    .name{
                        text-transform: uppercase;
                        color: white;
                        margin-top: 3px;
                        font-size: 11px;
                    }
                }
            }
        }
        .leftWfBtn,.rightWfBtn{
            position: absolute;
            background-size: 100%;
            top: 40%;
            width: 50px;
            height: 116px;
        }

        .leftWfBtn{
            left: 20px;
            background-image: url('https://warframe-web-assets.nyc3.cdn.digitaloceanspaces.com/images/warframes/leftArrow.png');
        }
        .rightWfBtn{
            right: 20px;
            background-image: url('https://warframe-web-assets.nyc3.cdn.digitaloceanspaces.com/images/warframes/rightArrow.png');
        }

    }

    #abilities{
        background-color: #ececec;
        background-size: cover;
        position: relative;

        .container {
            padding: 0 36px;
        }
    }

    #warframesList{
        .miniFrames{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 48px 0;

            @include breakpoint-tablet-up {
                justify-content: normal;
            }

            .miniFrame{
                flex-basis: 48%;
                margin: 0 0 15px;
                padding: 0;
                background: rgba(0, 0, 0, 0.1);
                display: flex;
                flex-direction: column;

                @include breakpoint-tablet-up {
                    width: 95px;
                    float: left;
                    display: block;
                    margin: 0 8px 8px 0;
                    padding: 5px 5px 0 5px;
                    flex-basis: auto;

                    .frameName {
                        display: none;
                    }
                }

                .frameName {
                    background-color: $COLOR_RED;
                    color: $COLOR_INVERTED_TITLE;
                    padding: 5px;
                }

                img{
                    width: 100%;
                    //@include filter(grayscale(100%));
                    filter: grayscale(100%);
                }

                &.active,&:hover{
                    background: rgba(0,0,0,0.3);

                    img{
                        //@include filter(grayscale(0%));
                        filter: grayscale(0%);
                    }
                }
            }

            &.primesList{
                display: none;
            }
        }
    }

    @include breakpoint-tablet-only {
        #mainPic{
            .innerMainPic{
                left: 0%;
            }
        }
    }

    @include breakpoint-mobile-only {
        #mainPic {
            background-position: 50%;
            display: flex;
            flex-direction: column-reverse;

            .rightWfBtn,.leftWfBtn{
                display: none;
            }

            #wfVideo{
                display: none;
            }

            .innerMainPic {
                height: auto;
                padding: 24px 36px 40px;
                bottom: 0;
                min-height: 0;
                left: 0;

                .one-half {
                    display: flex;
                    flex-direction: column;
                }

                #stats {
                    margin-top: 16px;
                    padding: 0;
                    display: flex;
                    flex-direction: row;
                    column-gap: 16px;
                }

                #stats li {
                    margin: 0;

                    .num {
                        color: #FF0000;
                        font-size: 24px;
                    }
                }

                p{
                    width: auto;
                    text-transform: none;
                    margin-top: 16px;
                    color: white;
                    line-height: 22px;
                    font-size: 12px;
                }
            }
        }
    }
}
