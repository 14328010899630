// todo: Overhaul with more CSS variables so that overriding is more centralized
$primaryColor: #B01717 !default;
$textColor: #fff !default;
$buttonText: $textColor !default;
$pageBackgroundColor: #000 !default;
$pageTitleColor: #000 !default;
$pageTitleBackgroundColor: #fff !default;
$pageSubtitleColor: #fff !default;
$shadowColor: rgba(0, 0, 0, 0.7) !default;
$cardTitleColor: $textColor !default;
$cardFooterHeight: 70px !default;
$cardTitleColor: $primaryColor !default;
$itemTitleColor: $primaryColor !default;
$panelShadow: 1px 3px 20px rgba(0,0,0,.5) !default;
$merchBannerTitleColor: $primaryColor !default;
$wordSpacingOverride: 0 !default;
$letterSpacingOverride: 0 !default;
$recommendedSpacingOverride: 2px !default;

body.product-compare {
    background-color: $pageBackgroundColor;

    @include mq-tablet-up {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 73vw;

            //background-image: url(#{$imageDir}/bg.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;

            pointer-events: none;
            z-index: -1;
        }
    }

    .storeBar {
        display: none;
    }
}

.product-compare-koumei-bg {
    background-color: #161616 !important;
}

.ProductCompareUpper {
    position: relative;
    padding-bottom: 60px;
    margin-bottom: -60px;

    &-background {
        @include FULL_COVER;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        mask-image: linear-gradient(to top, transparent, black 320px);

       @include breakpoint-tablet-down {
           bottom: 85% !important;
        }
    }
}

.ProductCompareContainer {
    position: relative;
    color: #fff;

    // todo: turn this into a proper component later
    .SubNav {
        position: relative;
        background-color: rgba(16, 14, 14, 0.95);
        text-align: center;

        &-inner {
            display: inline-grid;
            grid-auto-columns: auto;
            gap: 40px;
            align-items: center;
        }

        &-underline {
            position: absolute;
            bottom: 0;
            background-color: #B30000;
            height: 3px;
        }
    }
    .SubNavItem {
        display: inline-block;
        grid-row: 1;
        font-size: 14px;
        border-bottom: solid 4px transparent;
        padding: 16px 0;
        color: #fff;

        &:hover {
            //border-bottom-color: #B30000;
        }

        &.is-active {
            font-weight: 700;
            //border-bottom-color: #B30000;
        }
    }

    .productSetHeader {
        text-align: center;
        padding-top: 20px;
        font-family: var(--font-family-title, #{"Roboto", "Noto Sans", Arial, Helvetica, sans-serif});

        //@include mq(tablet) {
        //    padding: 80px 0;
        //}

        .titleBar {
            display: block;
            margin: 0 0 10px;

            .title {
                display: inline-block;
                padding: 10px 30px;
                background-color: $pageTitleBackgroundColor;

                font-family: inherit;
                font-weight: bold;
                font-size: 48px;
                color: $pageTitleColor;
                letter-spacing: 1.5px;
                text-transform: uppercase;

                @include mq-tablet-only {
                    font-size: 36px;
                }
                @include mq-mobile-only {
                    font-size: 30px;
                }
            }
        }
        .subtitleBar {
            display: block;

            .subtitle {
                display: inline-block;

                font-size: 48px;
                font-weight: 700;
                color: $pageSubtitleColor;
                text-transform: uppercase;
                //letter-spacing: 8px;
                word-spacing: 5px;

                @include mq-tablet-down {
                    color: inherit;
                    font-size: 36px;
                }
                @include mq-mobile-only {
                    font-size: 30px;
                    //letter-spacing: 6px;
                    word-spacing: 4px;
                }
            }
        }

        .label {
            width: fit-content;
            max-width: 800px;
            margin: 16px auto -16px;
            padding: 5px 10px;
            color: white;
            background-color: rgba(0,0,0,0.5);
        }
    }

    .HeaderDivider {
        position: relative;
        max-width: 50%;
        margin: 24px auto 24px;
        background-color: rgba(255,255,255,.5);

        //@include mq-tablet-down {
        //    margin-bottom: 20px;
        //}
    }
    .LastChanceBanner {
        background: rgba(0, 0, 0, 0.80);
        border-right: gold 1px solid;
        border-left: gold 1px solid;
        width: 121px;
        position: absolute;
        top: 0;
        left: 15px;
        @include mq-mobile-only {
            display: none;
        }
    }
    .SaleTag {
        display: inline-grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        padding: 4px 8px;
        gap: 4px;
        background-color: #B30000;

        &-icon {
            filter: invert(1);
        }
        &-text {
            color: #fff;
            font-weight: 700;
        }
    }

    .exclusiveNote {
        position: relative;
        margin: 15px auto 30px;
        text-align: center;
        text-shadow: 1px 1px 3px $shadowColor;

        .exclusiveNote-inner {
            display: inline-block;
            max-width: 80%;
            background-color: rgba(0,0,0,.7);
            box-shadow: $panelShadow;
            padding: 15px 0;
        }
    }

    #packages {
        .PackageTitles {
            margin-bottom: -58px;
            @include mq-mobile-only {
                margin-bottom: -28px;
            }
        }
        .PackageTitle {
            display: none;
            font-size: 3rem;
            font-weight: bold;
            text-align: center;
            width: 90%;
            margin: 0 auto;

            &.is-active {
                display: block;
            }

            @include mq-mobile-only {
                font-size: 2rem;
            }
        }
        .Carousel {
            &-content {
                height: calc(28.125vw + 180px);
                @include mq-mobile-only {
                    height: calc(28.125vw + 120px);
                }
            }
            &-control {
                height: 64px;
                width: 64px;
                padding: 12px;
                background-color: rgba(0,0,0,.5);
                &:hover {
                    background-color: rgba(0,0,0,.7);
                }
            }
            &-button {
                height: 40px;
                width: 40px;
                filter: invert(1);
            }
        }
        .TopCarouselSaleOverlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-image: linear-gradient(to top, #000, transparent 30%);
            pointer-events: none;
        }

        .TopCarouselPriceSection {
            position: absolute;
            bottom: 0;
            left: 24px;
            right: 24px;
            text-align: center;
            transform: translateY(50%);

            transition: opacity 250ms;
            opacity: 0;

            @media (max-width: 1919px){
                
                .small-text{
                    display:none;
                }

                &-plat {
                    position: absolute;
                    bottom: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    display: inline-grid;
                    grid-template-columns: auto 1fr;
                    align-items: center;
                    gap: 4px;
                    padding: 8px;
                    background-color: rgba(0,0,0,.5);
                }

            }

            @media(min-width: 1920px){
                .Icon--raster {
                    height: 100px;
                    width: 100px;
                }

                .plat-wrapper {
                    position: relative; /* Make sure the div can be positioned relative to its pseudo-elements */

                }

                .plat-wrapper::before {
                    content: "";
                    position: absolute;
                    top: -55px; /* Adjust how much space you want to add */
                    left: -55px;
                    right: -55px;
                    bottom: -55px;
                    background-image: url("/images/buy-plat/lens-flare.png"); /* Use the background or border styling you need */
                    background-position: center;
                    background-size: 115%;
                    background-repeat: no-repeat;
                    z-index: -1; /* Ensure the background stays behind the content */
                    border-radius: 10px; /* Optional: Control the rounded corners */
                }

                &-plat {
                    position: absolute;
                    bottom: 105%;
                    left: 90%;
                    transform: translateX(-50%);
                    display: flex;
                    flex-flow: column;
                    grid-template-columns: auto 1fr;
                    align-items: center;
                    gap: 4px;
                    padding: 6px;
                    width: 175px;
                    height: 165px;
                    background-color: rgba(0,0,0,0.8);
                    border-radius: 6px;

                    .small-text{
                        font-size: 12px;
                        font-family: "Open Sans", sans-serif !important;
                    }

                    .plat-text{
                        font-family: "Open Sans", sans-serif;
                        font-weight: bold;
                    }
                }
            }
            
            &-anchor {
                display: inline;
            }

            @include mq-mobile-only {
                display: none;
            }
        }

        .PackDisclaimer {
            margin-bottom: 8px;
            width: 100%;

            @include breakpoint-tablet-down {
                bottom: 18px;
            }
            @include breakpoint-mobile-only {
                bottom: 0;
            }

            &-inner {
                text-align: center;
                margin: auto;
                max-width: 600px;
                background: rgba(0, 0, 0, 0.3);
                padding: 8px;

                a{
                    color: rgba(255, 255, 255, 0.8);
                    text-decoration: underline;
                }
            }
        }
        .disclaimerLink{
            color: rgba(255, 255, 255, 0.8);
            text-decoration: underline;
        }
        .CarouselSlide {
            .SaleTag {
                position: relative;
                width: 100%;
                opacity: 0;
                transition: opacity 250ms;
            }
            .LastChanceTag {
                text-align: center;
                .LastChanceTag-clock {
                    margin-top: 10px;
                    @include mq-tablet-down {
                        width: 30%;
                    }
                }
                .LastChanceTag-headline {
                    color: rgba(245, 230, 160, 1);
                    text-transform: uppercase;
                }
                .LastChanceTag-text {
                    font-size: 12px;
                    padding: 0 2px;
                    &:after {
                        content: "";
                        background-image: url(/images/promo/last-chance/banner-bottom.svg);
                        background-repeat: no-repeat;
                        position: absolute;
                        width: 100%;
                        height: 48px;
                        display: block;
                        bottom: -47px;
                        left: 0;
                    }
                }
            }
            &.is-disabled, &.is-purchased {
                filter: grayscale(1);
            }
            &.is-tagged, &.is-active {
                .SaleTag {
                    opacity: 1;
                }
                .TopCarouselPriceSection {
                    opacity: 1;
                }
                .PackageTitle {
                    opacity: 1;
                }
            }
        }
    }

    #test-pack {
        //z-index: 10 !important;
        .Section-wrapper {
            overflow-x: unset;
        }

        .packContainer {
                display: grid;
                grid-template-columns: 2fr 1fr;
                gap: 24px;
                //max-width: 1760px;
                width: 100%;
                margin: 0 auto;
                padding: 40px 0;

            @include breakpoint-tablet-down {
                display: grid;
                grid-template-columns: 1fr;
                width: 100%;
                padding: 40px 0 40px;
            }

        }

        /* Box styling */
        .pack {
            background-color: rgba(42, 42, 42, 1);
            border-radius: 4px;
            padding: 40px;
            order: 1;
            height: fit-content;
            max-width: 1165px;
            grid-column: 1;
            grid-row: 1;

            @include breakpoint-tablet-down {
                display: none;
            }

            .pack-container {
                display: none;
            }

            h5 {
                font-family: "Roboto";
                font-size: 18px;
                font-weight: 700;
                line-height: 27px;
                color: rgba(225, 225, 225, 1);
            }

            .packInfo {
                display: grid;
                grid-template-columns: 2fr 1fr;
                gap: 60px;
                align-items: start;
                margin-bottom: 24px;

                .packName {
                    font-family: 'Roboto';
                    color: rgba(255, 255, 255, 1);
                    font-size: 36px;
                    font-weight: 700;
                    line-height: 54px;
                }

                .price{

                    font-family: 'Roboto';
                    color: rgba(255, 255, 255, 1);
                    font-size: 28px;
                    font-weight: 700;
                    line-height: 42px;
                    text-align: right;
                }
            }


            .packImage {
                position: relative;
                .packImgDisplay{
                    border-radius: 4px;
                    //max-width: 1085px;
                    width: 100%;


                    @include breakpoint-tablet-down {
                        width: calc(100% + 80px);
                        margin-left: -40px;
                        margin-top: -40px;
                        height: auto;
                        border-radius: 4px 4px 0 0;
                    }
                }

                &-plat {
                    position: absolute;
                    bottom: 4%;
                    left: 89%;
                    transform: translateX(-50%);
                    display: flex;
                    flex-flow: column;
                    grid-template-columns: auto 1fr;
                    align-items: center;
                    gap: 4px;
                    padding: 6px;
                    width: 175px;
                    height: 165px;
                    background-color: rgba(0, 0, 0, 0.75);
                    border-radius: 6px;
                    z-index: 1;

                    @media (max-width: 1919px){
                        left: 85%;
                        width: 165px;
                        height: 155px;
                    }

                    .small-text {
                        font-weight: 400;
                    }

                    .plat-text {
                        font-weight: 700;
                    }

                    .plat-wrapper::before {
                        content: "";
                        position: absolute;
                        top: -95px;
                        left: -55px;
                        right: -55px;
                        bottom: -55px;
                        background-image: url(/images/buy-plat/lens-flare.png);
                        background-position: center;
                        background-size: 80%;
                        background-repeat: no-repeat;
                        z-index: -1;
                        border-radius: 10px;
                    }

                    .Icon--raster {
                        height: 100px;
                        width: 100px;

                        @media (max-width: 1919px){
                            height: 80px;
                            width: 80px;
                        }

                        @include breakpoint-tablet-down{
                            height: 24px;
                            width: 24px;
                        }
                    }


                    .small-text-mobile {
                        display: none;

                        @include breakpoint-tablet-down {
                            display: block;
                        }
                    }
                }
            }

            .is-active {
                display: block;
            }
        }

        .packDivider {
            display: none;

            @include breakpoint-tablet-down {
                display: block;
                order: 2;
                background-color: rgba(64, 64, 64, 1);
                margin-top: 56px;
                margin-bottom: 80px;
            }

            @include breakpoint-mobile-only {
                display: block;
                margin-top: 40px;
                margin-bottom: 64px;
            }
        }

        .pack-tablet {
            display: none;

            @include breakpoint-tablet-down {
                display: block;
                grid-column: auto;
                grid-row: auto;
                order: 3;
            }
        }

        .Koumei-mobile {
            display: none;

            @include breakpoint-tablet-down {
                display: block;
            }

            h2 {
                color: white;
                font-size: 36px;
                line-height: 46.8px;
                margin-top: 80px;

                @include breakpoint-mobile-only {
                    font-size: 32px;
                    line-height: 41.6px;
                    margin-top: 40px;
                }
            }

            .exclusiveNote {
                text-align: left;
                margin: 8px auto 40px;
                &-inner {
                    background-color: unset;
                    box-shadow: none;
                    padding: 0;
                    max-width: fit-content;

                }
            }



        }

        .pack-mobile-card {
            display: none;
            background-color: rgba(42, 42, 42, 1);
            border-radius: 4px;
            padding: 40px;

            @include breakpoint-tablet-down {
                display: block;
                grid-column: auto;
                grid-row: auto;
            }

            @include breakpoint-mobile-only {
                padding: 24px;
            }

            .pack-container-mobile-tablet {
                position: relative;
            }

            .Button--primary {
                width: 100%;
                background-color: rgba(170, 34, 43, 1);
                margin-top: 40px;
                padding: 16px 40px;

                .buyNow {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 28.13px;
                }

                &:hover {
                    background-color: #73171D;
                }
            }

            .Button--disabled {
                width: 100%;
                background-color: rgba(50, 50, 50, 1);
                color: rgba(255, 255, 255, 1);
                --color-button-border: none;
                margin-top: 40px;
                padding: 16px 40px;

                .purchased {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 28.13px;
                }
            }

            .packImage {
                position: relative;
                .packImgDisplay{
                    border-radius: 4px;
                    //max-width: 1085px;
                    width: 100%;


                    @include breakpoint-tablet-down {
                        width: calc(100% + 80px);
                        margin-left: -40px;
                        margin-top: -40px;
                        height: auto;
                        border-radius: 4px 4px 0 0;
                    }
                }

                &-plat {
                    @media (max-width: 1919px){
                        position: absolute;
                        bottom: 5%;
                        left: 85%;
                        transform: translateX(-50%);
                        display: inline-grid;
                        grid-template-columns: 1fr auto 2fr;
                        align-items: center;
                        gap: 4px;
                        padding: 4.5px 8px;
                        height: auto;
                        width: 210px;
                        border-radius: 4px;
                        background-color: rgba(0, 0, 0, 0.75);

                        span {
                           font-weight: 700;
                        }
                    }

                    @include breakpoint-mobile-only{
                        left: 69%;
                    }

                    .plat-wrapper::before {
                        content: "";
                        position: absolute;
                        top: -98px;
                        left: -55px;
                        right: -55px;
                        bottom: -55px;
                        background-image: url(/images/buy-plat/lens-flare.png);
                        background-position: center;
                        background-size: 80%;
                        background-repeat: no-repeat;
                        z-index: -1;
                        border-radius: 10px;

                        @media (max-width: 1919px){
                            display:none;
                        }

                    }

                    .Icon--raster {
                        height: 100px;
                        width: 100px;

                        @media (max-width: 1919px){
                            height: 24px;
                            width: 24px;
                        }
                    }


                    .small-text-mobile {
                        display: none;

                        @include breakpoint-tablet-down {
                            display: block;
                        }
                    }
                }
            }

            .pack-img-mobile {
                display: none;

                @include breakpoint-tablet-down {
                    display: block;
                    width: 100%;
                    position: relative;
                }

                .packImgDisplayMobile {
                    width: calc(100% + 80px);
                    margin-left: -40px;
                    margin-top: -40px;
                    height: auto;
                    border-radius: 4px 4px 0 0;

                    @include breakpoint-mobile-only {
                        width: calc(100% + 48px);
                        margin-left: -24px;
                        margin-top: -24px;
                    }
                }
            }

            h6 {
                margin-top: 40px;
                font-size: 18px;
                line-height: 27px;
                color: rgba(225, 225, 225, 1);

                @include breakpoint-mobile-only {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            .packInfo {
                h3 {
                    font-weight: 36px;
                    line-height: 54px;
                    font-weight: 700;
                    color: rgba(255, 255, 255, 1);

                    @include breakpoint-mobile-only {
                        font-size: 32px;
                        line-height: 48px;
                        font-weight: 700;
                    }
                }

                .price {
                    margin-top: 24px;
                    font-size: 28px;
                    line-height: 42px;

                    @include breakpoint-mobile-only {
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 36px;
                    }
                }
            }
        }

        .packSelector {
            background-color: rgba(42, 42, 42, 1);
            border-radius: 4px;
            padding: 40px;
            position: sticky;
            position: -webkit-sticky;
            top: 100px;
            align-self: start;
            height: fit-content;
            order: 2;
            z-index: 10;
            grid-column: 2;
            grid-row: 1 / span 2;
            @include breakpoint-tablet-down {
                order: 1;
                position: relative;
                top: 0;
                grid-column: auto;
                grid-row: auto;
            }

            @include breakpoint-mobile-only {
                padding: 24px;
            }

            .logo {
                display: block;
                margin: 0 18px 40px;

                @include breakpoint-tablet-down {
                    display: none;
                }
            }


            .packImgsMobile {
                display: none;

                @include breakpoint-tablet-down {
                    display: block;
                    position: relative;
                }

                .pack-container-mobile {
                    display: none;
                    width: 100%;

                    @include breakpoint-tablet-down {
                        display: block;
                        width: 100%;
                        position: relative;
                    }

                    .packImgDisplayMobile {
                        width: calc(100% + 80px);
                        margin-left: -40px;
                        margin-top: -40px;
                        height: auto;
                        border-radius: 4px 4px 0 0;

                        @include breakpoint-mobile-only {
                            width: calc(100% + 48px);
                            margin-left: -24px;
                            margin-top: -24px;
                        }
                    }

                    .packImage {
                        position: relative;
                        &-plat {
                            @media (max-width: 1919px){
                                position: absolute;
                                bottom: 5%;
                                left: 85%;
                                transform: translateX(-50%);
                                display: inline-grid;
                                grid-template-columns: 1fr auto 2fr;
                                align-items: center;
                                gap: 4px;
                                padding: 4.5px 8px;
                                height: auto;
                                width: 210px;
                                border-radius: 4px;
                                background-color: rgba(0, 0, 0, 0.75);

                                span {
                                    font-weight: 700;
                                }
                            }

                            @include breakpoint-mobile-only{
                                left: 69%;
                            }

                            .plat-wrapper::before {
                                content: "";
                                position: absolute;
                                top: -98px;
                                left: -55px;
                                right: -55px;
                                bottom: -55px;
                                background-image: url(/images/buy-plat/lens-flare.png);
                                background-position: center;
                                background-size: 80%;
                                background-repeat: no-repeat;
                                z-index: -1;
                                border-radius: 10px;

                                @media (max-width: 1919px){
                                    display:none;
                                }

                            }

                            .Icon--raster {
                                height: 100px;
                                width: 100px;

                                @media (max-width: 1919px){
                                    height: 24px;
                                    width: 24px;
                                }
                            }


                            .small-text-mobile {
                                display: none;

                                @include breakpoint-tablet-down {
                                    display: block;
                                }
                            }
                        }
                    }


                }
            }


            h4 {
                font-family: Roboto;
                font-size: 28px;
                font-weight: 700;
                line-height: 42px;
                text-align: left;
                color: rgba(255, 255, 255, 1);
                margin-bottom: 8px;

                @include breakpoint-tablet-down {
                    margin-top: 40px;
                }

                @include breakpoint-mobile-only {
                    font-size: 24px;
                    line-height: 36px;
                }
            }

            p {
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                text-align: left;
                margin: 0;

                span {
                   font-weight: 700;
                }

                a {
                    color: rgba(255, 255, 255, 1);
                    text-decoration: underline;
                }
            }

            .FormInputLabel {
                gap: 16px;
            }

            .Button--primary {
                width: 100%;
                background-color: rgba(170, 34, 43, 1);
                margin-top: 24px;
                padding: 16px 40px;

                .buyNow {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 28.13px;
                }

                &:hover {
                    background-color: #73171D;
                }
            }

            .Button--disabled {
                width: 100%;
                background-color: rgba(50, 50, 50, 1);
                color: rgba(255, 255, 255, 1);
                --color-button-border: none;
                margin-top: 24px;
                padding: 16px 40px;

                .purchased {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 28.13px;
                }
            }

            .FormInputLabel {
                @include breakpoint-mobile-only {
                    gap: 16px;
                    align-items: flex-start;
                }
            }
            .customRadio .FormInputLabel-text {
                flex-flow: column nowrap;
                align-items: flex-start;
            }

            .customRadio:hover {
                background-color: rgba(64, 64, 64, 1);
            }
        }
    }

    .Koumei {
        order: 3;

        @include breakpoint-tablet-down {
            display: none !important;
        }
        .Section-wrapper, .Section-Content, .SectionContent, .exclusiveNote {
            text-align: left !important;
        }

        h2 {
            color: white;
            font-size: 36px;
            line-height: 46.8px;
        }

        .exclusiveNote {
            margin: 8px auto 40px !important;

            &-inner {
                background-color: unset;
                box-shadow: none;
                padding: 0;
                max-width: fit-content;
            }
        }


    }

    .Koumei-grid {
        .PackageTileSubtitle {
            font-size: 14px !important;
            margin-bottom: 4px !important;
        }
        .Caption {
            max-width: 214px;

            @include breakpoint-tablet-down {
                width: auto;
            }
        }
        .Caption--default {
            border: solid 1px rgba(42, 42, 42, 1) !important;
            background-color: rgba(42, 42, 42, 1) !important;
            border-radius: 4px;

            .Caption-image {
                background: radial-gradient(50% 50% at 50% 50%, rgba(184, 184, 184, 0.2) 0%, rgba(18, 18, 18, 0.2) 100%);
                border: 1px solid rgba(85, 85, 85, 1);
                height: 133px !important;
                border-radius: 4px;
                margin: 15px 15px 0 !important;

                @include breakpoint-tablet-down {
                    height: 170px !important;
                }

                @include breakpoint-mobile-only {
                    height: 120px !important;
                    margin: 16px 16px 0 !important;
                }

            }
            .Caption-text {
                border-top: none !important;
                padding: 16px 15px 15px !important;

                @include breakpoint-mobile-only {
                    padding: 16px !important;
                }
            }
        }

        .is-active {
            display: block !important;
            opacity: unset !important;
        }
    }

    .KoumeiFooter {
        display: none;
        @include breakpoint-tablet-down {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            background-color: rgba(42, 42, 42, 1);
            height: 77px;
            padding: 16px 32px;
            border-top: 1px solid rgba(80, 80, 80, 1)
        }

        .footer-text {
            color: white;
            font-family: 'Roboto';
            font-size: 16px;
            font-weight: 700;
            line-height: 18.75px;
            text-align: left;
        }

        .footer-buttons {
            display: flex;
            gap: 8px;
            flex-direction: row;
        }

        .Button {
            padding: 17px;
        }

        .Button-icon {
            margin: 0px;
            transform: rotate(90deg);
        }

        .arrow-btn {
           background-color: rgba(30, 30, 30, 1);
        }
    }

    #package-contents {
        z-index: 1;
        grid-column: 1;
        grid-row: 2;
        .SectionContent {
            text-align: center;
        }
        .Grid {
            display: inline-grid;
        }
        .DeferredBlock-inner {
            height: 100%;
        }
        .Caption {
            border: solid 1px rgba(255,255,255,.5);
            background-color: rgba(0,0,0,.5);
            max-width: 215px;
            height: 100%;
            &-image {
                height: 144px;
                vertical-align: bottom;
                margin-top: 8px;
                //aspect-ratio: 4/3;
                object-fit: contain;
            }
            &-text {
                border-top: solid 1px rgba(255,255,255,.5);
                padding: 2px 10px 5px;
            }
        }
        .PackageTileTitle {
            font-weight: 700;
        }
        .PackageTileSubtitle {
            font-size: 12px;
        }

        @include breakpoint-tablet-down {
            grid-column: auto;
            grid-row: auto;
        }
    }
    #package-contents-grid {
        overflow: visible;
    }
    #package-contents-grid-container {
        position: relative;
        overflow-y: hidden;
    }

    .merchBanner {
        display: block;
        position: relative;
        margin: 80px 0 40px;
        padding: 15px 20px;
        background-color: rgba(0,0,0,.3);
        background-image: url(#{$imageDir}/merch-bg_desktop.jpg);
        background-size: cover;
        background-position: center;
        box-shadow: $panelShadow;

        cursor: pointer;
        color: $textColor;

        @include mq(tablet) {
            background-image: url(#{$imageDir}/merch-bg_tablet.jpg);
            background-position: center top;
            padding-top: 60%;
            margin: 60px 0 40px;
        }
        @include mq(mobile) {
            background-image: url(#{$imageDir}/merch-bg_mobile.jpg);
        }

        h3 {
            color: $merchBannerTitleColor;
            font-family: inherit;
            font-weight: bold;
            letter-spacing: $letterSpacingOverride;
            font-size: 20px;
        }

        .title {
            text-shadow: 0 0 3px rgb(64, 70, 90);
        }
        .description {
            margin: 10px auto 10px 0;
            max-width: 50%;
            text-shadow: 0 0 3px rgb(64, 70, 90);
            font-size: 14px;

            @include mq(tablet) {
                max-width: none;
                margin: 10px 0;
            }
        }

        .links {
            .buyNow {
                position: relative;
                display: inline-block;
                padding: 8px 20px;
                background-color: $primaryColor;
                font-weight: bold;
                text-transform: uppercase;
                color: $buttonText;
                cursor: pointer;

                /*
                transition: $hoverTransition;
                &:hover {
                    filter: $hoverFilter;
                }
                 */

                // fixme: when this gets integrated into new style system,
                // fixme: use the animatingButton() mixin instead
                transition: filter .5s linear;
                overflow: hidden;

                &:before {
                    z-index: -1;
                    content: '';
                    position: absolute;
                    left: 0;
                    right: -50px;
                    top: 0;
                    border-right: 20px solid transparent;
                    border-bottom: 80px solid rgba(255, 255, 255, 0.2);
                    transform: translateX(-100%);
                    transition: transform .5s linear;
                }

                &:hover {
                    filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, .3));

                    &:before {
                        transform: translateX(0);
                    }
                }
            }
        }
    }

    .FixedFooter {
        pointer-events: none;
        opacity: 0;
        transition: opacity 500ms;
        margin-top: 80px;

        @include mq-mobile-only {
            opacity: 1;
            pointer-events: auto;

            .FixedFooter-inner {
                background-image: linear-gradient(to top, #000, #000 80%, transparent 100%);
                padding-top: 32px;
            }
        }
        @include mq-tablet-up {
            .FixedFooter-inner {
                background-color: rgba(30, 30, 30, .9);
            }
        }

        &.is-fixed {
            .FixedFooter-inner {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
            }
        }

        &.is-visible {
            opacity: 1;
            pointer-events: auto;
        }
    }

    #package-footer {
        .Carousel {
            @include mq-mobile-only {
                &-wrapper {
                    overflow-x: auto;
                }
                &-controls {
                    .Carousel-prev {
                        left: 0;
                        background-color: rgba(0,0,0,0);
                    }
                    .Carousel-next {
                        right: 0;
                        background-color: rgba(0,0,0,0);
                    }
                    .Icon {
                        transform: scale(0.5);
                    }
                }
            }
            &-control {
                height: 64px;
                width: 64px;
                padding: 12px;
                background-color: rgba(0,0,0,.5);
            }
            &-button {
                height: 40px;
                width: 40px;
                filter: invert(1);
            }
            &-prev {
                left: 10%;
                @include mq-tablet-only {
                    left: 5%;
                }
            }
            &-next {
                right: 10%;
                @include mq-tablet-only {
                    right: 5%;
                }
            }
        }
        .CarouselSlide {
            &.is-disabled, &.is-purchased {
                filter: grayscale(1);
            }
        }
        .FooterCarouselGrid {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            gap: 16px;
            width: 60%;
            margin: 16px auto;

            .Icon--footer{
                width: 30px;
                height: 30px;
            }

            &-plat {
                display: inline-grid;
                grid-template-columns: auto 1fr;
                gap: 4px;
                align-items: center;
                white-space: nowrap;
            }

            @include mq-mobile-only {
                width: auto;
                margin: 16px 5vw;
                grid-template-columns: 1fr;
                text-align: center;

                &-image {
                    display: none;
                }
                &-text {
                    .LastChanceBannerGlyph {
                        position: relative;
                        height: 60px;
                        width: 100%;
                        border-bottom: 1px solid rgba(167, 132, 78, 1);
                        margin: 0 0 10px;

                        .LastChanceTag {
                            display: inline-grid;
                            grid-template-columns: auto 1fr;
                            gap: 4px;
                            padding: 0 4px 10px;
                            &-headline {
                                display: inline-block;
                                font-size: 16px;
                                text-transform: uppercase;
                                color: rgba(245, 230, 160, 1);
                            }
                        }
                        .LastChanceTag-text {
                            color: rgba(255, 255, 255, 1);
                            font-size: 12px;
                        }
                    }

                    .LastChanceBannerGlyph::before,
                    .LastChanceBannerGlyph::after {
                        content: '';
                        position: absolute;
                        width: 50px;
                        height: 50px;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }

                    .LastChanceBannerGlyph::before {
                        top: 0;
                        left: 0;
                        background-image: url(/images/promo/last-chance/mobile-glyph.svg); /* URL to your top-left SVG */
                        transform: rotate(0deg);
                    }

                    .LastChanceBannerGlyph::after {
                        top: 0;
                        right: 0;
                        background-image: url(/images/promo/last-chance/mobile-glyph.svg); /* URL to your bottom-right SVG */
                        transform: rotate(180deg);
                    }

                }
                &-title {
                    font-size: 18px;
                    font-weight: 700;
                }
            }
            @include mq-tablet-only {
                width: 70%;
            }

            @include mq-tablet-up {
                &-image {
                    width: 220px;
                    padding-bottom: 56.25%;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    position: relative;
                    
                    .LastChanceBanner {
                        background: none;
                        position: absolute;
                        bottom: 0;
                        left: auto;
                        top: auto;
                        height: auto;
                        width: 100%;
                        .SaleTag {
                            width: 100%;
                        }
                    }
                }
                &-text {
                    .LastChanceBannerGlyph {
                        display: none;
                    }
                }
                &-title {
                    font-size: 28px;
                    font-weight: 700;
                    line-height: 1.3;
                }
            }
            .PriceTag {
                margin-top: 8px;
            }
        }
    }

    .recommended {
        .recommendedTitle {
            color: $primaryColor;
            margin: 30px 15px 40px;
            text-align: center;
            letter-spacing: $recommendedSpacingOverride;

            @include mq(tablet) {
                text-align: left;
            }
        }

        .recommendedCards {
            position: relative;

            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 40px;
            margin: 20px;

            @include mq(tablet) {
                grid-template-columns: 1fr;
                grid-gap: 30px;
                margin: 15px;
            }

            .recommendedCard {
                position: relative;
                padding-bottom: 55.55%;
                background-color: rgba(0,0,0,.3);
                background-size: cover;
                background-position: center;

                cursor: pointer;
                color: $textColor;

                @include mq(tablet) {
                    padding-bottom: 75%;
                }

                .content {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    padding: 30px 15px 15px;

                    background: linear-gradient(to bottom, transparent, rgba(0,0,0,.8) 40px);

                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;

                    @include mq(tablet) {
                        flex-flow: column nowrap;
                        align-items: flex-start;
                    }

                    .contentText {
                        flex: 1 1 auto;

                        .title {
                            font-size: 20px;
                            text-transform: uppercase;
                        }
                        .description {
                            margin-top: 5px;
                            color: #b1b1b1;
                        }
                    }
                    .contentActions {
                        flex: 0 0 auto;

                        @include mq(tablet) {
                            margin-top: 8px;
                        }

                        .learnMore {
                            display: inline-block;
                            background-color: #d40000;
                            padding: 10px 20px;
                            margin-left: 15px;

                            text-transform: uppercase;
                            color: #fff;

                            @include mq(tablet) {
                              margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
