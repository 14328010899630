// FIXME: This is a duplicate of the partial under themes/includes
// This is still being used by the flip-card stylesheet,
// but when we get around to updating the plat bundles page
// this stylesheet should be consolidated/removed
@import "../utils/mixins";
@import "../utils/variables";

.TokenGrid {
    display: grid;
    grid-template-columns: 1fr;
    color: black;

    margin-top: 40px;

    @include mq-ipad-up {
        display: inline-grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 20px;
        justify-content: center;
        align-items: stretch;
        //justify-items: center;
    }

    @include mq-desktop-up {
        gap: 40px;
    }
}

.TokenCard {
    position: relative;
    text-align: left;
    cursor: pointer;

    top: 0;
    left: 0;

    @include mq-ipad-not {
        &:not(:first-of-type) {
            border-top: solid 1px #AC9845;
        }
    }
    @include mq-ipad-up {
        padding: 2px;
        background: linear-gradient(0.35turn, #f9f9d6, #ac9945, #f9f9d6, #ac9945,#f9f9d6, #ac9945);
        // repeating-linear-gradient isn't fully supported in IE, hence the double-rule
        background: repeating-linear-gradient(135deg, #d0b549 0, #FFFFC5 4rem, #d0b549 8rem);
        box-shadow: 0 0 39px #6d6a6a;
    }
    &:hover {
        top: -2px;
        left: -2px;

        .TokenCard-image {
            //background-size: 100% 100%;
        }
        .TokenCard-footer {
            filter: brightness(107%);
        }
    }
}

.TokenCard-inner {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    height: 100%;

    @include mq-ipad-not {
        padding: 10px 0;
    }
    @include mq-ipad-up {
        grid-template-columns: 1fr;
        background-color: white;
        position: relative;
    }
}

.TokenCard-content {
    text-align: center;

    @include mq-ipad-up {
        padding-bottom: 20px;
    }
    &.prelaunch {
        color: #4f4f4f;
    }
}
.TokenCard-prelaunchPrice {
    font-weight: bold;
}
.TokenCard-image {
    position: relative;
    display: none;
    @include mq-ipad-up {
        display: block;
        width: 100%;
        max-width: 242px;
        margin: 0 auto -1rem;
    }
}
.TokenCard-text {
    text-align: left;
    @include mq-ipad-up {
        text-align: center;
    }
}
.TokenCard-title {
    font-size: 18px;
    font-weight: 700;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 1%;

    @include mq-ipad-up {
        font-size: 24px;
    }
    @include mq-desktop-up {
        font-size: 26px;
    }
}
.TokenCard-subtitle {
    font-size: 14px;
    padding-left: 3%;
}
.TokenCard-plat {
    font-size: 14px;
    padding-left: 3%;
    display:flex;
    justify-content: center;
    align-items: center;
    .TokenCard-platImage {
        width: 30px;
        margin-right: 3px;
        top: 3px;
    }
    
    @media (min-width: 600px) and (max-width: 768px) {
        justify-content: unset;
}

    // Justify left if mobile
    @include mq(mobile) {
        justify-content: unset;
    }
}

.Token-desktop {
    font-weight: bold;
    position: relative;
    margin: -0.25em 0;

    @include mq(tablet) {
        display: none;
    }
}

.Token-mobile {
    display: none;
    @include mq(tablet) {
        display: unset;
    }
}

.TokenCard-footer {
    align-self: end;
    background: linear-gradient(0.3turn, #d0b549, #ffffc4, #d0b549, #ffffc4, #d0b549);
    // repeating-linear-gradient isn't fully supported in IE, hence the double-rule
    background: repeating-linear-gradient(135deg, #d0b549 0, #FFFFC5 4rem, #d0b549 8rem);

    -webkit-transition: filter 100ms linear;
    -moz-transition: filter 100ms linear;
    -o-transition: filter 100ms linear;
    -ms-transition: filter 100ms linear;
    transition: filter 100ms linear;

    @include mq-ipad-not {
        background: repeating-linear-gradient(160deg, #d0b549 0, #FFFFC5 1rem, #d0b549 2rem);
        padding: 2px;
    }
}

.TokenCard-footerInner {
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;

    @include mq-ipad-not {
        background: linear-gradient(127.96deg, #D1B74C 0%, #F4EEA6 51.04%, #D1B74C 100%);
        padding: 5px;
        white-space: nowrap;

        .actualPrice {
            white-space: nowrap;
            font-size: 18px;
        }
        .buyNow {
            font-size: 12px;
        }
    }
    @include mq-ipad-up {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        min-height: 48px;

        .actualPrice {
            flex: 1 1 auto;
            white-space: nowrap;
            display: grid;
            justify-content: center;
            align-items: center;
            margin: 5px 10px;

            .sign, .priceLarge {
                grid-row: 1 / span 2;
                font-size: 32px;
            }

            .sign {
                grid-column: 1;
            }

            .priceLarge {
                grid-column: 2;
            }

            .priceSmall {
                align-self: end;
                grid-column: 3;
                grid-row: 1;
                margin: 2px 0 -2px;
                font-size: 20px;
            }

            .currency {
                align-self: start;
                grid-column: 3;
                grid-row: 2;
                font-size: 11px;
            }
        }

        .buyNow {
            flex: 1 1 auto;

            border-left: 1px solid #000;
            font-size: 25px;
            font-weight: bold;
            padding: 0 10px;
            margin: 5px 0;
        }
    }
}
