@import "../style-guide/_variables";
@import "../style-guide/_responsive";
@import "../style-guide/_mixins";
@import "../style-guide/_typography";


#cross-progression {

    .accountOptions {
        //border-top: 1px solid #d1d2d1;
        margin-top: 30px;
        margin-bottom: 20px;

        #accountOptionContain {
            padding-top: 20px;

            .optionContain {
                display: flex;
                justify-content: center;
                align-items: stretch;
                grid-column: 1/-1;
                line-height: 1.4;

                .accountCard {
                    width: 100%;
                    margin: 0 auto;
                    background: #fff;
                    max-width: 860px;
                    min-height: 200px;
                    display: flex;
                    flex-direction: column;

                    @include mq(tablet) {
                        min-height: 154px;
                    }

                    @include mq(mobile) {
                        min-height: 76px;
                    }

                    ::-webkit-scrollbar {
                        width: 5px;
                    }

                    ::-webkit-scrollbar-track {
                        background: lightgrey;
                    }

                    ::-webkit-scrollbar-thumb {
                        background: #d30000;
                    }

                    .returnContent {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;

                        a {
                            font-size: 18px;
                            color: black;
                            text-decoration: underline;

                            &:hover {
                                color: #535353;
                            }
                        }

                        .backIcon {
                            margin-right: 4px;
                            display: flex;
                            align-items: center;
                        }
                    }

                    .accountCardBody {
                        position: relative;
                        padding: 30px;
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        justify-content: space-between;
                        background-size: cover;
                        background-position: right center;
                        background-repeat: no-repeat;
                        @include mq(mobile) {
                            background-position: right -135px center;
                        }

                        .titleContent {
                            flex: 1;
                            width: 65%;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            align-items: flex-start;
                            z-index: 1;

                            .h2 {
                                text-shadow: 0 0 5px black;
                                font-size: 36px;

                                @include mq(mobile) {
                                    font-size: 24px;
                                }

                            }
                        }

                        .xsaveResult {
                            .success-message, .error-message {
                                display: flex;
                                align-items: center;

                                .checkIcon-container, .errorIcon-container {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    height: 33px;
                                    width: 33px;
                                    margin-right: 10px;

                                    .checkIcon, .errorIcon {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border-radius: 50%;
                                        height: 33px;
                                        width: 33px;

                                    }

                                    .checkIcon {
                                        background-color: white;
                                    }

                                    .errorIcon {
                                        background-color: #940000;
                                        padding-bottom: 3px;


                                        //&-icon {
                                        //    width: 19px;
                                        //}
                                    }
                                }

                                p {
                                    color: white;
                                    font-size: 16px;

                                    b {
                                        font-weight: bold;
                                        text-transform: uppercase;
                                    }
                                }

                            }

                            .error-message {
                                p {
                                    color: black;
                                    b {
                                        color: #940000;
                                        font-size: 18px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }


                        .crossProgressContent {
                            .crossSaveAccount {
                                margin-bottom: 25px;
                                width: 100%;
                                border: 2px solid black;

                                .accountLabel {
                                    color: white;
                                    background-color: black;
                                    padding: 15px;
                                    font-weight: bold;
                                    font-size: 14px;
                                }

                                .account {
                                    display: flex;
                                    align-items: flex-start;
                                    background-color: #D9D9D9;
                                    padding: 20px;
                                    height: auto;

                                    .platformIcons {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        //margin-top: 8px;

                                        .pc-container, .psn-container,
                                        .xbox-container, .switch-container, .ios-container {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            margin-right: 15px;
                                            min-height: 44px;
                                            min-width: 44px;
                                            //border-radius: 4px;
                                            //margin-bottom: 8px;

                                            img {
                                                max-height: 27px;
                                            }

                                        }

                                        .pc-container {
                                            background-color: $COLOR_PC_ICON;
                                        }
                                        .psn-container {
                                            background-color: $COLOR_PSN_ICON;
                                        }
                                        .xbox-container {
                                            background-color: $COLOR_XBOX_ICON;
                                        }
                                        .switch-container {
                                            background-color: $COLOR_NSW_ICON;
                                        }
                                        .ios-container {
                                            background-color: $COLOR_IOS_ICON;
                                        }
                                    }

                                    /*.platformImage {
                                        margin-right: 15px;
                                        height: 44px;
                                        width: 44px;
                                    }
                                     */

                                    .account-info {
                                        padding-top: 15px;

                                        .username {
                                            font-weight: bold;
                                            margin-bottom: 15px;
                                            font-size: 18px;
                                        }
                                    }
                                }
                            }


                            .linkedAccounts {
                                margin-bottom: 20px;
                                width: 100%;

                                .accountLabel {
                                    margin-bottom: 15px;
                                    font-weight: bold;
                                }

                                .account {
                                    display: flex;
                                    align-items: center;
                                    background-color: #D9D9D9;
                                    padding: 20px;
                                    height: auto;
                                    margin-bottom: 15px;

                                    img {
                                        margin-right: 15px;
                                        height: 44px;
                                        width: 44px;
                                    }

                                    .username {
                                        font-weight: bold;
                                    }
                                }
                            }

                            .loggedInAccount {
                                margin-bottom: 25px;
                                width: 100%;

                                .accountLabel {
                                    margin-bottom: 15px;
                                }

                                .account {
                                    display: flex;
                                    align-items: center;
                                    background-color: #D9D9D9;
                                    padding: 20px;
                                    height: auto;

                                    .pc-container, .psn-container, .xbox-container,
                                    .switch-container, .ios-container {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        margin-right: 15px;
                                        min-height: 44px;
                                        min-width: 44px;
                                        //border-radius: 4px;
                                        //margin-bottom: 8px;

                                        img {
                                            max-height: 27px;
                                        }
                                    }
                                    .pc-container {
                                        background-color: $COLOR_PC_ICON;
                                    }
                                    .psn-container {
                                        background-color: $COLOR_PSN_ICON;
                                    }
                                    .xbox-container {
                                        background-color: $COLOR_XBOX_ICON;
                                    }
                                    .switch-container {
                                        background-color: $COLOR_NSW_ICON;
                                    }
                                    .ios-container {
                                        background-color: $COLOR_IOS_ICON;
                                    }

                                    .username {
                                        font-weight: bold;
                                    }
                                }

                                .accountsAvailable {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    margin-top: 20px;
                                    margin-bottom: 0;

                                    @include mq(mobile) {
                                        display: block;
                                    }

                                    .platformIcons {
                                        display: flex;
                                        align-items: center;
                                        //justify-content: flex-start;
                                        //margin-top: 8px;

                                        @include mq(mobile) {
                                            padding-top: 15px;
                                        }

                                        .pc-container, .psn-container,
                                        .xbox-container, .switch-container, .ios-container {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            margin-right: 15px;
                                            min-height: 50px;
                                            min-width: 50px;
                                            //border-radius: 4px;
                                            //margin-bottom: 8px;

                                            img {
                                                max-height: 30px;
                                            }

                                        }

                                        .pc-container {
                                            background-color: $COLOR_PC_ICON;
                                        }
                                        .psn-container {
                                            background-color: $COLOR_PSN_ICON;
                                        }
                                        .xbox-container {
                                            background-color: $COLOR_XBOX_ICON;
                                        }
                                        .switch-container {
                                            background-color: $COLOR_NSW_ICON;
                                        }
                                        .ios-container {
                                            background-color: $COLOR_IOS_ICON;
                                        }

                                        /*
                                        img {
                                            //margin-left: 0;
                                            margin-right: 15px;
                                            height: 50px;
                                            width: 50px;
                                        }
                                         */
                                    }

                                }
                            }



                            .moreAccounts {
                                border-top: 2px solid darkgrey;

                                .moreAccounts-content {
                                    margin-top: 20px;
                                    margin-bottom: 20px;
                                    flex: 1;
                                    width: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    align-items: flex-start;
                                    z-index: 1;

                                    .moreTitle {
                                        font-size: 16px;
                                        font-weight: bold;
                                        margin-bottom: 10px;
                                    }

                                    .moreDesc {
                                        a {
                                            color: black;
                                            text-decoration: underline;

                                            &:hover {
                                                opacity: 80%;
                                                color: black;
                                            }

                                        }
                                    }

                                    .error-message {
                                        display: flex;
                                        align-items: center;
                                        padding: 8px 0;

                                        .errorIcon-container {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            height: 33px;
                                            width: 33px;
                                            margin-right: 8px;

                                            .errorIcon {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                //border-radius: 50%;
                                                //height: 33px;
                                                //width: 33px;

                                                .error {
                                                    width: 22px;
                                                    height: 19px;
                                                    filter: brightness(0) saturate(100%) invert(10%) sepia(60%) saturate(7095%) hue-rotate(357deg) brightness(86%) contrast(111%);
                                                }
                                            }
                                        }

                                        p {
                                            color: #940000;
                                            font-weight: bold;
                                        }
                                    }

                                    .button-columns {
                                        display: grid;
                                        grid-template-columns: 50% 50%;
                                        justify-content: space-between;
                                        width: 100%;
                                        padding-top: 20px;

                                        @include mq(tablet) {
                                            grid-template-columns: 40% 40%;
                                        }


                                        @include mq(mobile) {
                                            display: block;
                                        }

                                        .accountLink {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 15px;

                                            a {
                                                .Button {
                                                    white-space: nowrap;

                                                    @include mq(tablet) {
                                                        padding: 0 25px;
                                                    }
                                                }
                                            }

                                            .psn-container, .xbox-container,
                                            .switch-container, .ios-container {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                margin-right: 15px;
                                                min-height: 44px;
                                                min-width: 44px;
                                                //border-radius: 4px;
                                                //margin-bottom: 8px;

                                                img {
                                                    max-height: 27px;
                                                }

                                            }

                                            .psn-container {
                                                background-color: $COLOR_PSN_ICON;
                                            }
                                            .xbox-container {
                                                background-color: $COLOR_XBOX_ICON;
                                            }
                                            .switch-container {
                                                background-color: $COLOR_NSW_ICON;
                                            }
                                            .ios-container {
                                                background-color: $COLOR_IOS_ICON;
                                            }

                                            /*
                                            img {
                                                margin-right: 15px;
                                                height: 44px;
                                                width: 44px;
                                            }
                                             */
                                        }
                                    }
                                }
                            }

                            .createPCAccount {
                                border-top: 2px solid darkgrey;

                                .createPCAccount-content {
                                    margin-top: 20px;
                                    margin-bottom: 20px;
                                    width: 100%;

                                    .downloadNotNeeded {
                                        span {
                                            font-weight: bold;
                                        }
                                    }

                                    .needPCAccount {
                                        font-size: 16px;
                                        color: #B30000;
                                        font-weight: bold;
                                        margin-bottom: 16px;
                                    }

                                    .createDesc {
                                        margin-bottom: 8px;
                                    }

                                    .learnMore {
                                        margin-bottom: 16px;

                                        a {
                                            color: black;
                                            text-decoration: underline;

                                            &:hover {
                                                opacity: 80%;
                                                color: black;
                                            }

                                        }
                                    }

                                    .PCAccount-question {
                                        font-size: 16px;
                                        font-weight: bold;
                                        margin-bottom: 16px;
                                    }

                                    .PCAccount-options {
                                        background-color: white;
                                        padding: 10px;

                                        .PCAccount-answer {
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;

                                            @include mq(mobile) {
                                                display: block;
                                            }

                                            &.yes {
                                                padding-bottom: 30px;
                                            }

                                            .yesPCAccount, .noPCAccount {
                                                display: flex;
                                                align-items: center;
                                                margin-right: 15px;


                                                .yesIcon-container, .noIcon-container {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    height: 33px;
                                                    width: 33px;
                                                    margin-right: 10px;

                                                    .yesIcon, .noIcon {
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        border-radius: 50%;
                                                        height: 33px;
                                                        width: 33px;

                                                        .checkmark {
                                                            filter: invert(1);
                                                        }

                                                        .x {
                                                            filter: invert(1);
                                                        }
                                                    }

                                                    .yesIcon {
                                                        background-color: #159F2B;
                                                    }

                                                    .noIcon {
                                                        background-color: #E60012;
                                                    }
                                                }

                                                .yesPCAccountDesc, .noPCAccountDesc {

                                                    b {
                                                        font-weight: bold;
                                                        text-transform: uppercase;
                                                        font-size: 18px;
                                                    }
                                                }
                                            }

                                            a {
                                                .Button {
                                                    width: 225px;
                                                    padding: 0 30px;

                                                    @include mq(mobile) {
                                                        margin-top: 15px;
                                                        width: 100%;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &#title {
                    #titleCard {
                        .accountCardBody {
                            background-color: rgb(0, 0, 0);

                            @include mq(mobile) {
                                padding: 10px;
                            }

                        }

                        .cardBackgroundImage {
                            width: auto;
                            max-width: 100%;
                            height: 100%;
                            object-fit: cover;
                            top: 0;
                            right: 0;
                            position: absolute;
                        }
                    }
                }

                &#result {
                    .accountCard {
                        min-height: 0;
                    }
                    #resultCard {
                        .accountCardBody {
                            background-color: #116A1E;

                        }
                    }
                }

                &#crossProgress {
                    #crossProgressCard {
                        background-color: #F1F1F1;
                    }
                }
            }
        }
    }
}
