// Twitch account linking flow
// todo: migrate to theme sheet when page is updated
$purple: #9146FF;
body.twitch, body.twitch_link, body.twitch_link_success {
    background-color: white;
    .wrapper {
        background: url('../images/promo/twitch-free-prime/broca.jpg') !important;
        background-repeat: no-repeat !important;
        background-position: center -10px !important;
        background-size: cover !important;
    }

    #basicTextContain {
        background: none !important;
    }

    #twitch-logo {
        display: block;
        margin: 100px auto 0 auto;
    }

    #mainContent {
        .text {
            margin: 40px 0;
            text-align: center;

            h2 {
                margin-bottom: 40px;
                font-size: 24pt;
                color: $purple;
                text-align: center;
                line-height: 1em;
            }

            p {
                width: 520px;
                position: relative;
                margin: 0 auto;
            }
        }

        button, .button {
            display: table;
            margin: 0 auto;
            background-color: $purple;
            height: auto;

            img {
                vertical-align: middle;
                margin-top: -5px;
            }

            .button-wrap {
                color: white !important;
                padding: 3px 0;
            }
        }
    }

}
