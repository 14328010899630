// Warframe Arsenal twitch extension page
// todo: migrate to theme sheet when page is updated
@import "../utils/mixins";

$purple: #9146FF;
$red: #d30000;

body.twitch_extension {
    background-color: #E6E6E6;
    color: black;

    .extensionContainer {
        width: 60%;
        margin: 7% auto 2% auto;
        text-align: left;

        @include mq(tablet) {
            width: 80%;
            margin-top: 9%;
        }

        @include mq(mobile) {
            margin-top: 12%;
        }


        div {
            margin-bottom: 2%;
        }

        .title {
            //font-family: "Michroma", "Open Sans", Helvetica, sans-serif;
            color: $red;
            text-align: center;
            font-size: 2em;
            text-transform: uppercase;
        }

        .details {
            text-align: center;
            margin-bottom: 4%;
        }

        .subtitle {
            //font-family: "Michroma", "Open Sans", Helvetica, sans-serif;
            color: $red;
            font-size: 1.4em;
            text-transform: uppercase;
        }

        .bold {
            font-weight: bold;
            margin-bottom: 0%;
        }

        .mobileImage {
            display: none;
            width: 100%;

            @include mq(tablet) {
                display: block;
            }
            img {
                width: 100%;
            }
        }

        .description {
            display: flex;
            flex-direction: row;
            margin-bottom: 4%;

            .list {
                width: 55%;
                @include mq(tablet) {
                    width: 100%;
                }
                ul {
                    list-style-type: disc;
                    list-style-position: outside;
                    margin-top: 2%;
                    width: 90%;
                    position: relative;
                    left: 3%;

                    li {
                        margin-bottom: 3%;
                    }
                }
            }

            .image {
                display: block;
                width: 45%;

                @include mq(tablet) {
                    display: none;
                }
                img {
                    width: 100%;
                }
            }
        }

        .screenShots {
            display: flex;
            flex-direction: row;
            margin-bottom: 4%;

            @include mq(tablet) {
                display: block;
            }

            img {
                width: 49%;
                box-shadow: 0 0 12px 1px rgba(0,0,0,0.4);

                @include mq(tablet) {
                    width: 100%;
                }
                &:first-of-type {
                    margin-right: 2%;
                    @include mq(tablet) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
