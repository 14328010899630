$black: #000000;
$pcRed: #B30000;
$ps4Blue: #034169;
$xb1Green: #005400;
$blogPurple: #730D6A;
$steamGrey: #212121;
$white: #ffffff;

$warframeRed: #B30000;
$fontColor: #686868;
$borderColor: #a0a0a0;
$primeGold: #c79616;
$gold: #e4bc53;

$openSansFont: 'Open Sans', sans-serif;
//$futureFont: "Michroma", "Open Sans", Helvetica, sans-serif;
$futureFont: "Noto Sans", Arial, Helvetica, sans-serif;
