@import "../utils/_mixins";
@import "../utils/_variables";

$COLOR_VERY_LIGHT_RED: #F9EBEB;
$COLOR_DARK_RED: #940000;
$YELLOW: #FFA451;
$LIGHT_YELLOW: #FFDCBB80;
$COLOR_DARKER_GRAY: #4F4F4F;
$LIGHT_GRAY: #F2F2F2;

body.android_beta_optin,
body.android_prereg_optin {


    /* [data-state] {
        position: relative;
        outline: 2px dashed rgba( black, 0.9 );
        outline-offset: -1px;

        & * {
            outline-width: 1px;
            outline-color: rgba( black, 0.5 );
        }

        &::after {
            content: attr( data-state );
            position: absolute;
            top: 0;
            right: 100%;
            z-index: 5;
            padding: 10px;
            background-color: rgba( black, 0.9);
            color: white;
            font-family: monospace;
            font-size: 12px;
            line-height: 1.2;
        }
    } */

    strong {
        font-weight: 700;
    }

    #bgimg{
        max-width: 100%;
        height: auto;
        width: auto;
        mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0));
        display: flex;
        filter: grayscale(100%);
        
        @include mq(tablet) {
            max-width: 150%;
        }

        @include mq(mobile) {
            max-width: 200%;
        }
    }

    #imageContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: cover;
        overflow-x: hidden;
        position: relative;   
    }

    #android{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        min-height: 100%;
        text-align: center;
        position: relative;
        top: -130px;

        @media(max-width:1440px) {
            top: -100px;
        }

        @include mq(tablet) {
            top: 0px;
        }

        @include mq(mobile) {
            top: 0px;
        }

        a {
            text-decoration: underline;
        }

        p {
            margin-top: 10px;
        }

    }

    #opt-in,
    #pre-registration {
        max-width: 650px;
        margin-bottom: 32px;
        text-align: left;

        @include mq(tablet) {
            width: 80%;
        }

        @include mq(mobile) {
            width: 80%;
        }

        .lotus {
            width: 150px;
            height: 90px;
            position: absolute;
            top: -80px;
            left: 0;
            right: 0;
            margin: auto;

            @include mq-mobile-only {
                height: 60px;
                top: -60px;
            }
        }

        #join {
            margin-top: 8px;
        }

        p {
            line-height: 24px;
        }

        a {
            color: black;
        }
    }

    #pre-registration #form_section {
        width: auto;
    }

    .beta {
        text-align: unset !important;

        a {
            text-decoration: none !important;
            color: $pcRed !important;
        }
    }

    #thank {
        text-align: left;
    }

    .label {
        padding-top: 8px;
        color: $COLOR_DARKER_GRAY;
        font-size: 14px;
    }

    #form_section{
        width: 600px;

        @include mq(tablet) {
            width: 80%;
        }

        @include mq(mobile) {
            width: 80%;
        }
    }

    #notLoggedin {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include mq(mobile) {
            margin-top: 24px;
        }

        hr {
            margin-top: calc( 3rem - #{ $SPACE_MEDIUM } );
            margin-bottom: $SPACE_MEDIUM;
        }
    }

    #dob {
        display: inline-block;
        margin-bottom: 0px;
        border-bottom: 1px solid black;
        height: 33px;
        width: 100%;
        border-radius: 0px;
    }

    .flatpickr-input, .flatpickr-mobile{
        display: inline-block;
        margin-bottom: 0px;
        border-bottom: solid 1px black;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        height: 33px;
        min-width: 97%;
        vertical-align: middle;
        border-radius: 0px;
        background-color: $LIGHT_GRAY;
    }

    #dropdown {
        display: inline-block;
        margin-top: 16px;
        margin-bottom: 0px;
        width: 100%;
    }

    #optin {
        margin-top: 24px;
        font-weight: bold;
    }

    .optInButton {
        display: block;
        position: relative;
        font-weight: bold;
    }

    #account{
    }

    #login {
    }

    #title {
        text-align: center;
    }
    
    #notificationHolder{
        display: none;
    }

    .error {
        width: 600px;
        color: $COLOR_DARK_RED;
        background-color: $COLOR_VERY_LIGHT_RED;
        border: solid $COLOR_DARK_RED;
        border-width: 1px;
        padding: 8px 12px 8px 12px;
        margin-bottom: 32px;
        
        @include mq(tablet) {
            width: 80%;
        }

        @include mq(mobile) {
            width: 80%;
        }
    }
    
    #dropdown-error {
        background-color: white;
        border: transparent;
        border-width: 0px;
        padding-left: 0px;
        margin-bottom: -24px;
    }

    #dob-error {
        background-color: white;
        border: transparent;
        border-width: 0px;
        padding-left: 0px;
        width: auto;
        margin-bottom: -8px;
    }

    #notSupported {
        margin-top: 16px;
        background-color: $LIGHT_YELLOW;
        border: solid $YELLOW;
        border-width: 1px;
        color: black;
        display: none;
    }

    #phone{
        min-height: 163.97px;
        min-width: 335.34px;
        object-fit: contain;
        width: 40%;
        height: 50%;

        background-image: url("../images/promo/android/phone-android.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        position: absolute;
        top: 0;
        left: 0;
        bottom: 20%;
        right: 0;
        margin: auto auto auto auto;
        filter: brightness(0.5);
        animation: darken 0.5s ease-out both;

        @include mq(mobile) {
            bottom: 0;
        }
        @include mq(tablet) {
            width: 80%;
        }

        img {
            position: relative;
            max-height: 160%;
            top: -25%;
            left: 50%;
            transform: translateX(-50%);
        }
    }


    @keyframes darken {
        100% {
            filter: brightness(1);
        }
    }

    @keyframes popup{
        100% {
            max-height: 100%;
            opacity: 1;
        }
    }

    @keyframes popupMesa{
        100% {
            max-height: 125%;
            opacity: 1;
            transform: translate(17%, 10%);
        }
    }

    @keyframes popupBaruuk {
        100% {
            max-height: 125%;
            opacity: 1;
            transform: translate(-23%, 10%);
        }
    }

    .ButtonGroup {
        margin: $SPACE_MEDIUM auto;

        button {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
