@import "../style-guide/mixins";
@import "../utils/mixins";
@import "../utils/variables";

$packColor: #042632;
$bronzePackColor: #83512f; //259
$silverPackColor: #798795; //258
$goldPackColor: #9f852c; //257
$couponRed: rgb(179, 0, 0);


#marketThankYouModal, #buyMorePlatModal, #buyMarketModal {
    max-width: 1280px;
    padding: 20px;
    min-width: 600px;

    .colorboxClose {
        position: absolute;
        top: -2px;
        right: 7px;
        cursor: pointer;
    }

    @include mq-tablet-down {
        max-width: 600px;
        min-width: 325px;
    }

    @include mq-mobile-only {
        min-width: unset;
        max-width: 425px;
    }

    .priceBtn {
        cursor: pointer;
    }

    .platIcon {
        width: 40px;
        height: 40px;
        margin-right: 8px;
    }
}

#buyMorePlatModal, #buyMarketModal {
    display: flex;
    gap: 20px;

    @include mq-mobile-only {
        padding: 0;
    }


    .additionalPlatContainer {
        width: 65%;
        @include mq-tablet-down {
            margin: 0 auto;
        }
        @include mq-mobile-only {
            width: 100%;
        }
        .additionalPlatHeader {
            border-bottom: black 1px solid;
            margin-bottom: 8px;

            @include mq-mobile-only {
                display: none;
            }
            div {
                margin-bottom: 8px;
            }
        }

        .additionalPlatSubheader {
            .couponContainer {
                display: flex;
                padding: 20px 10px;
                flex-direction: row;
                margin-bottom: 8px;
                background-color: $couponRed;
                border-radius: 4px;
                gap: 8px;
                color: white;

                .couponDesc {
                    text-transform: uppercase;
                }

                .iconHolder {
                    position: relative;
                    background-color: white;
                    border-radius: 20px;
                    width: 41px;
                    height: 41px;
                    margin-left: 8px;
                    top: 2px;

                    img {
                        position: absolute;
                        filter: invert(12%) sepia(47%) saturate(6066%) hue-rotate(353deg) brightness(106%) contrast(119%);
                        top: 11px;
                        left: 11px;
                    }
                }
            }

            @include mq-mobile-only {
                padding: 0 16px;
            }
        }

        .buyPlatPacks {
            display: grid;
            gap: 5px 24px;
            grid-template-columns: repeat(3, 30%);

            @include mq-mobile-only {
                padding: 0 16px;
                gap: 5px 4%;
                grid-template-columns: repeat(2, calc(48% - 16px));
                grid-template-rows: repeat(3, 1fr);
                grid-auto-flow: column;
            }

            .platPacks {
                position: relative;

                .flagContainer {
                    position: relative;
                    background-color: $packColor;
                    color: white;
                    font-size: 12px;
                    width: fit-content;
                    padding: 2px 5px;

                    @include mq-mobile-only {
                        position: absolute;
                        top: 0px;
                        width: 100%;
                        text-align: center;
                    }
                }

                .packDisplay {
                    text-align: center;
                    border: 2px solid $packColor;
                    border-radius: 2px;
                    width: 100%;
                    height: calc(100% - 22px);
                    padding: 5px;
                    display: grid;
                    align-content: space-between;

                    @include mq-mobile-only {
                        padding-top: 22px;
                        height: 100%;
                    }

                    .modsText, .platText {
                        margin-bottom: 2px;
                        &.mobileOnly {
                            display: none;
                            @include mq-mobile-only {
                                display: block;
                            }
                        }
                    }
                    .platAmount, .modsText {
                        color: $packColor;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .modsText {
                        font-weight: normal;
                        text-transform: uppercase;
                        margin-top: -4px;
                        margin-bottom: 4px;
                    }
                    .bonusPlat {
                        margin-top: -12px;
                    }

                    .priceBtn {
                        border-radius: 4px;
                        background-color: $packColor;
                        color: white;
                        padding: 10px 0;

                        .primaryPrice {
                            font-weight: bold;
                        }
                    }

                    .strikethroughPrice {
                        text-decoration: line-through;
                    }
                }

                &.sku257 {
                    .flagContainer {
                        //background-color: $goldPackColor;
                    }
                    .platAmount, .modsText, .bonusPlat {
                        color: $goldPackColor;
                    }
                    .couponIcon {
                        filter: invert(51%) sepia(100%) saturate(312%) hue-rotate(9deg) brightness(84%) contrast(87%);
                    }
                    .packDisplay {
                        border-color: $goldPackColor;
                    }
                    .priceBtn {
                        background-color: $goldPackColor;
                    }
                }
                &.sku258 {
                    .flagContainer {
                        //background-color: $silverPackColor;
                    }
                    .platAmount, .modsText, .bonusPlat {
                        color: $silverPackColor;
                    }
                    .couponIcon {
                        filter: invert(53%) sepia(12%) saturate(497%) hue-rotate(169deg) brightness(97%) contrast(84%);
                    }
                    .packDisplay {
                        border-color: $silverPackColor;
                    }
                    .priceBtn {
                        background-color: $silverPackColor;
                    }
                }
                &.sku259 {
                    .flagContainer {
                        //background-color: $bronzePackColor;
                    }
                    .platAmount, .modsText, .bonusPlat {
                        color: $bronzePackColor;
                    }
                    .couponIcon {
                        filter: invert(29%) sepia(66%) saturate(457%) hue-rotate(341deg) brightness(98%) contrast(87%);
                    }
                    .packDisplay {
                        border-color: $bronzePackColor;
                    }
                    .priceBtn {
                        background-color: $bronzePackColor;
                    }
                }

                .flagContainer.flag--empty {
                    background-color: unset;
                }
            }

            &.multiplierCoupon {
                .packDisplay {
                    border-color: $packColor !important;
                    .platAmount {
                        color: $packColor;
                    }
                    &.hasCoupon {
                        .priceBtn {
                            background-color: $couponRed;
                        }
                    }

                    .bonusPlatAmount {
                        color: $couponRed;
                    }

                    .SaleTag-icon {
                        margin-right: -5px;
                        filter: invert(13%) sepia(43%) saturate(7195%) hue-rotate(354deg) brightness(92%) contrast(117%);
                    }

                }
                .bonusWord {
                    color: $couponRed;
                    font-weight: bold;
                }
            }
        }
    }


    .imgContainer {
        width: 100%;
        img {
            display: block;
            width: 100%;
        }
    }

    .packDisplay {
        margin: 0 auto;
        width: 100%;

        .packTitleSection {
            padding: 16px;
            background-color: rgb(39, 39, 39);
            .h2, .h4 {
                color: white;
            }
        }

        .platBalanceSection {
            padding: 16px;
            background: linear-gradient(209.97deg, rgba(217, 217, 217, 0) 27.63%, rgba(217, 217, 217, 0.15) 50.48%, rgba(217, 217, 217, 0) 83.74%),
            radial-gradient(159.47% 83.73% at 50% 54.67%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
            border: 2px solid;
            border-image-source: radial-gradient(159.47% 83.73% at 50% 54.67%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);

            .h3 {
                margin-bottom: 16px;
                display: flex;
            }
        }

        .btnContainer {
            text-align: center;
            margin-bottom: 16px;
        }
    }

    &.insufficientPlat {
        .platBalanceSection {
            .balance {
                color: rgb(148, 0, 0);
                display: flex;
            }
        }

        .packDisplay {
            @include mq-desktop-up {
                width: 35%;
                &.packDisplay--mobileTablet {
                    display: none;
                }
            }

            @include mq-tablet-down {
                width: 80%;
                &.packDisplay--desktop {
                    display: none;
                }
            }
        }
    }

    &.confirmPurchase {
        .market-item-form {
            width: 100%;

            .displaySection {
                display: grid;
                grid-template-columns: 40% 60%;

                @include mq-tablet-down {
                    grid-template-columns: 100%;

                    .imgContainer {
                        order: 1;
                    }
                    .packDisplay {
                        order: 2;
                    }
                }
            }
        }
    }
}
