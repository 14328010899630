body.promocode{
.container{
     min-width: 320px !important;
 }
    #mainPromocodeForm{

        .contentHeaderContain {

            h2{
                margin-top: -13px;
                font-size: 37px;
                line-height: 30px;
            }

            .contentHeaderLines{
                width: 15%;
            }
        }

        form{
            margin-top: 20px;
        }

        input[type="text"]{
            width: 100%;
        }

        input[type="submit"]{
            background-color: $pcRed;
            color: white;
            border-radius: 0px;
            //font-family: 'Open Sans',sans serif;
            font-size: 15px;
            border: none;
        }

        hr{
            margin-top: 80px;
            border-top: 1px solid #a0a0a0;
        }

        .redeemFaq{
            h3,p,h6{
                margin-bottom: 15px;
            }
            h6{
                color: $pcRed;
                font-weight: bold;
            }
        }
    }

    @include mq(tablet){
        #mainPromocodeForm{
            h2{
                margin-top: -32px !important;
                line-height: 32px !important;
            }

            .contentHeaderLines{
                display: none;
            }
        }
    }

    @media (max-width: 1024px) {
        #mainPromocodeForm {
            h2 {
                margin-top: -32px !important;
                line-height: 32px !important;
            }

            .contentHeaderLines {
                display: none;
            }
        }


            .wrapper{
                min-height: 91% !important;
            }

        #basicTextContain{
            width: 100%;
            background-color: rgba(255, 255, 255, 0.8);
            margin-top: 40px;
        }
    }
}

@media (max-width: 570px) {
    .lang-fr {
        #mainPromocodeForm .contentHeaderContain h2 {
            font-size: 22px !important;
        }
    }
}

@media (max-width: 320px) {
    .lang-fr {
        #mainPromocodeForm .contentHeaderContain h2 {
            font-size: 20px !important;
        }
    }
}
