@import "../style-guide/_variables";
@import "../style-guide/_responsive";
@import "../style-guide/_mixins";
@import "../style-guide/_typography";

#account-cross-link {
    .accountOptions {
        //border-top: 1px solid #d1d2d1;
        margin-top: 30px;
        margin-bottom: 20px;

        #accountOptionContain {
            padding-top: 20px;

            .optionContain {
                display: flex;
                justify-content: center;
                align-items: flex-start;

                .accountCard {
                    width: 100%;
                    margin: 0 auto;
                    background: #fff;
                    max-width: 860px;
                    min-height: 200px;
                    display: flex;
                    flex-direction: column;

                    @include mq(tablet) {
                        min-height: 154px;
                    }

                    @include mq(mobile) {
                        min-height: 76px;
                    }

                    ::-webkit-scrollbar {
                        width: 5px;
                    }

                    ::-webkit-scrollbar-track {
                        background: lightgrey;
                    }

                    ::-webkit-scrollbar-thumb {
                        background: #d30000;
                    }

                    .returnContent {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;

                        a {
                            font-size: 18px;
                            color: black;
                            text-decoration: underline;
                            &:hover {
                                color: #535353;
                            }
                        }

                        .backIcon {
                            margin-right: 4px;
                            display: flex;
                            align-items: center;
                        }
                    }

                    .accountCardBody {
                        position: relative;
                        padding: 30px;
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        justify-content: space-between;
                        background-size: cover;
                        background-position: right center;
                        background-repeat: no-repeat;
                        @include mq(mobile) {
                            background-position: right -135px center;
                        }

                        .titleContent {
                            flex: 1;
                            width: 65%;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            align-items: flex-start;
                            z-index: 1;

                            .h2 {
                                text-shadow: 0 0 5px black;
                                font-size: 36px;

                                @include mq(mobile) {
                                    font-size: 24px;
                                }

                            }
                        }

                        .crossLinkContent {

                            .accountQualify {

                                .accountQualifyTitle {
                                    font-weight: bold;
                                    margin-bottom: 16px;
                                }

                                .accountQualifyDesc {
                                    margin-bottom: 16px;
                                    a {
                                        color: #B30000;
                                        text-decoration: underline;

                                        &:hover {
                                            color: black;
                                        }
                                    }
                                }

                                .mergingIrreversible, .linkingIrreversible {
                                    color: #B30000;
                                    font-weight: bold;
                                    margin-bottom: 16px;
                                }

                                .accountQualified-container {
                                    background-color: white;
                                    padding: 24px;
                                    margin-bottom: 20px;

                                    .merge-container, .link-container {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;

                                            .yesIcon-container, .noIcon-container {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                height: 33px;
                                                width: 33px;
                                                margin-right: 24px;

                                                .yesIcon, .noIcon {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    border-radius: 50%;
                                                    height: 33px;
                                                    width: 33px;

                                                    .checkmark, .x {
                                                        filter: invert(1);
                                                    }

                                                }

                                                .yesIcon {
                                                    background-color: #159F2B;
                                                }

                                                .noIcon {
                                                    background-color: #E60012;
                                                }
                                            }

                                        .merging, .linking {
                                            .mergingTitle, .linkingTitle {
                                                font-weight: bold;
                                                text-transform: capitalize;
                                                margin-bottom: 8px;
                                            }
                                            .mergeDQWarning {
                                                margin-top: 8px;
                                                color: #B30000;
                                            }
                                        }
                                    }
                                }
                            }

                            .howItWorks {
                                .howItWorksTitle {
                                    font-weight: bold;
                                    margin-bottom: 15px;
                                }

                                ul {
                                    margin-bottom: 20px;

                                    li {
                                        list-style-type: square;
                                        //margin-bottom: 8px;
                                    }
                                }

                            }

                            .primaryAccounts {
                                margin-bottom: 16px;

                                .primaryAccountsTitle {
                                    font-weight: bold;
                                    margin-bottom: 8px;
                                }

                                .primaryAccountsDescription {
                                    margin-bottom: 8px;
                                    a {
                                        text-decoration: underline;
                                        &:hover {
                                            color: black;
                                        }
                                    }
                                }

                                .primaryAccountsNote {
                                    color: #B30000;
                                    font-weight: bold;
                                    margin-bottom: 8px;

                                    a {
                                        text-decoration: underline;
                                        color: black;
                                        &:hover {
                                            color: #B30000;
                                        }
                                    }
                                }

                                .primaryAccountsContent {
                                    margin-bottom: 8px;
                                }

                                .important-message {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 15px;
                                    padding: 10px;
                                    //background-color: rgba(249, 169, 0, 0.15);
                                    //background-color: rgba(204, 142, 64, 0.15);
                                    //border: 2px solid #cc8e40;

                                    .warningIcon-container {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        height: 33px;
                                        width: 33px;
                                        margin-right: 10px;

                                        .warningIcon {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border-radius: 50%;
                                            height: 33px;
                                            width: 33px;
                                            background-color: #940000;
                                            padding-bottom: 3px;
                                            //background-color: #f9a900;

                                            .warning {
                                                //filter: brightness(0) saturate(100%) invert(50%) sepia(83%) saturate(313%) hue-rotate(353deg) brightness(100%) contrast(100%);
                                            }
                                        }
                                    }
                                }

                                .mergingIrreversible, .linkingIrreversible {
                                    color: #B30000;
                                    font-weight: bold;


                                }

                            }

                            .account-selection {

                                .accountSelect, .accountSelected {
                                    font-weight: bold;
                                    margin-bottom: 15px;
                                }


                                /*
                                .accountSelectDesc, .accountSelectedDesc { //will prob remove this
                                    a {
                                        color: black;
                                        text-decoration: underline;

                                        &:hover {
                                            opacity: 80%;
                                            color: black;
                                        }

                                    }
                                }

                                 */

                                input[type="radio"] {
                                    display: none;
                                }



                                .account-options {
                                    margin-bottom: 20px;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: flex-end;
                                    justify-content: center;

                                    @include mq(mobile) {
                                        flex-direction: column;
                                    }

                                    label {
                                        margin: 5px;
                                        width: 100%;
                                        height: 100%;

                                        &.selected {
                                            border: 2px solid #2D611F;
                                            .account {
                                                background-color: #D1E8CD;
                                                //min-height: 330px;
                                                //@include breakpoint-tablet-only {
                                                //    min-height: 384px;
                                                //}
                                                &:hover {
                                                    background-color: #E5F2E3;
                                                }
                                            }
                                        }

                                        &.unselected {
                                            border: 2px solid #B30000;
                                            .account {
                                                background-color: #E8CDCD;
                                                //min-height: 330px;
                                                //@include breakpoint-tablet-only {
                                                //    min-height: 384px;
                                                //}
                                                &:hover {
                                                    background-color: #F1E0E0;
                                                }
                                            }
                                        }

                                        .primaryAccount {
                                            color: white;
                                            background-color: #2D611F;
                                            padding: 15px;
                                            font-weight: bold;
                                            font-size: 14px;
                                        }

                                        .account {
                                            background-color: #D9D9D9;
                                            padding: 20px;
                                            min-height: 180px;
                                            cursor: pointer;

                                            &:hover {
                                                background-color: #cccccc;
                                            }
                                            &-content {
                                                display: flex;
                                                align-items: flex-start;

                                                .pc-container, .psn-container, .xbox-container,
                                                .switch-container, .ios-container {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    margin-right: 15px;
                                                    min-height: 44px;
                                                    min-width: 44px;
                                                    //border-radius: 4px;
                                                    //margin-bottom: 8px;

                                                    img {
                                                        max-height: 27px;
                                                    }
                                                }

                                                .pc-container {
                                                    background-color: $COLOR_PC_ICON;
                                                }

                                                .psn-container {
                                                    background-color: $COLOR_PSN_ICON;
                                                }

                                                .xbox-container {
                                                    background-color: $COLOR_XBOX_ICON;
                                                }

                                                .switch-container {
                                                    background-color: $COLOR_NSW_ICON;
                                                }

                                                .ios-container {
                                                    background-color: $COLOR_IOS_ICON;
                                                }

                                                /*
                                                img {
                                                    margin-right: 15px;
                                                    height: 44px;
                                                    width: 44px;
                                                }
                                                 */

                                                .account-info {
                                                    padding-top: 10px;

                                                    .account-title {
                                                        font-weight: bold;
                                                        margin-bottom: 15px;
                                                    }
                                                }
                                            }

                                            .dataRemain, .dataOverwritten {
                                                &-message {
                                                    display: flex;
                                                    align-items: center;
                                                    margin-top: 24px;

                                                    .errorIcon-container {
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        height: 33px;
                                                        width: 33px;
                                                        margin-right: 8px;

                                                        .errorIcon {
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;

                                                            .error {
                                                                width: 22px;
                                                                height: 19px;
                                                                filter: brightness(0) saturate(100%);
                                                            }
                                                        }
                                                    }

                                                    p {
                                                        font-weight: bold;
                                                    }
                                                }

                                                &-items, &-clan, &-alliance {
                                                    margin-top: 8px;
                                                }
                                            }
                                        }
                                    }
                                }



                                .combineAccounts {
                                    border-top: 2px solid darkgrey;

                                    .combine-content {
                                        margin-top: 30px;
                                        margin-bottom: 20px;

                                        .linkTitle, .mergeTitle {
                                            font-size: 18px;
                                            font-weight: bold;
                                            margin-bottom: 10px;
                                        }

                                        .linkDesc, .mergeDesc {
                                            margin-bottom: 4px;
                                            span {
                                                font-weight: bold;
                                                color: #B30000;
                                            }
                                        }

                                        .warning {
                                            &-message {
                                                display: flex;
                                                align-items: center;
                                                margin-top: 24px;

                                                .errorIcon-container {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    height: 33px;
                                                    width: 33px;
                                                    margin-right: 8px;

                                                    .errorIcon {
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;

                                                        .error {
                                                            width: 22px;
                                                            height: 19px;
                                                            filter: brightness(0) saturate(100%);
                                                        }
                                                    }
                                                }

                                                p {
                                                    b {
                                                        color: #B30000;
                                                        font-weight: bold;
                                                        text-transform: uppercase;
                                                    }
                                                }
                                            }
                                        }

                                        .checkbox-container {
                                            margin-top: 24px;
                                            margin-bottom: 24px;
                                            padding: 24px;
                                            //background-color: rgba(179, 0, 0, 0.15);
                                            //border: 2px solid #B30000;
                                            background-color: white;

                                            .checkbox-container-inner {
                                                display: flex;
                                                align-items: center;
                                            }

                                            #warningCheckboxHolder {
                                                margin-top: 18px;
                                                &.hidden {
                                                    display: none;
                                                }
                                            }

                                            input[type="checkbox"] {
                                                margin-right: 16px;
                                                min-width: 18px;
                                                min-height: 18px;
                                                cursor: pointer;
                                                border: 1px solid black;
                                                border-radius: unset;
                                                margin-bottom: 0;
                                            }

                                            .termsDesc {
                                                font-weight: bold;
                                                font-size: 16px;
                                            }

                                        }
                                        #crossSaveButton:disabled {
                                            background-color: #CCCCCC;
                                            color: black;
                                            pointer-events: none;
                                        }

                                        #twoFA-holder {
                                            margin-bottom: 8px;

                                            .hidden {
                                                display: none;
                                            }
                                            #sendVerification {

                                            }

                                            #verificationInput {

                                            }
                                        }
                                    }
                                }

                                &.forcedMain {
                                    label, input[type="radio"] {
                                        pointer-events: none;

                                        &:hover {
                                            background-color: unset !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &#title {
                    #titleCard {
                        .accountCardBody {
                            background-color: rgb(0, 0, 0);

                            @include mq(mobile) {
                                padding: 10px;
                            }
                        }

                        .cardBackgroundImage {
                            width: auto;
                            max-width: 100%;
                            height: 100%;
                            object-fit: cover;
                            top: 0;
                            right: 0;
                            position: absolute;
                        }
                    }
                }

                &#crossLink {
                    #crossLinkCard {
                        background-color: #F1F1F1;
                    }
                }
            }
        }
    }

    .ConfirmationModal, .Confirmation2FAModal {
        display: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.85);
        z-index: 300;
        overflow-y: auto;

        &.is-active {
            display: block;
        }

        &-inner {
            position: relative;
            width: 90%;
            max-width: 800px;
            margin: 20vh auto 10vh;
            background-color: #f1f1f1;
        }
        &-closer {
            position: absolute;
            display: inline-block;
            top: 0;
            right: 0;
            padding: $SPACE_SMALL;
            cursor: pointer;
            .Icon {
                width: $SPACE_LARGE;
                height: $SPACE_LARGE;
            }
        }
        &-content {
            padding: $SPACE_LARGER $SPACE_LARGE;
        }
        &-title {
            @include TYPOGRAPHY_H3;
        }
        &-description {
            margin: 1em 0;
        }
        &-primarySubtitle,
        &-lostSubtitle {
            margin: $SPACE_LARGE 0 $SPACE_SMALL;
            @include TYPOGRAPHY_H4;
        }
        &-platform {
            display: none;
            position: relative;
            width: 48px;
            height: 48px;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 32px;
                transform: translate(-50%, -50%);
            }
            &[data-platform="pc"] {
                background-color: $COLOR_PLATFORM_PC;
            }
            &[data-platform="xbox"] {
                background-color: $COLOR_PLATFORM_XBOX;
            }
            &[data-platform="psn"] {
                background-color: $COLOR_PLATFORM_PLAYSTATION;
            }
            &[data-platform="nsw"] {
                background-color: $COLOR_PLATFORM_NINTENDO;
            }
            &[data-platform="ios"] {
                background-color: $COLOR_PLATFORM_IOS;
            }
        }
        &-selectedOption {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            gap: $SPACE_MEDIUM;
            padding: $SPACE_MEDIUM;
            background-color: #d9d9d9;
            font-size: $FONT_SIZE_PARAGRAPH_LARGE;
            font-weight: bold;

            &[data-platform="pc"] {
                .ConfirmationModal-platform[data-platform="pc"] {
                    display: inline-block;
                }
            }
            &[data-platform="xbox"] {
                .ConfirmationModal-platform[data-platform="xbox"] {
                    display: inline-block;
                }
            }
            &[data-platform="psn"] {
                .ConfirmationModal-platform[data-platform="psn"] {
                    display: inline-block;
                }
            }
            &[data-platform="nsw"] {
                .ConfirmationModal-platform[data-platform="nsw"] {
                    display: inline-block;
                }
            }
            &[data-platform="ios"] {
                .ConfirmationModal-platform[data-platform="ios"] {
                    display: inline-block;
                }
            }
        }
        &-warning {
            display: inline-grid;
            grid-template-columns: auto 1fr;
            gap: $SPACE_SMALLER;
            align-items: center;
            font-weight: bold;
            text-transform: uppercase;
            color: $COLOR_ACCENT;

            .Icon {
                filter: invert(10%) sepia(81%) saturate(6358%) hue-rotate(17deg) brightness(91%) contrast(124%);
            }
        }
        &-confirmations {
            margin-top: $SPACE_LARGE;
            background-color: $COLOR_BACKGROUND;
            padding: $SPACE_MEDIUM;
        }
        &-confirmation {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: $SPACE_SMALL;
            margin-top: $SPACE_MEDIUM;
            font-size: $FONT_SIZE_PARAGRAPH_LARGE;
            font-weight: bold;

            input {
                margin: 0;
            }
        }
        &-buttons {
            margin-top: $SPACE_LARGE;
        }
        .resendEmailVerification {
            cursor: pointer;
        }

    }
}
