.login-hide {
    display: none !important;
}

.containWrap{
    background-color: #FFFFFF;
    position: fixed;
    z-index: 200;
    width: 100%;
    top: 0;
    height: $mainHeaderHeight;
}

.fullWidthNav{
    min-width: 875px;
}

.header {
    height: $mainHeaderHeight;
    line-height: 1.2;

    .activeLanguage {
        &:active {
            pointer-events: none;
        }
    }

    .navHeader::after {
        background: url('/images/mainSiteAssets/nav/dropDownIcon.png') no-repeat;
        height: 17px;
        width: 17px;
        border: none;
        top: 9px;
        right: 9px;
    }

    .logoMenu {

        .mainLogo, .ul.nav-links {
            float: left;
        }

        .mainLogo {
            margin: 10px 0 0 20px;

            img{
                height: 28px;
            }
        }

        ul.nav-links {
            font-size: 13px;
            font-weight: bold;
            z-index: 3;
            margin: 7px 0 0 20px;

            li {
                background: none;

                a {
                    @include futureFont();
                    text-transform: uppercase;
                    color: black;
                    border: none;
                    font-size: 12px;

                    #avatarImage {
                        width: 35px;
                        border: #836f07 solid 1px;
                        background-color: #1a1a1c;
                        margin-right: 5px;
                    }

                    &.anniversary {
                        color: #4C4CAE;
                        &:hover {
                            color: #383883;
                        }
                    }
                    &.aozHeader {
                        color: #0B514D;
                        &:hover {
                            color: #0B605C;
                        }
                    }

                }
                ul{
                    border-top: 3px solid $pcRed;
                    box-shadow: none;
                    background-color: rgb(242,242,242);
                    background-color: rgba(242,242,242,0.9);
                }

                ul li {
                    font-weight: normal;
                    border-top: 1px solid #CFD0D0;

                    a {
                        //font-family: $openSansFont;
                        //word-spacing: 2px;
                    }
                }
                ul li:hover {
                    background-color: #f5f9f8;
                }
            }
        }

        .menuRight{
            float:right;
            height: $mainHeaderHeight;

            .languages, .loginMenu, .headerSignup{
                display: inline-block;
            }

            .languages select{
                height: 99%;
                border: none;
                margin-bottom: 0px;
            }

            .loginMenu,.headerSignup{
                display: inline-block;
                text-align: center;
                padding: 1px 35px 9px 25px;
                //border-left: 1px solid white;
                font-size: 13px;
                margin: 0;
                position: relative;

                .headerLoginText{
                    @include futureFont;
                    display: flex;
                    color: black;
                    text-transform: uppercase;
                    font-weight: bold;
                    position: relative;
                    padding-top: 3px;
                    .nameHolder {
                        position: relative;
                        top: 11px;
                        max-width: 150px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    &.signed_in {
                        padding-top: 4px;
                    }
                }
            }

            ul.loginMenu{

                .sf-with-ul{
                    position: relative;
                    //top: 11px;
                }

                a{
                    padding: 0;

                    &::after{
                        display: none;
                    }
                }

                ul {
                    top: 38px;

                    li a{
                        padding: .75em 1em;
                    }
                }

                .headerLoginText::after{
                    content:'';
                    background: url('/images/mainSiteAssets/nav/dropRightIcon.png') no-repeat;
                    height: 17px;
                    width: 17px;
                    border: none;
                    top: 1px;
                    right: -27px;
                    position: absolute;
                    display: block;
                }

                .headerLoginText.signed_in::after{
                    background: url('/images/mainSiteAssets/nav/dropDownIcon.png') no-repeat;
                    top: 12px;
                }

                .headerUser{
                    display: none;
                }
            }


            .headerSignup{
                background-color: $pcRed;
                padding: 12px 15px;
                height: $mainHeaderHeight;

                .headerLoginText{
                    color: white;
                }

                .headerDownloadImg{
                    display: none;
                }
            }
        }
    }
}

.lang-de, .lang-fr, .lang-pt-br, .lang-tr, .lang-pl, .lang-it, .lang-es{
    @media (max-width: 1450px) and (min-width: 1250px) {
        .header{
            .navHeader{
                font-size: 10px !important;
                padding-right: 30px;
                top: 1px;
            }

            .navHeader::after{
                top:7px;
            }
            .headerLoginText{
                font-size: 10px !important;
            }
            .headerSignup{
                font-size: 10px !important;
            }
        }
    }
}




.storeBar{
    background-color: #000000;
    width: 100%;
    text-transform: uppercase;
    display: block;
    text-align: center;
    height: 45px;

    a{
        color: #a1a1a1;
        float: left;
        width: 33.33%;
        box-sizing: border-box;
        padding-top: 5px;
        font-size: 16px;

        @media (max-width: 1024px) {
            font-size: 12px;
        }

        img{
            margin-bottom: 5px;
            display: inline-block;
        }

        .text {
            //@include futureFont;
            text-transform: uppercase;
            display: inline-block;
            position: relative;
            top: -15px;
        }

        &.goldText{
            color: #c99037;
        }

        .info{
            text-align: center;
            display: inline-grid;
            margin-left: 7px;
            margin-top: 0;
            width: auto;
        }

        &.coupon {
            .text{
                top: -22px;
            }
            .couponInfo{
                //font-family: $openSansFont;
                display: block;
                //word-spacing: 0;
                font-size: 10px;
                margin-top: -18px;
                color:#ffffff;

                span{
                    background-color: $pcRed;
                    padding:0 2px;
                }
            }
        }
    }

    @include mq(tablet) {
        .container{
            width: 100%;
        }

        .link {
            min-width: 184px;

            .text{
                font-size: 12px;
            }
        }
    }
    @include mq(mobile) {
        display: none;
    }
}

.noVault{
    .storeBarLink{
        width: 50%;
    }
    .vaultLink{
        display: none;
    }
}

.vaultLink {
    a{
        margin-top: 9px;
    }
    .vaultTimer {
        position: absolute;
        bottom: 11px;
        color: white;
        left: 46%;
        text-transform: uppercase;
        font-size: 10px;
        //font-family: "Open Sans", sans-serif;
    }
}

.mobileHeader{
    display: none;
    min-height: 80px;
    line-height: 1.0;

    .tealBar{
        height: 35px;
        //background-color: #14445b;
        background-color: black;
        position: relative;
        z-index: 2000;

        .hamburgerMenu{
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
            top: 5px;
            left: 10px;

            .patty{
                height: 3px;
                width: 100%;
                //background-color: #5d9dc1;
                background-color: white;
                margin-top: 4px;
                position: relative;
            }
        }

        .loginSignup{
            display: block;
            margin-right: 10px;
            padding-top: 10px;
            float: right;
            text-transform: uppercase;
            a{
                //color:#5d9dc1;
                color: white;
                font-size: 11px;
            }
        }

        .languages{
            float: left;
            margin-left: 60px;

            select{
                margin-left: 0px;
                height: 28px;
                font-size: 10px;
                margin-top: 4px;
            }
        }
    }
    .mobileLogo{
        text-align: center;
        background-color: #FFFFFF;
        width: 100%;
        position: fixed;
        z-index: 200;
        height: 90px;
        padding-top: 10px;

        @media (max-width: 415px) {
            height: 72px;
        }

        img{
            margin: 0 auto;
            max-width: 90%;
        }
    }

    .mobileNav{
        position: fixed;
        top: -120%;
        width: 100%;
        height: 100%;
        padding: 15px;
        z-index: 1000;

        @include futureFont;
        text-transform: uppercase;
        background: black;
        color: #FFFFFF;

        @include mq(mobilelandscape) {
            top: -150%;
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
            margin-top: 8px;
        }

        @media only screen and (max-width: 823px) and  (min-width: 820px){
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
            margin-top: 7px;

        }

        img{
            margin: 30px 0;
        }

        p{
            margin-top: 10px;
        }
        a{
            color: #FFFFFF;
        }
        ul{
            li{
                border-top: 1px solid rgba(255,255,255,0.2);
                padding: 17px 0;
                text-align: center;
                ul{
                    display: none;
                }
            }
        }
    }
}

//@include mq(tablet) {
@media (max-width: 1024px) {
    .fullWidthNav{
        display: none;
    }
    .mobileHeader{
        display: block;
        .mobileLogo{
            border-bottom: 1px solid #E1E1E1;
        }
    }

    .containWrap{
        position: relative;
        background-color: rgba(255, 255, 255, 0);
        .tealBar{
            position: fixed;
            top: 0;
        }
    }

    .languages {
        .navHeader {
            //color: #5d9dc1;
            //background: #14445b;
            color: white;
            background: black;
            padding-top: 0.6em;
            padding-bottom: 0.6em;
        }

        li, li > a {
            //color: #5d9dc1;
            color: white;
            border: none;
            background: black;
        }
    }
}

//@include mq(ib) {
@media (min-width: 875px) and (max-width: 1275px) {
    .header{
        .logoMenu{
            ul.nav-links {
                margin: 11px 0 0 11px;

                li a {
                    font-size: 10px;
                }
            }

            .menuRight {

                ul.loginMenu ul{
                    left: -87px;
                }

                ul.languages ul{
                    min-width: inherit;
                }

                .headerSignup{
                    font-size: 10px;
                    padding-top: 10px;

                    .headerLoginText{
                        display: none;
                    }

                    .headerDownloadImg{
                        display: block;
                        background: url('/images/mainSiteAssets/nav/downloadIcon.png') no-repeat;
                        width: 28px;
                        height: 28px;
                    }
                }

                .loginMenu {
                    padding-left: 15px;
                    padding-right: 15px;

                    .sf-with-ul{
                        //top: 11px !important;
                    }
                    &.signed_in{
                        padding-right: 20px;

                        .sf-with-ul{
                            top: 0 !important;
                        }

                        &::after{
                            content: '';
                            background: url('/images/mainSiteAssets/nav/dropDownIcon.png') no-repeat;
                            height: 11px;
                            width: 11px;
                            border: none;
                            top: 11px;
                            right: 5px;
                            position: absolute;
                            display: block;
                            background-size: 100% 100%;
                        }
                    }

                    .headerLoginText.signed_in{
                        max-width: 135px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &::after{
                            display: none;
                        }
                    }

                    a{
                        top: 0 !important;
                    }

                    .headerLoginText{
                        display: none;
                    }

                    .headerUser{
                        display: block !important;
                        position: relative;
                        //top: 3px;
                        top: 7px;
                        width: 38px;
                        height: 38px;
                        //background-color: #1a1a1c;
                        background-repeat: no-repeat;
                        //border: #836f07 solid 1px;

                        &.inactive{
                            background-image: url('/images/mainSiteAssets/nav/userInactive.png');
                        }

                        &.active{
                            background-image: url('/images/mainSiteAssets/nav/userActive.png');
                            //background-image: url('/images/default-avatar.png');
                            width: 30px;
                            background-size: contain;
                        }
                    }
                }
            }
        }

        .navHeader::after,.header .logoMenu .menuRight ul.loginMenu .headerLoginText::after{
            height: 11px;
            width: 11px;
            background-size: 100% 100%;
        }
    }
}


@media (max-width: 823px) {
    #clearlogin{
        display: none;
    }
    .mobileNav{
        //top: -170% !important;
    }
}


@media (max-width: 769px) {
    .loginSignup {
        width: 204px !important;
        position: absolute;
        right: 0px;
        margin-right: 8px !important;
        text-align: right;
    }
}

@media (max-width: 428px) {
    .loginSignup {
        width: 124px;
        margin-top: -7px;
        color: #000000;
        position: absolute;
        right: 0px;
        margin-right: 8px !important;
        text-align: right;
        span {
            display: none;
        }
        #clearlogin{
            clear: both;
            display: block;
        }

    }
    .tealBar, .mobileLogo{
        max-width: 428px !important;
    }
    .mobileHeader{
        min-height: 73px;

        .mobileNav{
            //width: 90%;
        }
    }

}

@media (max-width: 361px) {
    .tealBar, .mobileLogo{
        max-width: 360px !important;
    }
}

@media (max-width: 280px) {
    .tealBar, .mobileLogo {
        max-width: 280px !important;
    }
}
