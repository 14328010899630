//@import "compass/css3/images";
@import "../style-guide/_variables";
@import "../style-guide/_responsive";
@import "../style-guide/_mixins";
@import "../style-guide/_typography";

$COLOR_PC_ICON: #272727;
$COLOR_PSN_ICON: #003791;
$COLOR_XBOX_ICON: #0E7A0D;
$COLOR_NSW_ICON: #E60012;
$COLOR_IOS_ICON: #000000;

.h3-lowercase {
    font-size: 1.75rem;
    font-weight: bold;
}

body.user, body.user-changeAlias {
    background: white;

    .block {
        background-color: transparent;
        border: none;
    }
}



body.changeemail, body.changeemail_confirmed {

    #mainContent {
        padding-top: 2em;
    }

    input {
        background-color: #d8d9db;
        display: block;
        border: 1px solid #aeb1b2;
        //@include border-radius(0px);
        border-radius: 0px;
        padding: 0px 0 0 20px;
        height: 50px;
        font-size: 17px;
        width: 100%;
    }

    p {
        margin-bottom: 1em;

        strong {
            font-weight: bold;
        }
    }
}

#loginPage {
    &.robotCheck {
        p {
            text-align: center;
            font-weight: bold;
        }
    }

    .consoleLoginBtns {

        text-align: center;

        a {
            max-width: 220px;
            display: inline-block;

            button {
                padding: 0 30px;
            }

            &.ps4Btn button {
                background: $ps4Blue;
            }

            &.xb1Btn button {
                background: $xb1Green;
            }
        }
    }

    form {
        input[type="text"], input[type="email"], input[type="password"] {
            border: 1px solid #b6b9bb;
        }

        .recaptcha-check {
            width: 304px;
            margin: 30px auto;
        }
    }
}

body.user_view {

    .Button--primeGaming {
        color: white;
        background-color: rgb(100, 65, 165);

        &:hover {
            background-color: rgb(112, 75, 182);
        }
    }

    .Button--primeGamingWhite {
        color: black;
        background-color: white;

        &:hover {
            color: white;
            background-color: rgb(112, 75, 182);
        }
    }

    #mobileUserMenu {
        display: none;
    }

    #account-manage {
        padding-top: 32px;

        .accountHeader h1 {
            text-transform: uppercase;
            font-size: 31px;
            letter-spacing: normal;

            @include mq(mobile) {
                font-size: 27px;
            }
        }

        .accountOptions {
            border-top: 1px solid #d1d2d1;
            margin-top: 45px;
            margin-bottom: 20px;

            #accountOptionContain {
                padding-top: 20px;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(410px, 1fr));

                .optionContain {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    input[type="password"], input[type="text"], input[type="email"] {
                        background-color: #d8d9db;
                        display: block;
                        border: 1px solid #aeb1b2;
                        border-radius: 0;
                        padding: 0 0 0 20px;
                        height: 50px;
                        font-size: 17px;
                        width: 100%;
                    }

                    .platCost {
                        font-weight: bold;
                        text-transform: uppercase;
                        border-bottom: #a6a7a9;
                        padding-bottom: 15px;
                    }

                    p {
                        margin-bottom: 15px;

                        &.gray {
                            color: #949594;
                        }
                    }

                    .accountCard {
                        width: 100%;
                        margin: 15px;
                        background: #fff;
                        max-width: 530px;
                        min-height: 315px;
                        display: flex;
                        flex-direction: column;

                        &.comingSoon {
                            min-height: 282px;
                        }

                        ::-webkit-scrollbar {
                            width: 5px;
                        }

                        ::-webkit-scrollbar-track {
                            background: lightgrey;
                        }

                        ::-webkit-scrollbar-thumb {
                            background: #d30000;
                        }

                        #accountCardHeader {
                            //background: #CCCCCC;
                            text-align: left;
                            padding: 15px 30px;
                            text-transform: uppercase;
                            position: relative;

                            h1 {
                                word-wrap: break-word;
                                font-size: 20px;
                                color: black;
                                letter-spacing: normal;
                            }

                            .iconLarge {
                                position: absolute;
                                left: 5px;
                                width: 30px;
                                image-rendering: crisp-edges;
                            }
                        }
                        
                        .accountCardBodyTop{
                            position: relative;
                            padding: 0 30px;
                            padding-top: 15px;
                            display: flex;
                            flex: 1;
                            flex-direction: column;
                            justify-content: space-between;
                            background-color: #f1f1f1;
                            background-size: cover;
                            background-position: right center;
                            background-repeat: no-repeat;
                        }
                        .accountCardBodyMid{
                            position: relative;
                            padding: 0 30px;
                            display: flex;
                            flex: 1;
                            flex-direction: column;
                            justify-content: space-between;
                            background-color: #f1f1f1;
                            background-size: cover;
                            background-position: right center;
                            background-repeat: no-repeat;
                        }
                        .accountCardBodyBot{
                            position: relative;
                            padding: 0 30px;
                            padding-bottom: 15px;
                            display: flex;
                            flex: 1;
                            flex-direction: column;
                            justify-content: space-between;
                            background-color: #f1f1f1;
                            background-size: cover;
                            background-position: right center;
                            background-repeat: no-repeat;
                        }
                        .dark-background{
                            background-color: #d9d9d9;
                            
                            .highlight-section{
                                border-top: 16px solid #FFF;
                                position: relative;
                                padding: 5px 30px;
                                display: flex;
                                flex: 1;
                                flex-direction: column;
                                justify-content: space-between;
                                background-size: cover;
                                background-position: right center;
                                background-repeat: no-repeat;

                                @include mq(mobile){
                                    padding: 5px 15px;
                                }
                            }
                        }

                        .accountCardBody {
                            position: relative;
                            padding: 30px;
                            display: flex;
                            flex: 1;
                            flex-direction: column;
                            justify-content: space-between;
                            background-color: #f1f1f1;
                            background-size: cover;
                            background-position: right center;
                            background-repeat: no-repeat;
                            @include mq(mobile) {
                                background-position: right -135px center;
                            }

                            &.centered {
                                background-position: center;
                            }

                            .logo {
                                position: absolute;
                                width: 90px;
                                bottom: 20px;
                                right: 20px;
                                @include mq(mobile) {
                                    position: relative;
                                    bottom: unset;
                                    right: unset;
                                    margin-top: 15px;
                                    width: 110px;
                                }
                            }

                            .crossSaveContent {
                                padding: 30px;
                                flex: 1;
                                width: 65%;
                                //display: flex;
                                //flex-direction: column;
                                //justify-content: space-between;
                                //align-items: flex-start;
                                z-index: 1;
                                @include mq(tablet) {
                                    width: 100%;
                                    padding: 25px;
                                }

                                @include mq(mobile) {
                                    padding: 20px;
                                }
                                &.comingSoon {
                                    padding: 38px 40px;
                                    width: 55%; //might change

                                    @include mq(tablet) {
                                        width: 100%;
                                        padding: 16px 24px 24px 24px;
                                    }

                                    @include mq(mobile) {
                                        padding: 16px;
                                    }
                                }

                                h2 {
                                    font-size: 36px;
                                }

                                .comingSoon {
                                    color: #CCCCCC;
                                    font-weight: bold;
                                    font-size: 28px;
                                    text-transform: uppercase;
                                    margin-bottom: 8px;
                                }

                                p {
                                    span {
                                        font-weight: bold;
                                    }
                                }

                                .comingSoonDesc {
                                    margin-bottom: 24px;
                                    color: white;
                                    font-size: 18px;
                                }

                                .platformIcons {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    margin-top: 8px;

                                    .pc-container, .psn-container,
                                    .xbox-container, .switch-container, .ios-container {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        margin-right: 8px;
                                        min-height: 27px;
                                        min-width: 27px;
                                        border-radius: 4px;
                                        //margin-bottom: 8px;

                                        .platformImage {
                                            max-height: 18px;
                                            max-width: 18px;
                                        }

                                    }

                                    .pc-container {
                                        background-color: $COLOR_PC_ICON;
                                    }
                                    .psn-container {
                                        background-color: $COLOR_PSN_ICON;
                                        .platformImage {
                                            filter: invert(1);
                                        }
                                    }
                                    .xbox-container {
                                        background-color: $COLOR_XBOX_ICON;
                                    }
                                    .switch-container {
                                        background-color: $COLOR_NSW_ICON;
                                    }
                                    .ios-container {
                                        background-color: $COLOR_IOS_ICON;
                                    }
                                }

                                .h2 {
                                    text-shadow: 0 0 5px black;
                                    margin-bottom: 8px;
                                }

                                .PCAccount-options {
                                    border: 1px solid #484848;
                                    padding: 16px;
                                    width: 80%;
                                    margin: 24px 0 16px 0;


                                    @include mq(tablet) {
                                        width: 100%;
                                    }

                                    .PCAccount-answer {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;

                                        @include mq(mobile) {
                                            display: block;
                                        }

                                        &.yes {
                                            margin-bottom: 16px;
                                        }

                                        .yesPCAccount, .noPCAccount {
                                            display: flex;
                                            align-items: center;
                                            margin-right: 15px;


                                            .yesIcon-container, .noIcon-container {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                height: 33px;
                                                width: 33px;
                                                margin-right: 10px;

                                                .yesIcon, .noIcon {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    border-radius: 50%;
                                                    height: 33px;
                                                    width: 33px;

                                                    .checkmark {
                                                        filter: invert(1);
                                                    }

                                                    .x {
                                                        filter: invert(1);
                                                    }
                                                }

                                                .yesIcon {
                                                    background-color: #159F2B;
                                                }

                                                .noIcon {
                                                    background-color: #E60012;
                                                }
                                            }

                                            .yesPCAccountDesc, .noPCAccountDesc {
                                                color: white;

                                                b {
                                                    font-weight: bold;
                                                    text-transform: capitalize;
                                                    font-size: 18px;
                                                }
                                            }
                                        }
                                    }
                                }

                                .highTraffic {
                                    display: flex;
                                    align-items: center;
                                    margin: 24px 0;
                                    padding: 10px;
                                    width: 75%;
                                    background-color: rgba(119, 31, 22, 0.2);
                                    border: 1px solid rgba(255, 97, 97, 0.44);

                                    @include mq(tablet) {
                                        width: 100%;
                                    }

                                    .errorIcon-container {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        height: 33px;
                                        width: 33px;
                                        margin-right: 10px;

                                        .errorIcon {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border-radius: 50%;
                                            height: 33px;
                                            width: 33px;
                                            padding-bottom: 3px;
                                            //background-color: #f9a900;

                                            .error {
                                                filter: brightness(0) saturate(100%) invert(45%) sepia(97%) saturate(1939%) hue-rotate(327deg) brightness(111%) contrast(101%);
                                            }
                                        }
                                    }

                                    p {
                                        color: #FF6161;
                                    }
                                }

                                .Button {
                                    margin-bottom: 16px;
                                }

                                /*
                                .button-options {
                                    display: flex;
                                    align-items: center;
                                    margin: 24px 0 16px 0;
                                    //padding: 1px 0;
                                    @include mq(mobile) {
                                        display: block;
                                        width: 100%
                                    }

                                    .login {
                                        padding: 3px 32px 3px 0;
                                        border-right: solid #cccccc 0.25px;
                                        margin: 0;

                                        @include mq(mobile) {
                                            margin-bottom: 8px;
                                            padding: 0;
                                            border-right: unset;
                                        }
                                    }

                                    .signup {
                                        padding: 3px 0 3px 32px;
                                        border-left: solid #cccccc 0.25px;

                                        @include mq(mobile) {
                                            margin-bottom: 16px;
                                            padding: 0;
                                            border-left: unset;
                                        }
                                    }

                                    a {
                                        .Button {
                                            width: 100%;
                                            margin: 0;

                                            @include mq(mobile) {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }
                                 */

                                /*
                                {.button-columns {
                                    display: grid;
                                    grid-template-columns: 50% 50%;
                                    justify-content: space-between;
                                    width: 100%;
                                    padding-top: 20px;
                                    padding-bottom: 20px;

                                    @include mq(mobile) {
                                        display: block;
                                    }

                                    .accountLink {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 15px;

                                        a {
                                            &-button {
                                                white-space: nowrap;
                                            }
                                        }

                                        img {
                                            height: 44px;
                                            width: 44px;
                                            margin-right: 15px;
                                        }
                                    }

                                }
                               */

                                .connectedAccounts {
                                    margin-top: 24px;
                                    background: linear-gradient(10deg, rgba(217, 217, 217, 0) 0%, rgba(217, 217, 217, 0.15) 50%, rgba(217, 217, 217, 0) 100%), linear-gradient(301deg, rgba(53.03, 101.37, 128.56, 0) 0%, rgba(53.03, 101.37, 128.56, 0.10) 30%, rgba(53.03, 101.37, 128.56, 0) 100%);
                                    border: 2px solid transparent;
                                    border-image: linear-gradient(to bottom right, #555, #ccc, #555, #ccc, #555);
                                    border-image-slice: 1;
                                    padding: 18px 24px;
                                    width: 75%;

                                    @include mq(tablet) {
                                        width: 100%;
                                    }

                                    .connectedAccountsTitle {
                                        text-transform: uppercase;
                                        color: white;
                                        font-size: 18px;
                                        font-weight: bold;
                                    }

                                    .platformIcons {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        margin-top: 14px;

                                        .pc-container, .psn-container,
                                        .xbox-container, .switch-container, .ios-container {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            margin-right: 16px;
                                            min-height: 44px;
                                            min-width: 44px;
                                            border-radius: 4px;
                                            margin-bottom: 0;

                                            img {
                                                max-height: 30px;
                                                max-width: 35px;
                                            }

                                        }

                                        .pc-container {
                                            background-color: $COLOR_PC_ICON;
                                        }
                                        .psn-container {
                                            background-color: $COLOR_PSN_ICON;
                                            .platformImage {
                                                filter: invert(1);
                                            }
                                        }
                                        .xbox-container {
                                            background-color: $COLOR_XBOX_ICON;
                                        }
                                        .switch-container {
                                            background-color: $COLOR_NSW_ICON;
                                        }
                                        .ios-container {
                                            background-color: $COLOR_IOS_ICON;
                                        }
                                    }


                                }

                                a {
                                    .Button {
                                        margin: 24px 0 16px 0;
                                    }

                                }

                                p {
                                    margin: 0;
                                    color: white;
                                    font-size: 18px;
                                    text-shadow: 0 0 5px black;
                                    width: 80%;
                                    @include mq(mobile) {
                                        width: 100%;
                                    }
                                    a {
                                        font-size: 16px;
                                        color: white;
                                        text-decoration: underline;

                                        &:hover {
                                            opacity: 80%;
                                            color: white;
                                        }
                                    }
                                }
                            }

                            .syncAmazon, .syncTwitch, .enable-2fa {
                                flex: 1;
                                width: 65%;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: flex-start;
                                @include mq(mobile) {
                                    width: 100%;
                                }

                                h1 {
                                    font-weight: bold;
                                    font-size: 18px;
                                    color: white;
                                    text-shadow: 0 0 5px black;
                                    margin-bottom: 20px;
                                }

                                h2 {
                                    word-spacing: normal;
                                }

                                strong {
                                    color: white;
                                    font-weight: bold;
                                    font-size: 14px;
                                    text-transform: uppercase;
                                    text-shadow: 0 0 5px black;
                                    margin-bottom: 20px;

                                    a {
                                        color: #FF6161;
                                    }
                                }

                                p {
                                    color: white;
                                    margin: 2px 2px 10px 2px;
                                    font-size: 14px;
                                    text-shadow: 0 0 5px black;
                                    //text-transform: uppercase;

                                    a {
                                        color: white;
                                        text-decoration: underline;

                                        &:hover {
                                            opacity: 80%;
                                            color: white;
                                        }
                                    }
                                }

                                a {
                                    margin-top: 10px;
                                }

                                .accountButton {
                                    @include button('.saveAccount');
                                }
                                .accountButton {
                                    transform: none;
                                    font-family: var(--font-family-button, #{"Roboto", "Noto Sans", Arial, Helvetica, sans-serif});
                                    font-weight: bold;
                                    font-size: inherit;
                                    transition: background 200ms;
                                    white-space: nowrap;
                                    min-width: 0;
                                    padding: 0 40px;

                                    &:hover {
                                        background-color: #BF0B02;
                                    }
                                }

                            }

                            .lockedStamp {
                                background: rgba(0, 0, 0, 0.4);
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 100%;
                                height: 100%;

                                img {
                                    display: block;
                                    margin-left: auto;
                                    margin-right: auto;
                                    margin-top: 15%;
                                }

                                strong {
                                    color: white;
                                    font-weight: bold;
                                    font-size: 14px;
                                    text-tansform: uppercase;
                                    position: absolute;
                                    text-align: center;
                                    width: 50%;
                                    transform: translateX(50%);
                                    margin-top: 25px;
                                    line-height: normal;
                                }
                            }

                            .stampCardInfo {
                                display: grid;
                                z-index: 1;
                                grid-template-columns: 60% 40%;
                                margin-bottom: 36px;

                                .stampCardTitleContainer {
                                    border-bottom: 1px rgb(255 255 255 / 25%) solid;
                                    padding-bottom: 24px;
                                }

                                @include mq(tablet) {
                                    grid-template-columns: 100%;
                                }

                                #stampCardTitle {
                                    h1 {
                                        margin-bottom: 0px;
                                        word-spacing: 1px;
                                    }
                                }

                                #stampCardDetail {
                                    padding-right: 10%;
                                }

                                #topLogo {
                                    margin: auto;
                                    z-index: 1;
                                    max-width: 300px;

                                    @include mq(tablet) {
                                        display: none;
                                    }

                                    img {
                                        width: 100%;
                                    }
                                }

                                h1 {
                                    //font-family: 'Open Sans', sans-serif;
                                    color: white;
                                    font-size: 18px;
                                    font-weight: bold;
                                    //letter-spacing: 1px;
                                    margin-bottom: 15px;
                                }

                                p {
                                    color: white;
                                }

                                b {
                                    font-weight: bold;
                                }
                            }

                            .stampCardProgress {
                                margin-bottom: 36px;
                                @include mq(tablet) {
                                    display: none;
                                }

                                .h3 {
                                    margin-bottom: 8px;
                                }

                                .stampCardProgressGrid {
                                    display: grid;
                                    position: relative;
                                    gap: 24px;
                                    z-index: 0;
                                    width: 55%;
                                    @include mq(mobile) {
                                        width: 100%;
                                    }

                                    .stamp {
                                        position: relative;
                                        width: 100%;
                                        padding-top: 100%;

                                        .innerStamp {
                                            line-height: 1;
                                            background-color: rgba(0, 0, 0, .5);
                                            position: absolute;
                                            width: 100%;
                                            height: 100%;
                                            border-radius: 5px;
                                            background-size: cover;
                                            background-repeat: no-repeat;
                                            transform: translate(-50%, -50%);
                                            top: 50%;
                                            left: 50%;

                                            $pg_animation_speed: 200ms;

                                            &.reward {
                                                z-index: 1;
                                                transition: width $pg_animation_speed, height $pg_animation_speed;

                                                img {
                                                    transition: width $pg_animation_speed, margin $pg_animation_speed;
                                                    width: 90%;
                                                    margin: 5%;
                                                }

                                                .rewardName {
                                                    pointer-events: none;
                                                    position: relative;
                                                    text-align: center;
                                                    opacity: 0;
                                                    transition: width $pg_animation_speed, left $pg_animation_speed, opacity $pg_animation_speed;
                                                    width: 175%;
                                                    left: -35%;
                                                }

                                                &:hover {
                                                    width: 200%;
                                                    height: 200%;
                                                    background-color: rgba(0, 0, 0, 1);

                                                    img {
                                                        width: 60%;
                                                        margin: 5% 20% 0 20%;
                                                    }

                                                    .rewardName {
                                                        //display: block;
                                                        opacity: 1;
                                                        width: 100%;
                                                        left: 0;
                                                    }
                                                }
                                            }
                                        }

                                    }

                                    .claimedStamp {
                                        .innerStamp {
                                            background-color: rgba(0, 106, 87, 1);
                                        }
                                    }
                                }
                            }

                            .stampCardProgressMobile {
                                margin-bottom: 16px;
                                display: none;
                                @include mq(tablet) {
                                    display: block;
                                }

                                .h3 {
                                    margin-bottom: 8px;
                                }

                                .stampCardStampDisplay {
                                    width: 100%;
                                    border-radius: 5px;
                                    min-height: 220px;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    background-color: rgba(0, 0, 0, .5);
                                    margin-bottom: 10px;
                                    padding: 15px;

                                    .rewardName {
                                        text-align: center;
                                    }
                                }

                                .stampCardProgressGrid {
                                    display: grid;
                                    position: relative;
                                    gap: 10px;
                                    z-index: 0;

                                    .stamp {
                                        position: relative;
                                        width: 100%;
                                        padding-top: 100%;

                                        .innerStamp {
                                            background-color: rgba(0, 0, 0, .5);
                                            position: absolute;
                                            width: 100%;
                                            height: 100%;
                                            border-radius: 5px;
                                            background-size: cover;
                                            background-repeat: no-repeat;
                                            transform: translate(-50%, -50%);
                                            top: 50%;
                                            left: 50%;

                                            &.reward {
                                                z-index: 1;
                                                transition: width .5s, height .5s;

                                                img {
                                                    transition: width .5s, margin .5s;
                                                    width: 90%;
                                                    margin: 5%;
                                                }

                                                .rewardName {
                                                    pointer-events: none;
                                                    position: relative;
                                                    text-align: center;
                                                    opacity: 0;
                                                    transition: width .5s, left .5s, opacity .5s;
                                                    width: 175%;
                                                    left: -35%;
                                                }
                                            }
                                        }

                                    }

                                    .claimedStamp {
                                        .innerStamp {
                                            background-color: rgba(0, 106, 87, 1);
                                        }
                                    }
                                }
                            }

                            .stampRedemptions {
                                display: grid;
                                align-items: center;
                                grid-template-columns: 55% 15% 30%;
                                background-color: rgba(0, 0, 0, 0.25);

                                .stampRedemptionExplanation {
                                    padding: 16px 24px 24px;
                                }

                                @include mq(tablet) {
                                    display: block;
                                    background-color: unset;

                                    .stampRedemptionExplanation {
                                        display: none;
                                    }
                                }

                                div {
                                    padding: 4px 8px;
                                    @include mq(tablet) {
                                        padding: 0;
                                    }
                                }

                                .activeItems {
                                    display: grid;
                                    align-content: center;
                                    height: 100%;
                                    text-align: center;
                                    color: rgba(239, 204, 134, 1);
                                    background-color: rgba(0, 0, 0, 0.25);

                                    .bigNum {
                                        font-weight: 700;
                                        font-size: 64px;
                                        margin-bottom: -10px;
                                    }

                                    @include mq(tablet) {
                                        height: unset;
                                        background-color: rgba(0, 0, 0, 0.4);
                                        grid-template-columns: 30% 70%;
                                        min-height: 100px;

                                        .bigNum {
                                            font-size: 36px;
                                            background-color: rgba(0, 0, 0, 0.25);
                                            margin-bottom: 0;
                                            padding: 25px 0;

                                            min-height: 100px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            height: 100%;
                                        }
                                        .rewardsWaiting {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            height: 100%;
                                        }
                                    }
                                }

                                .redeemBtnHolder {
                                    position: relative;
                                    text-align: center;
                                    @include mq(tablet) {
                                        margin: 16px 0;
                                    }

                                    .Button {
                                        @include mq(tablet) {
                                            width: 100%;
                                        }
                                    }
                                }

                                &.noRewards {
                                    grid-template-columns: 60% 40%;

                                    &.noEntitlements {
                                        .activeItems {
                                            grid-template-columns: unset;
                                        }
                                    }
                                }
                            }

                            .stampCardStamps {
                                display: grid;
                                grid-template-columns: repeat(auto-fit, minmax(185px, 1fr));
                                grid-auto-rows: 1fr;
                                grid-gap: 15px;
                                margin: 15px;
                                //justify-content: space-evenly;
                                //justify-items: center;
                                //align-items: center;
                                //align-content: space-evenly;
                                div {
                                    border-radius: 5px;
                                    display: grid;
                                    grid-template-columns: 60px 1fr;
                                    height: 100%;
                                    width: 100%;
                                    min-height: 40px;
                                    min-width: 153px;
                                    //margin: 15px;
                                }

                                p {
                                    color: rgb(255, 255, 255);
                                    font-size: 14px;
                                    margin: auto 8px auto 0px;
                                    padding-top: 6px;
                                    padding-bottom: 6px;
                                    line-height: normal;
                                }

                                img {
                                    margin: auto auto;
                                    max-height: 100%;
                                }

                                .circle {
                                    z-index: 1;
                                    position: absolute;
                                    height: 100px;
                                    width: 100px;
                                    background-color: #000;
                                    border-radius: 50%;
                                    display: inline-block;
                                    left: 6px;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                }

                                .stampRedeemed {
                                    position: relative;
                                    background-color: rgba(0, 0, 0, 0.75);
                                    overflow: hidden;

                                    &.stampGreen {
                                        background-color: rgb(47, 99, 13);
                                    }

                                    &.stampPurple {
                                        background-color: rgb(100, 65, 165);
                                    }

                                    img {
                                        width: 70%;
                                        z-index: 5;
                                    }

                                    span {
                                        display: block;
                                    }

                                    .circle {
                                        background-color: #006a57;
                                    }
                                }

                                .stampEntitled {
                                    background-color: rgb(100, 65, 165);

                                    img {
                                        width: 50%;
                                    }

                                    p {
                                        text-transform: uppercase;
                                    }
                                }

                                .stampAvailable {
                                    background-color: rgba(0, 0, 0, 1);

                                    img {
                                        width: 50%;
                                    }

                                    p {
                                        color: #c2c2c2;

                                        a {
                                            text-decoration: underline;
                                            display: block;
                                            color: rgb(255, 255, 255);
                                            text-decoration-color: rgb(255, 255, 255);
                                            text-transform: uppercase;
                                            font-weight: bold;
                                        }
                                    }
                                }

                                .stampUnavailable {
                                    position: relative;
                                    overflow: hidden;
                                    background-color: rgba(0, 0, 0, 0.5);

                                    img {
                                        width: 35%;
                                        z-index: 5;
                                    }

                                    p {
                                        color: rgb(194, 194, 194);
                                    }

                                    b, span {
                                        color: rgb(255, 255, 255);
                                        font-weight: bold;
                                        display: block;
                                        text-transform: uppercase;
                                    }
                                }
                            }

                            #newPass-error {
                                color: red;
                                margin-bottom: 8px;
                                margin-top: -16px;
                            }

                            #newPass2-error {
                                color: red;
                                margin-bottom: 8px;
                                margin-top: -16px;
                            }

                            .stampCardFooter {
                                border-top: 1px white solid;
                                margin-top: 15px;
                                padding: 0px;

                                .stampCardButton {
                                    position: relative;
                                    top: -3px;
                                    display: inline-block;
                                    padding-top: 15px;

                                    a {
                                        width: 0px;
                                    }

                                    @include mq(tablet) {
                                        min-width: 100%;
                                        .Button {
                                            min-width: 100%;
                                        }
                                    }
                                }

                                .stampCardAccountName {
                                    display: inline-block;
                                    color: white;
                                    font-size: 13px;
                                    font-weight: normal;
                                    margin-left: 15px;
                                    height: 38px;
                                    line-height: normal;
                                    padding-top: 15px;

                                    strong {
                                        font-weight: bold;
                                        text-transform: uppercase;
                                    }

                                    a {
                                        color: #B55FFF;

                                        &:hover {
                                            color: white;
                                        }
                                    }

                                    @include mq(tablet) {
                                        margin-left: 0px;
                                    }
                                }

                                .stampCardRewardEarnedMessage {
                                    width: 270px;
                                    margin: 10px 15px 10px 0px;
                                    background-color: rgba(0, 0, 0, 0.6);
                                    color: white;
                                    padding: 10px 15px 10px 15px;
                                    text-align: center;
                                    font-weight: bold;
                                    font-size: 13px;
                                    line-height: normal;
                                }

                                .stampCardLogo {
                                    position: absolute;
                                    right: 0;
                                    bottom: 0;
                                    margin-right: 50px;
                                    margin-bottom: 17px;
                                    margin-top: 17px;

                                    &-ended {
                                        position: relative;
                                        text-align: center;
                                    }

                                    //@include mq(tablet) {
                                    @media (max-width: 1024px) {
                                        position: relative;
                                        margin-bottom: 0px;
                                    }

                                    img {
                                        width: 140px;
                                        height: auto;
                                    }

                                    .stampCardVerticalLine {
                                        background-color: rgba(255, 255, 255, 0.5);
                                        width: 1px;
                                        height: 50px;
                                        display: inline-block;
                                    }

                                    .stampCardHorizontalLine {
                                        display: none;
                                    }

                                    @include mq(tablet) {
                                        margin-bottom: 0px;
                                        #warframeLogo {
                                            padding-top: 20px;
                                        }
                                    }
                                    @media (max-width: 467px) {
                                        .stampCardVerticalLine {
                                            display: none;
                                        }
                                        .stampCardHorizontalLine {
                                            display: block;
                                            margin: 25px 0px 25px 0px;
                                            width: 140px;
                                            border-top-color: rgba(255, 255, 255, 0.5);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .checkBox {
                        cursor: pointer;
                        color: white;
                        border: 1px solid black;
                        width: 18px;
                        height: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 0;
                        font-weight: bold;
                        transition: background-color 200ms;

                        &.checked {
                            border: 0px;
                            font-size: 18px !important;
                            background-color: #d30000;

                            &:hover {
                                background-color: #BF0B02;
                            }
                        }
                    }

                    label {
                        flex: 1;
                        padding-top: 2px;
                        padding-left: 5px;
                    }

                    &#stampCard {
                        line-height: 1.4;
                        color: white;
                        grid-column: 1 / -1;
                        align-items: stretch;

                        .accountCardBody {
                            padding: 5px;
                        }

                        .pgEnded {
                            min-height: 260px;

                            .accountCardBody {
                                padding-top: 25px;
                                justify-content: space-evenly;

                                p {
                                    line-height: 1.3;
                                }
                            }

                            h2, p {
                                padding: 0 20px;
                                color: white;
                                text-align: left;
                            }

                            p {
                                font-size: 22px;
                            }
                        }

                        #stampCardCard {

                            .announcement {
                                background-color: #1e2b17;
                                color: white;
                                padding: 10px 18px;

                                a {
                                    color: #b55fff;

                                    &:hover {
                                        color: white;
                                    }
                                }
                            }

                            .octaviaHeader {
                                width: 100%;
                                display: none;
                                @include mq(tablet) {
                                    display: block;
                                }
                            }

                            .accountCardBody {
                                padding: 25px 50px;
                                background: linear-gradient(to bottom right, #422128, #2c3434);
                                //background-image: url('../images/account/redesign2021/stampcard/desktop-bg.png');
                                @include mq(mobile) {
                                    //background-image: url('../images/account/redesign2021/stampcard/mobile-bg.png');
                                    background-position: center;
                                    padding: 25px;
                                }

                                .octaviaBg {
                                    position: absolute;
                                    width: 75%;
                                    right: 0;
                                    top: 0;
                                    @include mq(tablet) {
                                        display: none;
                                    }
                                }

                                .accountButton {
                                    @include button('.saveAccount');
                                }
                                .accountButton {
                                    transform: none;
                                    transition: background 200ms;
                                    white-space: nowrap;
                                    min-width: 0;
                                    padding: 0 40px;
                                    font-family: var(--font-family-button, "Roboto", "Noto Sans", Arial, Helvetica, sans-serif);
                                    font-weight: bold;
                                    font-size: inherit;
                                    background-color: rgb(100, 65, 165);

                                    &:hover {
                                        background-color: rgb(112, 75, 182);
                                    }
                                }
                            }
                        }
                    }

                    &#crossSave {
                        #crossSaveCard {
                            .accountCardBody {
                                background-color: rgb(0, 0, 0);
                                //background-image: url('../images/account/cross-save/CrossSave_Keyart.png');
                                //background-position: right;
                                //@include mq(mobile) {
                                //    background-image: unset;
                                //}
                                padding: 0;
                            }

                            .crossSaveImage {
                                width: auto;
                                max-width: 60%;
                                height: 100%;
                                object-fit: cover;
                                top: 0;
                                right: 0;
                                position: absolute;
                                @include mq(tablet) {
                                    max-width: 100%;
                                    max-height: 205px;
                                    width: auto;
                                    object-fit: cover;
                                    top: 0;
                                    position: relative;
                                    display: flex;
                                    justify-content: center;
                                }
                                @include mq(mobile) {
                                    display: none;
                                }

                                img {
                                    height: 100%;
                                    width: 100%;
                                    object-position: center;
                                    object-fit: cover;
                                    mask-image: linear-gradient(to right, transparent, black 20%);
                                    @include mq(tablet) {
                                        height: 100%;
                                        width: 80%;
                                        max-width: 100%;
                                        object-position: center 25%;
                                        mask-image: linear-gradient(to right, transparent, black 10% 90%, transparent),
                                                    linear-gradient(to bottom, black 75%, transparent);
                                        -webkit-mask-composite: source-in;
                                        mask-composite: intersect;
                                    }

                                }
                            }

                            .crossSaveImageMobile {
                                display: none;
                                position: relative;
                                max-width: 100%;
                                max-height: 140px;
                                top: 0;
                                @include mq(mobile) {
                                    display: flex;
                                    justify-content: center;
                                }

                                img {
                                    width: 90%;
                                    max-width: 100%;
                                    max-height: 140px;
                                    object-fit: cover;
                                    object-position: center 25%;
                                    mask-image: linear-gradient(to right, transparent, black 10% 90%, transparent),
                                                linear-gradient(to bottom, black 75%, transparent);
                                    -webkit-mask-composite: source-in;
                                    mask-composite: intersect;
                                }
                            }
                        }
                    }

                    &#lockedStampCard {
                        #lockedStampCardCard {
                            .accountCardBody {
                                background-color: rgb(34, 52, 27);
                                background-image: url('../images/account/redesign2021/stampcard/desktop-bg.png');
                                background-position: right;
                                @include mq(mobile) {
                                    background-image: url('../images/account/redesign2021/stampcard/mobile-bg.png');
                                }

                                .whiteLock {
                                    background-image: url('../images/account/redesign2021/stampcard/white-lock.png');
                                }
                            }
                        }
                    }

                    &#twitchSync {
                        #twitchSyncCard {
                            .accountCardBody {
                                background-color: rgb(26, 19, 50);
                                background-image: url('../images/account/redesign2021/twitch-card-background.png');
                                @include mq(mobile) {
                                    background-image: unset;
                                }

                                a {
                                    .accountButton {
                                        //background: #9632FD;

                                        &:hover {
                                            //background: #772CE8;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &#amazonSync {
                        #amazonSyncCard {
                            .accountCardBody {
                                background-color: rgb(19, 40, 50);
                                background-image: url('../images/account/redesign2021/amazon-bg.png');
                                @include mq(mobile) {
                                    background-image: unset;
                                }

                                a {
                                    .accountButton {
                                        //background: rgb(100, 65, 165);

                                        &:hover {
                                            //background: rgb(110, 71, 182);
                                        }
                                    }
                                }
                            }
                        }
                    }


                    &#enable-2fa {
                        #enable-2faCard {
                            color: white;

                            .accountCardBody {
                                background-color: rgb(19, 22, 50);
                                background-image: url('../images/account/redesign2021/2fa-bg.jpg');
                                @include mq(mobile) {
                                    background-image: unset;
                                }
                            }

                            &.console {
                                .accountCardBody {
                                    justify-content: center;
                                }
                            }

                            &.enabled {
                                .accountCardBody {
                                    justify-content: space-between;
                                }
                            }

                            .icon {
                                position: absolute;
                                width: 200px;
                                right: 0px;
                                top: 55px;
                                @include mq(mobile) {
                                    display: none;
                                }
                            }

                            p {
                                margin-right: 35%;
                                @include mq(mobile) {
                                    margin-right: 0;
                                }

                                &.twofa-on {
                                    a {
                                        text-decoration: underline;
                                    }
                                }


                            }

                        }

                        a {
                            color: lightgrey;
                            //text-decoration: underline;

                            &:hover {
                                color: white;
                            }

                            &.learnMore {
                                text-decoration: underline;
                            }
                        }

                    }

                    &#accountInfo {

                        .accountInfo {
                            display: flex;
                            height: auto;
                            transition: height 0.25s ease-out;

                            &.grown {
                                height: auto;
                            }
                        }

                        .accountInfoContent {
                            display: flex;
                            flex-direction: column;
                            opacity: 0%;
                            transition: visibility 0s, opacity 0.15s ease-in;
                            visibility: hidden;
                            height: 0;
                            overflow: hidden;

                            &.active {
                                opacity: 100%;
                                visibility: visible;
                                height: auto;
                            }
                        }

                        ul.infoTable {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;


                            @media(max-width:768px){
                                padding: 0px;
                            }
                            .icon {
                                width: 12px;
                                vertical-align: middle;
                                margin-bottom: 2px;
                                image-rendering: crisp-edges;
                            }

                            .iconBtn {
                                width: 30px;
                                vertical-align: middle;
                                margin-bottom: 2px;
                                image-rendering: crisp-edges;
                            }

                            .cardLink {
                                flex: 1;
                            }

                            .btnSubmit {
                                min-width: 0 !important;
                                margin: 0 !important;
                                transition: background-color 200ms;

                                &:hover {
                                    background-color: #BF0B02;
                                }

                                @media (max-width: 768px){
                                    padding: 0 15px;
                                }
                            }

                            li {
                                height: auto;
                                padding: 15px 0px;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                                
                                .half {
                                    overflow: hidden;
                                    flex: 1;
                                }

                                .values{
                                    margin: 5px 0px;
                                    @include mq(mobile){
                                        max-width: 175px;
                                    }
                                }
                                @include mq(mobile){
                                    gap: 10px;
                                }
                            }
                            
                            .note-box{
                                background-color: #fff3cd; /* Light yellow background */
                                border: 1px solid #eec13b;  /* Light yellow border */
                                padding: 10px;
                                margin-bottom: 20px;
                                border-radius: 5px;
                                font-family: Arial, sans-serif;
                                font-size: 14px;
                            }

                            .top-border{
                                border-top: 1px solid black;
                            }

                            .subtext{
                                font-size: 0.85rem;
                            }

                            .accountField {
                                display: block;
                                font-weight: bold;
                                font-size: 16px;

                                @include mq(mobile){
                                    font-size: 15px !important;
                                }
                            }

                            .accountVal {
                                display: block;
                                min-width: 60px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 100%;
                                margin-top: 5px;
                                padding-bottom: 2px;
                                font-size: 16px;

                                &::after {
                                    content: '';
                                    clear: both;
                                }
                            }

                            .accountPlatRegalVal{
                            }
                        }

                        label.newsletterLabel {
                            display: inline;
                            font-size: 13px;
                            position: relative;
                            top: 4px;
                            left: 4px;
                        }

                        input#newsletter {
                            margin: 0;
                        }

                        .btnSubmit {
                            float: right;
                            margin-top: 68px;
                        }
                    }

                }

                #changeAlias, #changePw, #changeEmail, #clanSettings, #allianceSettings, #socialMediaSettings, #gdprSettings {

                    #resendEmailVerification {
                        position: absolute;
                        top: 18px;
                        right: 15px;
                        cursor: pointer;
                    }

                    .form-inline {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 15px;

                        h2 {
                            font-weight: bold;
                            font-size: 16px;
                            color: #686868;
                            margin-bottom: 5px;
                            letter-spacing: 1px !important;
                        }

                        label {
                            line-height: 18px;
                            display: inline-block !important;
                            vertical-align: top;
                            margin-right: 20px;
                            width: 80%;
                            padding: 0 !important;
                            flex: none !important;
                        }

                        input[type="checkbox"] {
                            margin: 0 !important;
                        }

                    }

                    .btnSubmit {
                        float: right;
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }

                #clanSettings {
                    button {
                        float: right !important;
                    }
                }

                #change-guild-image, #change-alliance-image {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }

                #forumAccountSettings {
                    #accountCardHeader {
                        @include breakpoint-mobile-only {
                            padding: 15px;
                        }
                    }
                    .accountCardBody {

                        @include breakpoint-mobile-only {
                            padding: 30px 20px;
                        }

                        #forumAccountForm {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        }

                        .description {
                            p {
                                text-align: center;
                                margin: 0;
                            }
                        }
                        .platformIcons {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-wrap: wrap;
                            margin: 30px 0;

                            .platformForumContainer {
                                max-width: 90px;
                                max-height: 90px;
                                cursor: pointer;
                                padding: 0;
                                margin: 0;

                                .platformIcon {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 8px;
                                    margin: 15px;
                                    height: 60px;
                                    width: 60px;
                                    filter: opacity(60%);

                                    .platformImage {
                                        width: 90%;
                                        height: 90%;
                                        padding: 10px;
                                        box-sizing: border-box;
                                    }
                                }

                                .forumAccountSelector {
                                    opacity: 0;
                                    width: 0;
                                    height: 0;
                                }

                                .pc-icon {
                                    background-color: $COLOR_PC_ICON;
                                }
                                .psn-icon {
                                    background-color: $COLOR_PSN_ICON;
                                    .platformImage {
                                        filter: invert(1);
                                    }
                                }
                                .xbox-icon {
                                    background-color: $COLOR_XBOX_ICON;
                                }
                                .switch-icon {
                                    background-color: $COLOR_NSW_ICON;
                                }
                                .ios-icon {
                                    background-color: $COLOR_IOS_ICON;
                                }
                            }
                            .selected {
                                background-color: #D6D6D6;
                                filter: none;
                                .platformIcon {
                                    filter: none;
                                }
                            }
                        }
                        .disclaimer {
                            p {
                                text-align: center;
                            }
                        }
                        .btnSubmit {
                            margin: 30px auto 10px;
                        }
                    }
                }

                .notice {
                    font-weight: bold;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    text-transform: uppercase;
                }

                .tabs {
                    display: flex;
                    flex-direction: row;
                    height: auto;
                    padding: 0;
                    margin: 0;

                    .tab {
                        cursor: pointer;
                        height: auto;
                        display: inline-block;
                        //font-family: 'Open Sans', sans-serif;
                        width: 50%;
                        background: #cccccc;
                        text-align: center;
                        text-transform: uppercase;
                        line-height: 30px;
                        transition: border-bottom-width 50ms;
                        margin: 0;

                        &:hover {
                            border-bottom: 2px #d30000 solid;
                        }

                        &.active {
                            background: #f0f0f0;
                            border-bottom: 2px #d30000 solid;
                            cursor: default;
                        }
                    }
                }

                .tabContent {
                    display: flex;
                    flex-direction: column;

                    &.inactive {
                        height: 100% !important;
                        display: none;
                    }
                }

                .dragdrop {
                    &.enabled {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        flex: 1;
                        border: 2px dashed black;
                    }

                    &.draggedOver {
                        border: 2px dashed #d30000;
                    }
                }

                #spinner {
                    position: absolute;
                    top: 16px;
                    right: 15px;
                    width: 20px;
                    height: 20px;
                    border: 2px solid #d98c8c;
                    border-top: 2px solid #d30000;
                    border-radius: 50%;
                    transition-property: transform;
                    animation-name: rotate360;
                    animation-duration: 1.2s;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                }

                @keyframes rotate360 {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
            }
        }

        .extras {
            .extra {
                margin-bottom: 5px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .syncTwitch .synced {
                font-weight: bold;
            }
        }

        .hidden {
            display: none;
        }
    }

    #guild-image-dialog {
        button {
            float: left !important;
            margin-bottom: 20px;
        }
    }

    .addNewsletterEmailC {
        @include button('.addNewsletterEmail');

        a {
            font-size: 11px;
            color: white;
            font-weight: bold;
        }
    }

    #change-console-email {
        //display: none;
    }

    #cboxLoadedContent {
        width: 900px;
        max-width: 90vw;
        height: auto !important;
        padding: 0 !important;
        font-size: 14px;
        line-height: 20px;
        overflow: hidden !important;

        .header {
            background: #CCCCCC;
            text-align: center;
            padding: 10px;
            text-transform: uppercase;

            h1 {
                font-size: 20px;
                color: black;
                letter-spacing: normal;
            }
        }

        .checkBox {
            cursor: pointer;
            color: white;
            border: 1px solid black;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0;
            font-weight: bold;
            transition: background-color 200ms;

            &.checked {
                border: 0px;
                font-size: 18px !important;
                background-color: #d30000;

                &:hover {
                    background-color: #BF0B02;
                }
            }
        }

        .inner {
            position: relative;

            .textPane {
                width: 60%;

                p {
                    color: black;
                    margin-bottom: 5px;
                }

                .notice {
                    font-weight: bold;
                    text-transform: uppercase;
                }

                @media screen and (max-width: 1000px) {
                    width: 100%;
                }
            }
        }

        #excalibur {
            height: 500px;
            right: 0;
            bottom: 0px;
            position: absolute;

            @media screen and (max-width: 1000px) {
                display: none;
            }
        }

        #clanEmblemImagePreview, #allianceEmblemImagePreview {
            position: absolute;
            z-index: 1;
            right: 42px;
            bottom: 250px;
            width: 80px;
            height: 80px;
            animation: pop ease-in 0.5s forwards;

            @keyframes pop {
                from {
                    filter: drop-shadow(0 0 50px white) brightness(0) invert(1)
                }
                to {
                    filter: none
                }
            }

            @media screen and (max-width: 1000px) {
                width: 80px;
                height: 80px;
                position: relative !important;
                right: 0 !important;
                top: 0 !important;
                display: block;
            }
        }
    }

    #cboxLoadedContent .consoleEmail {
        padding: 10px;

        input[type="text"] {
            border: 1px solid black;
            width: 90%;
            margin: 0 auto;
            margin-top: 15px;
        }

        input[type="submit"] {
            background-color: $pcRed;
            margin-top: 10px;
            padding: 5px 10px;
            height: auto;
        }
    }

    input {
        border: none;
    }

    [type="submit"] {
        color: white;
    }

    @include mq(mobile) {
        #accountOptionContain {
            padding-top: 20px;
            display: block !important;
        }

        .optionContain {
            display: block !important;
        }

        .accountCard {
            min-width: 300px;
            width: 80vw !important;
            @include mq(mobile) {
                min-width: unset;
            }
        }

        #accountInfo ul.infoTable li {
            padding-left: 0px !important;
        }

        #changeAlias, #changePw, #clanSettings {
            .columns {
                padding-left: 0 !important;
            }
        }
    }

    //grid column 200px
    //{
    //   .accountOptions {
    //     #accountOptionContain {
    //       .optionContain {
    //         #stampCardCard {
    //           .accountCardBody {
    //             .stampCardStamps {
    //               grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    //grid column 205px
    &.lang-pl, &.lang-es, &.lang-ja, &.lang-tr, &.lang-it {
        .accountOptions {
            #accountOptionContain {
                .optionContain {
                    #stampCardCard {
                        .accountCardBody {
                            .stampCardStamps {
                                grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
                            }
                        }
                    }
                }
            }
        }
    }

    //grid column 210px
    &.lang-zh-hant {
        .accountOptions {
            #accountOptionContain {
                .optionContain {
                    #stampCardCard {
                        .accountCardBody {
                            .stampCardStamps {
                                grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
                            }
                        }
                    }
                }
            }
        }
    }

    //grid column 220px
    &.lang-ru {
        .accountOptions {
            #accountOptionContain {
                .optionContain {
                    #stampCardCard {
                        .accountCardBody {
                            .stampCardStamps {
                                grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
                            }
                        }
                    }
                }
            }
        }
    }

    //grid column 240px
    &.lang-fr {
        .accountOptions {
            #accountOptionContain {
                .optionContain {
                    #stampCardCard {
                        .accountCardBody {
                            .stampCardStamps {
                                grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
                            }
                        }
                    }
                }
            }
        }
    }

    //grid column 250px
    &.lang-ko {
        .accountOptions {
            #accountOptionContain {
                .optionContain {
                    #stampCardCard {
                        .accountCardBody {
                            .stampCardStamps {
                                grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
                            }
                        }
                    }
                }
            }
        }
    }

    //grid column 260px
    &.lang-pt-br {
        .accountOptions {
            #accountOptionContain {
                .optionContain {
                    #stampCardCard {
                        .accountCardBody {
                            .stampCardStamps {
                                grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
                            }
                        }
                    }
                }
            }
        }
    }

    //font for Turkish and Polish
    &.lang-tr, &.lang-pl {
        //font-family: 'Roboto', Sans-Serif;
        #account-manage {
            .accountOptions {
                #accountOptionContain {
                    .optionContain {
                        &#stampCard {
                            #stampCardCard {
                                .accountCardBody {
                                    .accountButton {
                                        //font-family: 'Roboto', Sans-Serif;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#change-alias, #change-clan-name {
    .accountHeader {
        margin-bottom: 20px;
    }

    input[type="text"] {
        width: 100%;
    }
}

#emblemImagePreview {
    width: auto;

    .containImg {
        display: inline-block;
        background-image: url('../images/account/emblemPreview.jpg');
        width: 359px;
        height: 657px;
        position: relative;
        margin: 0 auto;

        img {
            top: 180px;
            position: absolute;
            left: 60px;
            width: 90px;
            height: 90px;
        }
    }

    .previewLinks {
        margin-top: 20px;
    }
}

@include mq(mobile){
    .gdprButton{
    max-width:225px;
    }
}
