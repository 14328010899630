#gdprsticky{
    display: none;
    position: relative;
    bottom: 0;
    background-color: white;
    z-index: 20;
    width: 100%;
    border-top:3px solid #d30000;
    padding:10px;
    font-size: 14px;
    line-height: 40px;
    text-align: center;

    p{
        display: inline-block;
        width: 60%;
        line-height: 16px;
        font-size: 14px;
        margin: 0;
    }

    .actionBtns{
        display: inline;
        margin-left: 20px;
        position: relative;
        top: -5px;

        a{
            border:3px solid #d30000;
            padding: 8px;
            margin-right: 10px;
            color:#d30000;
            text-decoration: none;
            font-family: 'Open-sans',sans-serif;

            &:last-child{
                margin-right: 0;
            }
        }
    }

    a.close{
        position: absolute;
        top: 0;
        right: 40px;
        font-size: 20px;
        color: #d30000;
        margin: 0;
        text-decoration: none;
    }

    @include mq(mobile){
        line-height: inherit;
        height: 160px;

        p{
            display: block;
            float:none;
            width:100%;
            margin-left: 0;
        }

        .actionBtns{
            margin-left: 0;
            display: block;
            margin-top: 30px;
        }

        a.close{
            top:7px;
        }
    }
}

#gdprLightboxContainer{
    display: none;
}

.gdprLightbox{
    padding: 20px;

    table{
        margin: 25px 0;
        border-collapse: separate;
        border-spacing: 1px;

        tr{
            height: 50px;
            td{
                padding: 8px;

                &.value{
                    text-align: center;
                    background-color: #343434;
                    color:#fff;
                }

            }
        }
    }

    p {
      a {
        font-family: 'Open-sans', sans-serif;
        text-decoration: none;
        color: #d30000;
      }
    }

    .accept{
        padding:0;
        float:right;
        margin-bottom: 15px;

        a {
          font-family: 'Open-sans', sans-serif;
          text-decoration: none;
          color: #d30000;
        }

        a.value{
            display: block;
            text-align: center;
            background-color: #343434;
            color:#fff;
            height: 50px;
            line-height: 50px;
            margin-bottom: 5px;
        }
    }

    .turnOff {
        a {
            font-family: 'Open-sans', sans-serif;
            text-decoration: none;
            color: #d30000;
        }
    }
}