body.new_confirm{
    @include fullscreen-content-page;

    #sysReqsLB{
        display: none;
    }

    .sysReqsLBinner{
        text-align: center;
        background:#ebebeb;
        color:#000;

        .title{
            background: #fff;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 28px;
            padding: 12px;
        }

        .SystemRequirementsGrid {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            align-items: stretch;
            gap: 16px;
            padding: 1em;

            &-tile {
                border-radius: 5px;
                display: inline-grid;
                border: 1px solid black;
                padding: 16px;
            }
        }

        p.noSupport{
            font-size: 24px;
            font-weight: bold;
            padding-bottom: 15px;
            text-transform: uppercase;
        }
    }

    #mainText {
        .text-content{
            .title{
                font-size: 36px;
            }

            .contactImg{
                width:25%;

                @include mq(mobile){
                    width:50%;
                }
            }

            .bigDLWrapper {
                @include button('.bigDLBtn');
            }
            // split due to SASS deprecation
            .bigDLWrapper{
                padding: 10px 45px;
                height: 58px;
                font-size: 26px;

                @include mq(tablet){
                    font-size: 16px;
                }

                a{
                    color:#fff;
                }
            }

            a.sysReqs{
                display: block;
                color:#fff;
                text-transform: uppercase;
                font-weight: bold;
                margin-top: 15px;
                font-size: 18px;
                text-decoration: underline;
            }

            a.steamButton{
                display: inline-block;
                margin-top: 15px;

                img{
                    width: 75%;
                }
            }

            a {
                color: white;
                text-decoration: underline;
                &:hover {
                    color: white;
                }
            }
        }
    }
}

body.new_thank_you{
    @include fullscreen-content-page;

    .ThankYouPanel {
        position: relative;
        width: 80%;
        max-width: 800px;
        margin: $SPACE_HUGE auto;
        background-color: rgba(0,0,0,.5);
        text-align: center;
        z-index: 2;

        &-title {
            padding: 16px 32px;
            background-color: rgba(0,0,0,.5);
        }
        &-content {
            padding: 32px;

            p {
                margin-top: 1em;
            }
        }
    }
}
body.new_confirm,
body.new_thank_you{
    .ThankYouPanel {
        position: relative;
        width: 80%;
        max-width: 800px;
        margin: $SPACE_HUGE auto;
        background-color: rgba(0,0,0,.5);
        text-align: center;
        z-index: 2;

        &-title {
            padding: 16px 32px;
            background-color: rgba(0,0,0,.5);
        }
        &-content {
            padding: 32px;

            p {
                margin-top: 1em;
            }

            a {
                color: white;
                text-decoration: underline;

                &:hover {
                    color: #ff0000;
                }
            }
        }
    }
}
