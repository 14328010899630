@import "../utils/_mixins";
@import "../utils/_variables";

$WHITE_BORDER: #D9D9D9;
$BLACK: #0F1519;

body {
    #mainContainer{
        min-width: 100%;
        min-height: 100%;
        position: relative;
        background-color: $BLACK;
        background-image: url('../images/404/bkg-orbiter-optimized.jpg');
        background-position: center;
        background-size: cover;

        display: flex;
        justify-content: center;

        @include mq(steambrowser) {
            display: flex;
            justify-content: center;
        }

        @include mq(tablet) {
            display: flex;
            justify-content: center;
        }
    }

    #contentContainer {
        display: flex;
    
        @media (min-width: 876px) {
            padding-top: 144px;
        }

        @include mq(steambrowser) {
            padding-top: 80px;
            margin-left: 0px;
        }


        @include mq(tablet) {
            margin-top: 80px;
            padding-top: 0px;
        }
    }

    #ordis {
        max-height: 256px;        
        max-width: auto;
        border: 1px solid $WHITE_BORDER;
        padding: 14px;
        margin-right: 48px;

        @include mq(tablet) {
            max-height: 140px;
            padding: 1px;
        }
        @include mq(mobile) {
            display: none;
        }

    }

    #textContainer {
        z-index: 2;
        max-width: 575px;
        
        #subtitle404 {
            display: flex;
        }

        #smallOrdis{
            display: none;
            @include mq(mobile) {
                max-height: 64px;        
                max-width: auto;
                border: 1px solid $WHITE_BORDER;
                margin-right: 24px;        
                display: inline-block;
            }    
        }
        
        #homeButton {
            margin-bottom: 353px;
            @include mq(tablet) {
                margin-bottom: 160px;
            }
        }
        @include mq(tablet) {
            max-width: 440px;
        }
        @include mq(mobile) {
            max-width: 330px;
        }

        h1, h2, p {
            color: white;
        }    

        h2 {
            text-transform: none;
        }

        #info {
            line-height: 24px;
            margin-top: 16px;
            margin-bottom: 24px;
            font-weight: 400;
        }

        hr {
            margin-top: 16px;
            margin-bottom: 16px;
            height: 1px;
            opacity: 50%;
        }
    }
    
    .wrapper {
        min-height: 0;
    }
}
