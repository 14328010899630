// Build notes page
// todo: migrate to theme sheet when page is updated
@import "../utils/_mixins";
@import "../utils/_variables";
@import "../style-guide/_responsive";
@import "../style-guide/_variables";

#build-notes {
    overflow-x: hidden;
    max-width: 100%;

  .spoilers {
    position: relative;
    &:before {
      content: "Spoiler Warning";
      position: relative;
      text-shadow: 0 0 3px #000;
      margin-top: 10px;
      padding: 10px;
      display: block;
      top: 0;
      background-color: grey;
      color: white;
      font-weight: bold;
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: calc(100% - 35px);
      top: 38px;
      left: 0;
      background-color: #686868;
    }
    &:hover {
      &:after {
        background-color: unset;
      }
    }
  }

  .buildTracker {
    position: fixed;
    top: 100px;
    z-index: 198;
    width: 94px;
    min-height: 300px;
    height: auto;
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;

    @media screen and (max-width: 875px) {
      top: 147px;
    }

    @include mq(mobile) {
      display: none;
    }

    .platformWrap {
      background-color: rgb(255, 255, 255);
      background-color: rgba(255, 255, 255, 0.8);
      height: 100%;
      float: left;
      padding: 8px 5px;
      width: 100%;

      hr {
        margin-top: 12px;
        margin-bottom: 5px;
      }

      .trackerLogoPC, .trackerLogoPSN, .trackerLogoXB1, .trackerLogoXBOX, .trackerLogoSwitch, .trackerLogoSWITCH, .newTrackerLogoXBOX, .trackerLogoMobile, .trackerLogoMOBILE {
        margin: 0 auto;
        height: 44px;
        width: 44px;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .trackerbar {
        background-color: #a7a7a7;
        height: 7px;
        width: 60px;
        margin: 0 auto;
        margin-top: 5px;
        margin-bottom: 5px;
        position: relative;

        .trackerFill {
          height: 7px;
        }

        .trackerTick {
          height: 10px;
          width: 1px;
          background-color: #000000;
          position: absolute;
          bottom: -1px;
        }

        .pcTick {
          left: 30px;
        }

        .consoleTick1 {
          left: 20px;
        }

        .consoleTick2 {
          left: 40px;
        }
      }

      .pcContain {
        color: $steamGrey;

        .trackerLogoPC {
          background-image: url('../images/mainSiteAssets/updateTracker/pcGrey.png');
        }

        .pcFillLive {
          background-color: $steamGrey;
        }
      }

      .ps4Contain, .psnContain {
        color: $ps4Blue;

        .trackerLogoPSN {
          background-image: url('../images/mainSiteAssets/updateTracker/ps4.png');
          width: 53px !important;
          height: 51px !important;
          margin-bottom: 5px;
        }

        .newTrackerLogoPSN {
          background-image: url('../images/mainSiteAssets/updateTracker/PS4logo.png');
          width: 85px !important;
          height: 16.5px !important;
          margin: 10px 0 8px 0;
        }

        .trackerLogoPS5 {
          background-image: url('../images/mainSiteAssets/updateTracker/PS5logo.png');
          background-repeat: no-repeat;
          position: relative;
          left: 2px;
          width: 75px !important;
          height: 20px !important;
          margin-bottom: 5px;
        }

        .ps4FillLive {
          background-color: $ps4Blue;
        }
      }

      .xboxContain {
        color: $xb1Green;

        .trackerLogoXB1, .trackerLogoXBOX {
          background-image: url('../images/mainSiteAssets/updateTracker/xb1.png');
          width: 53px !important;
          height: 51px !important;
        }

        .newTrackerLogoXBOX {
          background-image: url('../images/mainSiteAssets/updateTracker/XB1Logo.png');
          width: 78px !important;
          height: 16px !important;
          margin: 12px 0px 5px 0px;
          position: relative;
          left: 3px;
        }

        .trackerLogoXBSX {
          background-image: url('../images/mainSiteAssets/updateTracker/XBSXLogo.png');
          background-repeat: no-repeat;
          transform: scale(0.80);
          position: relative;
          left: -10px;
          width: 105px !important;
          height: 20px !important;
          margin-bottom: 7.5px;
        }

        .xboxFillLive {
          background-color: $xb1Green;
        }
      }

      .switchContain {
        color: $pcRed;

        .trackerLogoSwitch, .trackerLogoSWITCH {
          background-image: url('../images/mainSiteAssets/updateTracker/switch.png');
          width: 76px !important;
          height: 71px !important;
        }

        .switchFillLive {
          background-color: $pcRed;
        }
      }

      .mobileContain {
        color: $pcRed;

        .trackerLogoMobile, .trackerLogoMOBILE {
            background-image: url('../images/icons/material/phone.svg');
            width: 60px !important;
            height: 60px !important;
            margin-bottom: 2px;
        }

        .switchFillLive {
          background-color: $pcRed;
        }
      }
    }

    .trackerTabContain {
      float: right;
      width: 15px;
      height: 100%;
      position: absolute;
      right: -15px;
      cursor: pointer;

      .beforeSlant {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-left: 15px solid #212121;
      }

      .afterSlant {
        width: 0;
        height: 0;
        border-bottom: 10px solid transparent;
        border-left: 15px solid #212121;
      }

      .trackerTab {
        width: 15px;
        height: 50%;
        background-color: #212121;

        p {
          width: 793%;
          position: absolute;
          top: 50%;
          left: -53px;
          color: #FFFFFF;
          font-size: 10px;
          white-space: nowrap;
          transform: rotate(90deg);
        }
      }
    }
  }

  #header {
    position: relative;

    #list-container {
      z-index: 100;
      position: absolute;
      right: 0;
      top: 80px;
      background-color: rgba(0, 0, 0, 0.6);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      width: 250px;
      color: white;
      box-shadow: 5px 5px 5px #0000006e;
      max-height: initial;
      opacity: 100%;
      overflow-y: hidden;

      #update-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 5px 5px 5px 22px;
        font-size: 16px;
        text-transform: uppercase;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.5);
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        #dropdown-icon {
          height: 8px;
          margin: 0 15px 0 10px;
          transform: rotate(90deg);
          transition: transform 200ms ease-out;
        }

        &:hover {
          opacity: 80%;
        }
      }

      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-track {
        background: #2a2a2c;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background: #7d7d7f;
        border-radius: 10px;
      }

      .scrollContain {
        overflow-x: hidden;
        overflow-y: hidden;
        max-height: 350px;
        scrollbar-width: thin;
        margin-right: 5px;
      }

      #list {
        max-height: 0;
        transition: max-height 500ms ease-out;

        .listItem {
          color: white;
          padding: 5px 0 0 5px;
          width: 100%;
          border-top: 1px rgba(0, 0, 0, 0.1) solid;

          .majorItem {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            cursor: pointer;
            padding-bottom: 5px;

            p {
              margin: 0 2px;
            }

            .indicator {
              min-width: 15px;
              width: 15px;
              text-align: center;
            }

            &:hover {
              opacity: 80%;
            }
          }

          .subList {
            overflow: hidden;
            //max-height: 0px;
            margin: 0;
            //transition: max-height 200ms ease-out;

            .subListItem {
              color: lightgrey;
              padding: 5px 20px 5px 35px;
              cursor: pointer;
              border-top: 1px rgba(0, 0, 0, 0.1) solid;

              &.active, &:hover {
                color: white;
              }
            }
          }
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    #carousel-container {
      width: 100%;
      position: relative;

      #mobile-swipe-indicator {
        z-index: 10;
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 0;
        width: 100%;
        height: 60vh;
        display: none;
        align-items: center;
        justify-content: center;
        transition: opacity 200ms;

        img {
          filter: invert(100%);
          width: 100px;
          object-fit: contain;
          animation: slide 1.5s ease-in-out infinite alternate;

          @keyframes slide {
            from {
              transform: translateX(-50px)
            }
            to {
              transform: translateX(50px)
            }
          }
        }
      }

      #keyart {
        height: 55vh;
        width: inherit;

        .keyartContainer {
          height: inherit;
          width: inherit;
          left: 0px;
          top: 0px;
          position: relative;
          display: flex;
          align-items: center;
          overflow: hidden;

          .foreground {
            height: inherit;
            width: auto;
            margin: 0 auto;
            object-fit: contain;
            box-shadow: 0 0 50px black;
          }

          .background {
            z-index: -1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 120%;
            filter: blur(15px)
          }

        }
      }

      .carousel {
        width: 100vw;
        height: 11.25vw;
        max-width: 100%;
        box-shadow: 0 0 50px black;
        background: black;

        @media screen and (max-width: 1440px) {
          height: 18.75vw;
        }

        .imgContainer {
          position: relative;
          height: inherit;
          background: black;

          img[src=""],
          img:not([src]) {
            visibility: hidden;
          }

          img {
            width: inherit;
            height: inherit;
            object-fit: cover;
          }

          .gradient {
            top: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8));
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);

            &:hover {
              box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.65);
            }

            .minorTitle {
              font-size: 20px;
              text-transform: uppercase;
              text-align: center;
              color: white;
              //font-family: "Michroma", 'Open-Sans', Sans-Serif;
              margin: 20px;

              @media screen and (max-width: 1440px) {
                font-size: 16px;
              }
            }
          }

        }

        .slick-slide {
          cursor: pointer;

          &:focus {
            outline: none !important;
          }
        }

        button {
          all: unset;
          position: absolute;
          z-index: 50;
          color: transparent !important;
          height: 8vh;
          width: 50px;
          background-size: contain !important;
          background-repeat: no-repeat !important;
          top: 50%;
          transform: translateY(-50%);
          margin: 10px;
          cursor: pointer;
          opacity: 50%;

          &:hover {
            opacity: 100%;
          }

          &.slick-prev {
            background: url('../images/buildnotes/arrow-left.png');
            left: 5px;
          }

          &.slick-next {
            background: url('../images/buildnotes/arrow-right.png');
            right: 0px;
          }
        }
      }

      .slick-track {
        background: black;
      }

      .slick-slide {
        opacity: 50%;
        transition: opacity 400ms ease-out;

        @include mq(mobile) {
          opacity: 100%;
        }

        &:hover, &.selected {
          opacity: 100%;
        }
      }

      .slick-list,
      .slick-track,
      .slick-slide {
        height: 100%;
        margin: 0;
        padding: 0 !important;
      }
    }
  }

  #mobile-platform-selector {
    background-color: white;
    display: none;
    grid-auto-columns: 1fr;
    grid-gap: 20px;
    padding: 20px;
    width: 100%;

    @include mq(mobile) {
      display: grid;
    }

    .platformButton {
      width: 100%;
      height: 40px;
      line-height: 38px;
      font-size: 24px;
      text-transform: uppercase;
      text-align: center;
      border: 2px solid;

      &.pc {
        grid-column-start: 1;
        grid-column-end: 2;
        border-color: black;
        color: black;

        &:focus, &.active {
          background-color: black;
        }
      }

      &.mobile {
        grid-column-start: 1;
        grid-column-end: 2;
        border-color: black;
        color: black;

        &:focus, &.active {
          background-color: black;
        }
      }

      &.xbox {
        grid-column-start: 2;
        grid-column-end: 3;
        border-color: $xb1Green;
        color: $xb1Green;

        &:focus, &.active {
          background-color: $xb1Green;
        }
      }

      &.psn {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 2;
        border-color: $ps4Blue;
        color: $ps4Blue;

        &:focus, &.active {
          background-color: $ps4Blue;
        }
      }

      &.switch {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 2;
        grid-column-end: 3;
        border-color: $pcRed;
        color: $pcRed;

        &:focus, &.active {
          background-color: $pcRed;
        }
      }

      &:focus, &.active {
        color: white;
      }
    }
  }

  #body {
    max-width: 1140px;
    margin: 0 auto 50px;

    .patchNavTitle {
      margin-top: 40px;
      padding-top: 10px;
      padding-left: 10px;
      background: rgba(0, 0, 0, 0.06);
      font-size: 24px;
    }

    .patchNav {
      columns: 3;
      -moz-columns: 3;
      -webkit-columns: 3;
      width: 100%;
      margin: 0;
      padding: 1rem;
      background: rgba(0, 0, 0, 0.06);
      list-style: inside;

        @include breakpoint-mobile-only {
            columns: 2;
            -moz-columns: 2;
            -webkit-columns: 2;
            list-style-type: none;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 16px;
        }

      li {
        margin: 0 0 5px 0;

          @include breakpoint-mobile-only {
              margin: 0 0 8px 0;
              position: relative;
              flex-basis: 45%;

              &:first-child {
                  width: 100%;
                  flex-basis: 100%;
              }
              .tablet-up {
                  display: none;
              }
          }
          @include breakpoint-tablet-up {
              .mobile {
                  display: none;
              }
          }

        a {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .description {
      font-size: 24px;
      margin: 10px 0 0 0;
    }

    .release {
      font-size: 24px;
      margin: 10px 0 0 0;
    }

    .forum-link {
      //TODO style this nicely
      margin: 10px 0 0 0;
    }

    @media screen and (max-width: 1440px) {
      padding: 0 5%;
    }

    .patch-content {
      margin-top: 50px;

      &::before {
        display: block;
        content: " ";
        margin-top: -100px;
        height: 100px;
        visibility: hidden;
        pointer-events: none;
      }
    }

    h2 {
      font-size: 28px;
      margin-top: 50px;
    }

    h3 {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    hr {
      margin: 20px 0;
      border-top: 1px solid darkgray;
    }

    strong {
      font-weight: bold;
    }

    p {
      margin: 5px 0;
      line-height: 1.3em;
    }

    img {
      max-width: 100%;
      object-fit: scale-down;
      vertical-align: middle;
      height: auto;
      margin: 15px 0 10px 0;
    }

    video, iframe {
      max-width: 100%;
    }

    ul {
      list-style: disc;
      margin: 1rem;
    }

    li {
      margin: 5px 0;
    }

    table {
      table-layout: fixed;
      width: 100%;
      border-collapse: initial;

      td {
        border-bottom: 1px solid #E1E1E1;
      }
    }
  }

  @include mq(mobile) {
    #header {
      #list-container {
        width: 100%;
        border-radius: 0 !important;
        top: 5px;

        #update-title {
          width: 100%;
          height: 5vh;
          border-radius: 0 !important;
        }

        .scrollContain {
          max-height: 55vh;
        }

        .listItem {
          padding: 10px 0 5px 5px !important;
        }

        .subListItem {
          padding: 10px 0 10px 20px !important;
        }
      }

      .foreground {
        width: inherit !important;
        object-fit: cover !important;
        object-position: center top !important;
        box-shadow: none !important;
      }

      .background {
        display: none;
      }

      #carousel-container {
        #keyart {
          display: none;
        }

        .carousel {
          height: 60vh;
          width: inherit;
          box-shadow: none;

          .imgContainer {
            position: relative;
            height: inherit;
            background: black;

            img {
              width: inherit;
              height: inherit;
              object-fit: cover;
            }

            .gradient {
              display: none;
            }
          }
        }
      }
    }
  }
}

