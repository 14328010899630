// Community page root at warframe.com/community
// todo: migrate to theme sheet when page is updated
#community{
    min-width: 360px;
    h2{
        color: #FFFFFF;
    }
    #forums_slide{
        background-image: url('/images/community/CommunityPage_V3.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center -9px;
        text-align: center;
        overflow: hidden;
        background-color: #92abb2;
        height: 87%;
        position: relative;
        display: table;


    }
    #resources_slide{
        background: url('/images/community/resources_slide.jpg');

        .gameBox{
            background: url('/images/mainSiteAssets/fansitesPage/gameBox.png') center center no-repeat;
            width: 656px;
            height: 576px;
            margin-left: -210px;
            margin-right: 40px;
            background-size: contain;
        }

        h2,p{
            color: black;
        }
        button{
            display: inline-block;
            margin-left: 3px !important;
            &.black{
                background: black;
            }
        }

    }

    #referral_slide{
        background: url('/images/community/referral_slide.png') center center no-repeat;

        h2,p{
            color: white;
            text-align: left !important;
        }

        h2{
            margin-top: 0px !important;
            margin-bottom: 20px !important;
        }
    }

    #support_slide{
        background: white;
        //background: url('/images/community/support_slide.jpg') center center no-repeat;
        max-height: 264px !important;
        //filter:brightness(140%);

        .container{
            padding-top: 70px !important;
        }

        h2,p{
            color: black;
            text-align: center !important;
        }

        h2{
            margin-top: 0px !important;
            margin-bottom: 20px !important;
        }

        button{
            float: none !important;
        }
    }

    #social_slide{
        .contentHeaderContain h2{
            color: $pcRed;
        }

        .stream li {
            margin: 6px;
            width: calc(24.95% - 12px) !important;
            box-shadow: none;
        }
    }

    .whitebacker{
        padding: 20px;
        background-color: rgba(255,255,255,0.7);
        color: white;
    }

    #forums_slide, #referral_slide{
        background-attachment: fixed;
    }

    section:not(#social_slide){
        background-size: cover !important;
        height: 100%;
        max-height: 826px;
        padding-bottom: 15%;

        .container{
            color: #fff;
            position: relative;
            text-align: center;
            padding-top: 10%;

            h2{
                margin: 0 auto;
                margin-top: 50px;
                margin-bottom: 40px;
                font-size: $titleSize;
                text-align: left;
                text-transform: uppercase;
            }

            h3{
                //font-family: 'Open Sans', sans-serif !important;
                text-transform: uppercase;
                //letter-spacing: 0.2em;
                font-size: 27px;
                margin-bottom: 34px;
            }

            p{
                font-size: 16px;
                line-height: 27px;
                font-weight: 300;
                margin-bottom: 18px;
                letter-spacing: 0.04em;
                text-align: left;
            }

            button{
                float: left;
                margin-left: 25px;
            }
        }
        .topContain{
            padding-top: 12%;
        }
    }

    .rightText{
        float: right;
        margin-top: 5%;
    }

    a.anchor {
        display: block;
        position: relative;
        top: -($mainHeaderHeight + $storeHeaderHeight);
        visibility: hidden;
    }

    @media (max-width: 1200px) {
        .stream li .icon{
            height: 20px !important;
            bottom: 3px !important;
        }

        .stream li.dcsns-youtube .section-intro, .filter .f-youtube a:hover, .filter .f-youtube a.iso-active, .stream li.dcsns-facebook .section-intro, .filter .f-facebook a:hover, .filter .f-facebook a.iso-active{
            padding-left: 30px;
            padding-right: 5px;
            a{
                margin-left: 0px;
            }
        }
    }

    @include mq(tablet) {

        section:not(#social_slide) {
            background-position-y: 0 !important;
        }

        #forums_slide {
            background-position: center 0;

            .keyartCopy h1{
                font-size: 32px;
            }
        }

        #resources_slide, #referral_slide{
            .container{
                p,h2{
                    text-align: center;
                }
                h2{
                    font-size: 32px;
                }
            }
        }

        #resources_slide{
            .gameBox{
                display: none;
            }

            a button.black{
                margin: 0 auto !important;
                position: relative;
                width: 300px;
            }

            .container{
                position: relative;
                background: rgba(255,255,255,0.7) !important;
                width: 100%;
                height: 100%;
                padding-top: 1%;

                .columns {
                    float: none;
                    width: 90%;
                    margin: auto;
                }
            }

            .container button{
                margin-left: -15px;
            }
        }

        #referral_slide{
            background-position-y: 200px !important;

            > .container{
                position: relative;
            }
        }

        #support_slide{
            .container{
                background: rgba(255,255,255,0.7) !important;
            }

            h2{
                font-size: 30px !important;
            }

            p{
                font-size: 13px !important;
            }
        }

        .rightText{
            float: none;
        }

        .containWrap{
            margin-bottom: 0;
        }
    }

    @include mq(mobile) {
        #forums_slide{
            position: relative;
            padding-top: 0;
            display: block;
        }

        #referral_slide{

            > .container {
                position: relative;
                padding-top: 40px;
                padding-bottom: 30px;
            }
        }

        #support_slide{
            .container{
                padding-top: 50px !important;
            }
        }

        #social_slide{
            display: none;
        }

        #resources_slide .gameBox {
            display: none;
        }

        section:not(#social_slide){
            background-attachment: inherit;
            background-position-y: 0 !important;

            .container {
                background: rgba(0,0,0,0.7);
                width: 100%;
                height: 100%;
                padding: 15px;
                padding-top: 16%;

                p,h2{
                    text-align: center !important;
                }

                h2{
                    font-size: 25px;
                }

                button{
                    float:none;
                }
            }
        }
    }
}

@media (max-width: 825px){
    #forums_slide{
        padding-bottom: 0px !important;

        .topContain{
            padding-top: 0px !important;
            padding-bottom: 5% !important;
        }
    }

    #resources_slide{
        padding-bottom: 0px !important;
        .container{
            padding-top: 1% !important;
            padding-bottom: 5% !important;
        }
    }

    #referral_slide{
        padding-bottom: 0px !important;
        .container {
            padding-bottom: 5% !important;
        }
    }

    #support_slide{
        padding-bottom: 0px !important;
        p {
            font-weight: bold !important;
        }

        .container {
            padding-bottom: 5% !important;
        }
    }

}
.lang-fr {
    #community {
        button {
            height: 60px !important;
            max-width: 300px !important;

            .button-wrap {
                white-space: pre-line;
                line-height: 21px;
            }
        }
    }
}
