// Warframe companion app page
// todo: migrate to theme sheet when page is updated
body.mobileapp-page{
  background-color: #fff;
  color: #000;
  width: 100%;

  .wrapper{
    min-width: 320px !important;
  }

  .section1{
    height: 80vh;
    overflow: hidden;
    max-height: 570px;
    min-height: 528px;
    @media screen and (min-width: 37.5em){
      max-height: 740px;
    }
    @media screen and (min-width: 48em){
      max-height: 545px;
    }
    @media screen and (min-width: 56em){
      max-height: 665px;
      min-height: 595px;
    }
    @media screen and (min-width: 64em){
      min-height: 640px;
    }
    @media screen and (min-width: 107.5em){
      max-height: 905px;
    }
    @media screen and (min-width: 120em){
      min-height: 825px;
    }
    @media screen and (min-width: 125em){
      max-height: 1110px;
    }
    @media screen and (min-width: 160em){
      max-height: 1320px;
      min-height: 1015px;
    }
    @media screen and (min-width: 240em){
      max-height: 1765px;
    }
  }

  .appTitleIcon{
    @media screen and (min-width: 48em){
      width: 420px;
      margin: 55px 0px 0px 55px;
      position:absolute;
    }
    @media screen and (min-width: 54.6875em){
      margin: 150px 0px 0px 155px;
    }
    @media screen and (min-width: 77em){
      margin: 150px 0px 0px 240px;
    }
    @media screen and (min-width: 90em){
      margin: 150px 0px 0px 330px;
    }
    @media screen and (min-width: 120em){
      margin: 150px 0px 0px 400px;
      width: 540px;
    }
    @media screen and (min-width: 140em){
      margin: 195px 0px 0px 500px;
      width: 1000px;
    }
    @media screen and (min-width: 160em){
      margin: 195px 0px 0px 700px;
    }
    @media screen and (min-width: 240em){
      margin: 315px 0px 0px 1075px;
      width: 1300px;
    }
    @media screen and (max-width: 320px){
      margin-top:25px;
    }
  }

  .appTitle{
    width: 100%;
      @media screen and (min-width: 48em){
        float: right;
        width: 75%;
        margin-top: 25px;
      }
      @media screen and (min-width: 64em){
        width: 72%;
        margin-top: 31px;
      }
      @media screen and (min-width: 120em){
        width: 74%;
        margin-top: 35px;
      }
      @media screen and (min-width: 140em){
        width: 80%;
        margin-top: 42px;
      }
      @media screen and (min-width: 240em){
        width: 80%;
        margin-top: 50px;
      }
        p{
          text-align: center;
              @media screen and (min-width: 26.5625em){
                font-size: 16px;
              }
              @media screen and (min-width: 48em){
                text-align: left;
                font-size: 14px;
                width: 185px;
              }
              @media screen and (min-width: 64em){
                font-size: 18px;
                width: 260px;
              }
              @media screen and (min-width: 120em){
                font-size: 22px;
              }
              @media screen and (min-width: 140em){
                font-size: 28px;
                width: 394px;
              }
              @media screen and (min-width: 240em){
                font-size: 38px;
                width: 500px;
              }
        }
  }

  h2{
    text-align: center;
    padding-bottom: 5px;
    font-size: 25px;
        @media screen and (min-width: 26.5625em){
          font-size: 29px;
        }
        @media screen and (min-width: 48em){
          text-align: left;
          font-size: 25px;
        }
        @media screen and (min-width: 64em){
          font-size: 25px;
        }
        @media screen and (min-width: 120em){
          font-size: 32px;
        }
        @media screen and (min-width: 140em){
          font-size: 54px;
        }
        @media screen and (min-width: 240em){
          font-size: 74px;
        }
  }

  .icon{
    display: none;
    @media screen and (min-width: 48em){
      display: block;
      width: 100px;
      float: left;
    }
    @media screen and (min-width: 64em){
      width: 115px;
    }
    @media screen and (min-width: 120em){
      width: 140px;
    }
    @media screen and (min-width: 140em){
      width: 200px;
    }
    @media screen and (min-width: 240em){
      width: 260px;
    }
  }

  .phone1{
    height: 70%;
    margin-left: 12%;
    padding-top: 15px;
        @media screen and (min-width: 23.4375em){
          margin-left: 18%;
        }
        @media screen and (min-width: 26.5625em){
          margin-left: 21%;
        }
        @media screen and (min-width: 37.28125em){
          margin-left: 28%;
        }
        @media screen and (min-width: 48em){
          float: right;
          height: auto;
          width: 560px;
          margin: 29px -195px 0px 0px;
        }
        @media screen and (min-width: 54.6875em){
          margin: 90px -200px 0px 0px;
          width: 630px;
        }
        @media screen and (min-width: 64em){
          margin: 90px -200px 0px 0px;
          width: 700px;
        }
        @media screen and (min-width: 77em){
          margin: 90px -100px 0px 0px;
        }
        @media screen and (min-width: 90em){
          margin: 90px 0px 0px 0px;
        }
        @media screen and (min-width: 120em){
          width: 950px;
        }
        @media screen and (min-width: 140em){
          width: 1200px;
          margin: 90px -265px 0px 0px;
        }
        @media screen and (min-width: 240em){
          width: 1985px;
          margin: 90px -315px 0px 0px;
        }
  }

  .btns
  {
    text-align: center;
      @media screen and (min-width: 48em){
        height: auto;
        width: auto;
        display: inline;
        margin: 155px 0px 0px 157px;
        position: absolute;
      }
      @media screen and (min-width: 54.6875em){
        margin: 250px 0px 0px 256px;
      }
      @media screen and (min-width: 64em){
        margin: 260px 0px 0px 269px;
      }
      @media screen and (min-width: 77em){
        margin: 260px 0px 0px 350px;
      }
      @media screen and (min-width: 90em){
        margin: 260px 0px 0px 444px;
      }
      @media screen and (min-width: 120em){
        margin: 285px 0px 0px 536px;
      }
      @media screen and (min-width: 140em){
        margin: 366px 0px 0px 700px;
      }
      @media screen and (min-width: 160em){
        margin: 405px 0px 0px 898px;
      }
      @media screen and (min-width: 240em){
        margin: 550px 0px 0px 1326px;
      }
  }

  .appleBtn{
    height: 40px;
    margin-right: 3px;
        @media screen and (min-width: 23.4375em){
          height: 50px;
        }
        @media screen and (min-width: 26.5625em){
          height: 55px;
        }
        @media screen and (min-width: 48em){
          height: auto;
          width: 100px;
        }
        @media screen and (min-width: 64em){
          width: 110px;
        }
        @media screen and (min-width: 120em){
          width: 140px;
        }
        @media screen and (min-width: 140em){
          width: 238px;
        }
        @media screen and (min-width: 240em){
          width: 345px;
        }
  }

  .googleBtn{
    height: 40px;
    margin-left: 3px;
        @media screen and (min-width: 23.4375em){
          height: 50px;
        }
        @media screen and (min-width: 26.5625em){
          height: 55px;
        }
        @media screen and (min-width: 48em){
          height: auto;
          width: 110px;
        }
        @media screen and (min-width: 64em){
          width: 125px;
        }
        @media screen and (min-width: 120em){
          width: 157px;
        }
        @media screen and (min-width: 140em){
          width: 267px;
        }
        @media screen and (min-width: 240em){
          width: 390px;
        }
  }









  .appSection{
    padding-top: 25px;
    width: 100%;
    height: 90vh;
    overflow: hidden;
    @media screen and (min-width: 37.5em){
      min-height: 455px;
    }
    @media screen and (min-width: 48em){
      min-height: 700px;
    }
    @media screen and (min-width: 64em){
      max-height: 835px;
    }
    @media screen and (min-width: 77em){
      max-height: 745px;
    }
    @media screen and (min-width: 90em){
      max-height: initial;
    }
    @media screen and (min-width: 160em){
      max-height: 1285px;
    }
    @media screen and (min-width: 240em){
      max-height: 1715px;
    }
  }

  .drones{
    background: url('../images/../../images/mobileapp/bg-drones.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 90vh;
    @media screen and (min-width: 48em){
      clip-path: polygon(0% 4%, 100% 0%, 100% 96%, 0 100%);
      -webkit-clip-path: polygon(0% 4%, 100% 0%, 100% 96%, 0 100%);
    }
    @media screen and (min-width: 90em){
      clip-path: polygon(0% 6%, 100% 0%, 100% 94%, 0 100%);
      -webkit-clip-path: polygon(0% 6%, 100% 0%, 100% 94%, 0 100%);
    }
  }

    .appContent {
        .info {
            color: #fff;
            @media screen and (min-width: 48em) {
                width: 47%;
                margin-top: 24vh;
            }
            @media screen and (min-width: 64em) {
                width: 42%;
            }
            @media screen and (min-width: 77em) {
                width: 34%;
            }
            @media screen and (min-width: 240em) {
                width: 30%;
            }
        }
    }

  h1{
    color: #fff;
    border: 1px solid #fff;
    text-align: center;
    font-size: 24px;
    margin-bottom: 15px;
    margin: 0px 10px 15px 10px;
    padding: 5px;
      @media screen and (min-width: 26.5625em){
        margin: 0px 35px 15px 35px;
        padding: 7px
      }
      @media screen and (min-width: 37.25em){
        margin: 0px 100px 15px 100px;
        padding: 8px
      }
      @media screen and (min-width: 48em){
        margin: 0;
      }
      @media screen and (min-width: 107.5em){
        font-size: 33px;
      }
      @media screen and (min-width: 125em){
        font-size: 36px;
      }
      @media screen and (min-width: 240em){
        font-size: 46px;
      }
  }

  .infoDesc{
    text-align: center;
    color: #fff;
    line-height: 21px;
    margin: 0px 15px 0px 15px;
      @media screen and (min-width: 26.5625em){
        margin: 0px 35px 15px 35px;
        padding: 8px;
      }
      @media screen and (min-width: 37.25em){
        margin: 0px 100px 0px 100px;
      }
      @media screen and (min-width: 48em){
        margin: 0;
        text-align: left;
      }
      @media screen and (min-width: 107.5em){
        font-size: 16px;
        line-height: 28px;
      }
      @media screen and (min-width: 125em){
        font-size: 18px;
        line-height: 32px;
      }
      @media screen and (min-width: 240em){
        font-size: 24px;
        line-height: 36px;
      }
  }

  .phone{
    width: 100%;
    margin-top: 10px;
      @media screen and (min-width: 37.25em){
        width: 50%;
        margin-left: 25%;
      }
      @media screen and (min-width: 48em){
        width: 35%;
        margin-top: 16vh;
        display: none;
      }
      @media screen and (min-width: 64em){
        width: 25%;
        margin-top: 12vh;
      }
      @media screen and (min-width: 77em){
        width: 20%;
      }
      @media screen and (min-width: 90em){
        margin-top: 9vh;
        width: 20%;
      }
      @media screen and (min-width: 125em){
        margin-top: 6vh;
      }
  }

  .phoneImage{
    @media screen and (min-width: 48em){
      display: none !important;
    }
  }
  .phoneVideo{
    display: none;
    @media screen and (min-width: 48em){
      display: inline;
    }
  }

  .left{
    @media screen and (min-width: 48em){
      margin-left: 9%;
    }
    @media screen and (min-width: 64em){
      margin-left: 16%;
    }
    @media screen and (min-width: 77em){
      margin-left: 23%;
    }
    @media screen and (min-width: 125em){
      margin-left: 22%;
    }
  }

  .infoRight, .right
  {
    @media screen and (min-width: 48em){
      float: right;
      margin-right: 9%;
    }
    @media screen and (min-width: 64em){
      margin-right: 16%;
    }
    @media screen and (min-width: 77em){
      margin-right: 23%;
    }
    @media screen and (min-width: 240em){
      margin-right: 24%;
    }
  }

  .right{
    @media screen and (min-width: 48em){
      margin-left: 0;
    }
  }






  .infoDescLeft{
    color: #000;
  }

  .infoLeft{
    @media screen and (min-width: 48em){
      float: left;
      margin-left: 9%;
    }
    @media screen and (min-width: 64em){
      margin-left: 16%;
    }
    @media screen and (min-width: 77em){
      margin-left: 23%;
    }
    @media screen and (min-width: 240em){
      margin-left: 22%;
    }
    h1{
      color: #484848;
      border: 1px solid #484848;
    }
  }







  .companion{
    background: url('../images/../../images/mobileapp/bg-companion.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 90vh;
    @media screen and (min-width: 48em){
      clip-path: polygon(0% 4%, 100% 0%, 100% 96%, 0 100%);
      -webkit-clip-path: polygon(0% 4%, 100% 0%, 100% 96%, 0 100%);
    }
    @media screen and (min-width: 90em){
      clip-path: polygon(0% 6%, 100% 0%, 100% 94%, 0 100%);
      -webkit-clip-path: polygon(0% 6%, 100% 0%, 100% 94%, 0 100%);
    }
  }






  .sectionLast{
    height: 100%;
    padding-top: 20px;
    overflow: hidden;
    @media screen and (min-width: 48em){
      height: 500px;
    }
    @media screen and (min-width: 64em){
      height: 560px;
    }
    @media screen and (min-width: 90em){
      height: 620px;
    }
    @media screen and (min-width: 107.5em){
      height: 670px;
    }
    @media screen and (min-width: 125em){
      height: 835px;
    }
    @media screen and (min-width: 142.5em){
      height: 925px;
    }
    @media screen and (min-width: 160em){
      height: 1000px;
    }
    @media screen and (min-width: 240em){
      height: 1475px;
    }
  }

  .iconLast{
    display: inline;
    text-align: center;
    width: 46%;
    margin-left: 27%;
    @media screen and (min-width: 37.5em){
      width: 30%;
      margin-left: 35%;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    @media screen and (min-width: 48em){
      margin-left: 0;
      display: block;
      width: 103px;
      float: left;
      margin-top: 0;
      margin-bottom: 0;
    }
    @media screen and (min-width: 120em){
      width: 140px;
    }
    @media screen and (min-width: 142.5em){
      width: 195px;
    }
    @media screen and (min-width: 240em){
      width: 250px;
    }
  }

  .button{
    padding: 0;
    margin-left: 7%;
    width: 86%;
    font-size: 11px;

    @media screen and (min-width: 26.5625em){
      width: 75%;
      margin-left: 13%;
      font-size: 12px;
    }
    @media screen and (min-width: 37.5em){
      width: 50%;
      margin-left: 27%;
      font-size: 14px;
    }
    @media screen and (min-width: 48em){
      width: 45%;
      margin-left: 75px;
      margin-top: 157px;
      position: absolute;
    }
    @media screen and (min-width: 56em){
      margin-left: 183px;
      margin-top: 170px;
    }
    @media screen and (min-width: 64em){
      width: 38%;
      margin-left: 220px;
      margin-top: 182px;
    }
    @media screen and (min-width: 77em){
      width: 28%;
    }
    @media screen and (min-width: 107.5em){
      width: 24%;
    }
    @media screen and (min-width: 120em){
      width: 25%;
      margin-top: 275px;
      margin-left: 550px;
      height: 54px !important;
      font-size: 19px;
    }
    @media screen and (min-width: 142.5em){
      width: 25%;
      margin-top: 350px;
      margin-left: 550px;
      height: 60px !important;
      font-size: 25px;
    }
    @media screen and (min-width: 240em){
      width: 21%;
      margin-top: 485px;
      margin-left: 1045px;
      height: 95px !important;
      font-size: 40px;
    }
  }

  .phoneBottom{
    display: none;
    @media screen and (min-width: 48em){
      display: block;
      position: relative;
      width: 100%;
      margin-left: 24%;
      margin-top: 20%;
      z-index: -5;
    }
    @media screen and (min-width: 64em){
      margin-left: 16%;
      margin-top: 11%;
    }
    @media screen and (min-width: 77em){
      margin-left: 25%;
      margin-top: 5%;
      width: 80%;
    }
  }

  .features{
    padding: 0px 20px 30px 20px;
    @media screen and (min-width: 48em){
      padding: 0;
      position: absolute;
      width: 40%;
      margin-top: 23%;
      margin-left: 8%;
    }
    @media screen and (min-width: 64em){
      margin-top: 19%;
      margin-left: 13%;
    }
    @media screen and (min-width: 77em){
      margin-top: 16%;
      margin-left: 8%;
    }
    @media screen and (min-width: 90em){
      margin-top: 14%;
    }
    @media screen and (min-width: 107.2em){
      margin-top: 11%;
      margin-left: 7%;
      width: 30%;
    }
    @media screen and (min-width: 120em){
      margin-left: 24%;
      margin-top: 16%;
    }
    @media screen and (min-width: 142.5em){
      margin-left: 20%;
      margin-top: 15%;
    }
    h4{
      color: #484848;
      font-weight: bold;
      font-size: 20px;
      letter-spacing: initial;
      text-align: center;
      margin-top: 40px;
      @media screen and (min-width: 37.5em){
        font-size: 24px;
      }
      @media screen and (min-width: 48em){
        font-size: 18px;
      }
      @media screen and (min-width: 90em){
        font-size: 20px;
      }
      @media screen and (min-width: 125em){
        font-size: 26px;
      }
      @media screen and (min-width: 160em){
        font-size: 30px;
      }
      @media screen and (min-width: 240em){
        font-size: 42px;
      }
    }
  }

  .list{
    padding: 10px 5px 0px 25px;
    width: 100%;
    line-height: 20px;
    @media screen and (min-width: 26.5625em){
      padding: 10px 5px 0px 70px;
    }
    @media screen and (min-width: 48em){
      font-size: 12px;
      line-height: 17px;
    }
    @media screen and (min-width: 142.5em){
      font-size: 16px;
      line-height: 21px;
    }
    @media screen and (min-width: 240em){
      font-size: 25px;
      line-height: 38px;
    }
    li{
      list-style: initial;
    }
  }

  .listLeft{

    @media screen and (min-width: 37.5em){
      padding: 10px 5px 0px 150px;
    }
    @media screen and (min-width: 48em){
      float: left;
      padding: 10px 5px 0px 25px;
      width: 40%;
    }
  }

  .listRight{
    padding-top: 0;
    @media screen and (min-width: 37.5em){
      padding: 0px 5px 0px 150px;
    }
    @media screen and (min-width: 48em){
      float: right;
      width: 40%;
      padding: 10px 5px 0px 25px;
    }
  }

  .skew{
    @media screen and (min-width: 48em){
      transform: rotate(3deg) skew(3deg);
      -webkit-transform: rotate(3deg) skew(3deg);
    }
    @media screen and (min-width: 90em){
      transform: rotate(2deg) skew(2deg);
      -webkit-transform: rotate(2deg) skew(2deg);
    }
  }

  .titleLast{
    @media screen and (min-width: 56em){
      margin: 70px 0px 0px 155px;
    }
    @media screen and (min-width: 120em){
      margin: 140px 0px 0px 500px;
    }
    @media screen and (min-width: 240em){
      margin: 230px 0px 0px 1000px;
    }
  }
}
