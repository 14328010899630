@import "../utils/_mixins";
@import "../utils/_variables";
@import "../style-guide/_variables";
@import "../style-guide/_responsive";

$gold: #e4bc53;

body.prime_access {
  background: #000;
  overflow-x: hidden;

  .container {
    max-width: 1225px !important;
  }

  @media (max-width: 1250px) and (min-width: 875px) {
    overflow-x: auto;

    .container {
      width: 90%;
      min-width: 835px;
    }
  }

  .goldText {
    color: $gold;
  }

  #bgvid,
  #vidFallback {
    @include fullscreen-video-background;
  }

  #bgvid {
    min-width: 1920px;
  }

  #primeAccessBottom {
    position: absolute;
    z-index: -1;
    background-image: url('/images/prime-access/generic-back.jpg') !important;
    bottom: -914px;
    width: 2500px;
    height: 1197px;
    left: -218px;
  }

  #mobilePAHeader {
    display: none;
  }

  #mainContent {
    margin-bottom: 110px;
  }

  #primeaccess {
    position: relative;

    .affBooster {
      top: -15px;
      left: 84px;
    }

    .resourceBooster {
      top: -15px;
      left: 84px;
    }

    .credBooster {
      left: 81px;
      top: -14px;
    }

    .modBooster {
      left: 81px;
      top: -14px;
    }

    .avatars,
    .weapons {
      .itemTitlePurchased {
        width: 100% !important;
      }
    }

    .plat1050,
    .plat1365,
    .plat1575,
    .plat2625,
    .plat2940,
    .plat3990 {
      margin-top: -11px;
    }

    #primeHeader {
      background-repeat: no-repeat;
      z-index: 10;
      position: relative;

      @include breakpoint-mobile-only {
        top: -70px;
      }

      .logoContent {
        float: left; // ADJUSTMENT: float either left or right depending on background
        position: relative;
        margin: 50px 0;
        z-index: 11;

        @include mq(steambrowser) {
          width: 100%;
        }

        #primeLogo {
          top: -10px;
          position: relative;
          margin-bottom: 29px;

          @include mq(steambrowser) {
            margin-left: auto;
            margin-right: auto;
            display: block;
          }
        }

        #logoText {
          //font-family: Arial, sans-serif;
          margin-top: -16px;
          font-size: 13px;
          line-height: 16px;
          text-align: left;
          z-index: 4;
          color: #fff;
          padding: 15px;
          background-color: rgba(0, 0, 0, 0.7);

          a {
            color: white;
            text-decoration: underline;

            &:hover {
              color: white;
            }
          }

          .subtitle {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 18px;
            //font-family: $futureFont;
            line-height: 20px;
            margin-bottom: 20px;
            color: $gold;
            //word-spacing: 5px;

            span {
              font-size: 30px;
              //letter-spacing: -1px;
              display: inline-block;
              margin-bottom: 20px;
              line-height: 31px;
              margin-top: -6px;
              //font-family: $futureFont;
              color: #fff;
            }
          }

          p {
            margin-bottom: 10px;
            font-size: 17px;
            line-height: 19px;

            @include mq(tablet) {
              color: white;
            }
          }

          .moreFaq {
            color: white;
            text-decoration: underline;
          }
        }
      }

      #viewTrailer {
        background-image: url('/images/prime-access/viewTrailer.png');
        width: 228px;
        height: 47px;
        cursor: pointer;
      }
    }

    .purchased {
      //@include filter(grayscale(100%) brightness(0.8));
      filter: grayscale(100%) brightness(0.8);
    }

    .open {
      .Icon {
        transform: rotate(180deg);
      }
    }

    #packageContainer {
      position: relative;
      z-index: 1;
      margin: 0 auto;
      min-width: 1120px;

      @include mq(steambrowser) {
        min-width: auto !important;
      }

      .package {
        min-height: 400px;
        z-index: 2;
        float: left;
        position: relative;
        min-width: 255px;

        @include mq(notTabletOrMobile) {
          width: 30.6666666667%;

          &.columns:not(:first-child) {
            margin-left: 4%;
          }
        }

        .item,
        .title {
          border: 1px solid $gold;
        }

        .title {
          text-transform: uppercase;
          //font-family: "Ubuntu", sans-serif;
          font-size: 30px;
          font-weight: bold;
          text-align: center;
          border: none;
          position: relative;
          z-index: 10;
          height: 64px;
          border-left: 1px solid $gold;

          .titleContain {
            border-top: 1px solid $gold;
            clip-path: polygon(0% 0%, 86% 0, 100% 100%, 100% 100%, 0% 100%);
            width: 100%;
            height: 63px;
            min-width: 246px;
            line-height: 58px;

            @include mq(tablet) {
              clip-path: polygon(0% 0%, 85% 0, 100% 100%, 100% 100%, 0% 100%);
            }

            @include mq(tablet) {
              clip-path: none;
            }

            .slantBorder {
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: $gold;
            }

            .slantBackground {
              position: absolute;
              width: 100%;
              height: 100%;
              clip-path: polygon(0% 0%, 86% 0, 100% 100%, 100% 100%, 0% 100%);

              @include mq(tablet) {
                clip-path: polygon(0% 0%, 85% 0, 100% 100%, 100% 100%, 0% 100%);
              }

              @include mq(tablet) {
                clip-path: none;
              }
            }

            .titleImg {
              color: $gold;
              position: relative;
              text-transform: uppercase;
              //font-family: $futureFont;
              font-size: 22px;
              left: -7px;
              line-height: 1em;
              text-align: center;
              width: 80%;
              margin: 0 auto;
              display: inline-block;
              vertical-align: middle;
              padding-left: 20px;
              //word-spacing: 6px;

              .Icon {
                // Below sets color to #e4bc53
                filter: invert(94%) sepia(39%) saturate(3914%) hue-rotate(315deg) brightness(94%) contrast(90%);
                float: right;
              }

              @include breakpoint-tablet-up {
                padding-left: 0;

                .Icon {
                  display: none;
                }
              }
            }
          }

          &.package0 .titleContain {
            background-color: #016084;
            border-bottom: #094860 5px solid;

            .slantBackground {
              background-color: #016084;
            }

            .titleImg {
              font-size: 20px;
              left: 0px;
            }
          }

          &.package1 .titleContain {
            background-color: #073A4E;
            border-bottom: #052D3C 5px solid;

            .slantBackground {
              background-color: #073A4E;
            }
          }

          &.package2 .titleContain {
            background-color: #042633;
            border-bottom: #04151D 5px solid;

            .slantBackground {
              background-color: #042633;
            }
          }

          .titleRight {
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;
          }
        }

        .itemTitle {
          background-repeat: no-repeat;
          padding-left: 6px;
          padding-top: 9px;
          position: relative;
          text-align: center;
          font-size: 12px;
          text-transform: uppercase;
          color: #949494;
          line-height: 17px;

          &.small {
            height: 39px;
          }

          &.large {
            height: 32px;
          }
        }

        .empty {
          position: relative;
          top: 65px;
          margin: 0 auto;

          .emptyRect {
            position: absolute;
            width: 55px;
            height: 18px;
            background-color: $gold;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
          }
        }

        .plusSign {
          position: absolute;
          z-index: 9;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          top: -22px;
        }

        .item {
          position: relative;
          top: -2px;
          min-height: 136px !important;
          width: 100%;

          &.plat {
            border-top: none;
            height: 136px;

            .itemTitle {
              top: 30px;
              left: 55px;
            }

            .platWord {
              position: relative;
              text-transform: uppercase;
              font-weight: bold;
              top: 94px;
              text-align: center;
              font-size: 18px;
              //@include text-shadow(0px 0px 10px rgba(0, 0, 0, 1));
              text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
              color: #fff;
              //font-family: $futureFont;
            }
          }

          &.weapons {
            z-index: 10;
            height: 202px;

            .itemTitlePurchased {
              padding-top: 30%;
            }

          }

          &.boosters {
            height: 105px;

            .itemTitle {
              height: 60px;
            }
          }

          &.drone {
            height: 135px;

            .itemTitle {
              height: 90px;
            }

            .itemTitlePurchased {
              padding-top: 30%;
            }
          }

          &.avatars {
            height: 100px;
          }

          &.acc {
            .paTitle {
              margin-left: auto;
              margin-right: auto;
              left: 0;
              right: 0;
              position: absolute;
            }

            .itemTitlePurchased {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
            }
          }

          .itemTitle,
          .itemTitlePurchased {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            //background-image: url('/images/prime-access/11overlay.png');
            //background-repeat: repeat !important;
            padding-top: 20%;
            display: none;
            color: #fff;
            z-index: 100;

            @include mq(tablet) {
              padding-top: 15.5%;
            }
          }

          .itemTitlePurchased {
            display: block;
            text-align: center;
            font-weight: bold;
            font-size: 14px;
            text-transform: uppercase;
          }

          .platImg,
          .pistolMeleeImg,
          .primeImg,
          .avaImg,
          .pistolMeleePrimeImg {
            display: flex;
            justify-content: center;
            position: absolute;
            margin-top: -2px; // ADJUSTMENT: Remove this if there is a gap between Warframe image and box border in packs
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
          }

          .platImg {
            .platAmount {
              position: absolute;
            }
          }
        }

        .avaImg {
          margin: 0 auto;
          top: 30px;
        }

        .boughtBtn {
          padding-top: 32px;
          text-align: center;
          background-repeat: no-repeat;
          font-size: 25px;
          text-transform: uppercase;
          font-weight: bold;
          color: #674915;
          height: 107px;
        }

        .priceBtn,
        .boughtBtn {
          background-color: $gold;
          width: 100% !important;
          clip-path: polygon(0% 0%, 100% 0, 100% 61%, 87% 100%, 0% 100%);

          @include mq(tablet) {
            clip-path: none;
          }
        }

        .priceBtn {
          position: relative;
          top: -2px;
          width: 264px;
          height: 107px;
          text-align: center;
          padding-top: 8px;
          font-weight: bold;
          cursor: pointer;
          color: #674915;
          //font-family: "Ubuntu", sans-serif;

          &.currency-usd,
          &.currency-cad,
          &.currency-gbp {
            //font-family: "Michroma", "Ubuntu", sans-serif;
          }

          &.currency-twd {

            .sign,
            .priceLarge {
              font-size: 45px;
            }

            .priceSmall {
              font-size: 23px;
            }
          }

          .sign,
          .priceLarge {
            font-size: 55px;
          }

          .priceSmall {
            position: relative;
            font-size: 33px;
            top: -16px;
            right: 4px;
          }

          .value {
            text-transform: uppercase;
            font-size: 16px;
            margin-top: 4px;

            &.mobile {
              display: none;
            }
          }

          .currency {
            margin-left: -38px;
            font-weight: bold;
            font-size: 14px;
          }

          &.hover {
            .buyNow {
              font-size: 40px;
              text-transform: uppercase;
              //@include text-shadow(none);
              text-shadow: none;
              padding-top: 3px;
              margin-bottom: 12px;
            }
          }
        }

        .cutCorner {
          clip-path: polygon(0% 0%, 100% 0, 100% 61%, 87% 100%, 0% 100%);

          @include mq(tablet) {
            clip-path: none;
          }
        }

        &.package0 {
          .item {
            background-color: rgb(78, 144, 169);
            background-color: rgba(78, 144, 169, 0.8);

            &.plat {

              .itemTitle {
                left: 58px;
              }
            }
          }
        }

        &.package1 {

          .item {
            background-color: rgb(7, 58, 78);
            background-color: rgba(7, 58, 78, 0.8);
          }
        }

        &.package2 {
          margin-right: 0;

          &:has(div):has(.open) {
            margin-bottom: 25px;
          }

          .item {
            background-color: rgb(4, 38, 51);
            background-color: rgba(4, 38, 51, 0.8);
          }

          .avatars {
            .itemTitle {
              padding-bottom: 20px;
            }
          }
        }
      }

      #primeAccessories {
        margin-top: 100px;
        position: relative;
        min-width: 1038px;

        @include mq(steambrowser) {
          min-width: auto !important;
        }

        .primeaccesszephyr-bonus.mobile,
        .itemTitle.mobile {
          display: none;
        }

        &.purchased {
          opacity: 0.9;

          .addPrice .buyNow {
            padding: 0;
          }
        }

        .paPackTitleEx {
          //font-family: $futureFont;
          position: relative;
          z-index: 4;
          text-transform: uppercase;
          color: $gold;
          padding: 11px 11px 11px 31px;
          font-size: 15px;
          font-weight: bold;
          background-image: url("/images/prime-access/pa-generic-btns/paPackTitleEx.png");
          background-repeat: no-repeat;

          @include mq(tablet) {
            background-image: none;
            font-size: 22px;
          }

          .Icon {
            // Below sets color to #e4bc53
            filter: invert(94%) sepia(39%) saturate(3914%) hue-rotate(315deg) brightness(94%) contrast(90%);
            float: right;
          }

          @include breakpoint-tablet-up {
            .Icon {
              display: none;
            }
          }
        }

        .accLogo {
          margin: 0 auto;
          display: block;
        }

        .accTrim {
          position: absolute;
          bottom: 73px;
          left: -3px;
        }

        .accItem {
          float: left;
          position: relative;
          background-color: #2d4955;
          width: 20%;
          height: 150px;
          margin-left: 0;
          margin-top: 0;
          border-left: 1px solid $gold;
          border-top: 1px solid $gold;
          border-bottom: 1px solid $gold;

          &.itemCount5 {
            width: 16.6%;

            .accLogo {
              //margin-left: 6px;
            }
          }

          &.itemCount6 {
            width: 14.27%;

            .accLogo {
              margin: auto;
              // width: 70%;
            }
          }

          &.narrow {
            width: 16.6%;

            .accLogo {
              //margin-left: 6px;
            }
          }

          &.bonusItem {
            .bonusText {
              text-align: center;
              height: 18%;
              font-weight: bold;
              font-size: 1.2em;
              padding: 4px;
              color: $gold;
              position: absolute;
              bottom: 24px;
              left: 50%;
              transform: translateX(-50%);
            }

            .itemTitle {
              white-space: nowrap;
              width: 100%;
              //letter-spacing: 1px;
              left: 0;
            }
          }

          &.leftItem {
            margin-top: -5px;
            z-index: -1;
            margin-left: -97px;
          }

          .itemImg {
            position: relative;
            z-index: 2;

            &.accessory {
              img {
                // For Xaku Prime
                max-width: 140px;
                -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 1) 40%);
              }
            }

            &.booster {
              bottom: 75px;

              img {
                transform: translateY(50%);
              }
            }

            &.pa-generic-btns-affBooster {
              background-image: url('/images/prime-access/pa-generic-btns/affBooster.png');
              width: 100%;
              height: 100%;
              background-repeat: no-repeat;
              background-position: center;
            }

            &.pa-generic-btns-resourceBooster {
              background-image: url('/images/prime-access/pa-generic-btns/resourceBooster.png');
              width: 100%;
              height: 100%;
              background-repeat: no-repeat;
              background-position: center;

            }
          }

          .itemDesc {
            position: absolute;
            top: -24px;
            left: -6px;
            z-index: 9;
            text-align: center;
            width: 217px;
            height: 141px;
            display: none;

            div {
              width: 140px;
              position: absolute;
              top: 43px;
              left: 47px;
              line-height: 16px;
            }
          }

          .itemTitle {
            text-align: center;
            font-size: 13px;
            text-transform: uppercase;
            z-index: 9;
            line-height: 15px;
            //letter-spacing: 2px;
            color: #fff;
            width: 78%;
            margin: 0 auto;
            bottom: 10px;
            left: 20px;
            position: absolute;

            @include mq(steambrowser) {
              font-size: 12px;
            }

            &.longTitle {
              width: 96%;
              left: 5px;
            }
          }
        }

        .accPrice {
          background-color: $gold;
          display: inline-block;
          padding: 7px 10px 10px;
          position: relative;
          top: -3px;
          height: 55px;
          cursor: pointer;

          @include mq(tablet) {
            height: auto;
          }

          &:hover {
            background-color: $gold;
          }

          &.purchased {
            cursor: auto;

            .buyNow {
              border-left: none;
              width: 174px;

              .text {
                font-size: 27px;
              }
            }
          }

          .accPriceEnd {
            position: absolute;
            right: -55px;
            top: 0;
            border-top: 55px solid $gold;
            border-right: 55px solid transparent;
          }

          .actualPrice,
          .buyNow {
            text-transform: uppercase;
            color: #674915;
            font-weight: bold;
            float: left;
          }

          .actualPrice {
            //font-family: "Ubuntu", sans-serif;
            border-right: 1px solid #9f7d31;

            &.currency-cad,
            &.currency-usd,
            &.currency-gbp {
              //font-family: "Michroma", "Ubuntu", sans-serif;
            }

            .value {
              text-align: center;
              font-size: 11px;
              position: relative;
              top: 0;
            }

            .listedPrice {
              padding: 0 10px;
              margin-top: -7px;
              margin-bottom: 2px;

              .bigNum,
              .numRight {
                float: left;
              }

              .bigNum {
                font-size: 25px;
                margin-top: 8px;
              }

              .numRight {
                margin-left: 1px;

                .smallNum {
                  font-size: 14px;
                  position: relative;
                  top: 2px;
                  margin-bottom: 1px;
                  margin-top: 7px;
                }

                .currency {
                  font-size: 10px;
                }
              }
            }
          }

          .buyNow {
            text-align: center;
            padding-left: 20px;
            border-left: 1px solid #d9c45a;
            width: auto;

            .text {
              font-size: 33px;
              margin-top: 7px;
              line-height: 26px;
              margin-left: -2px;

              @include mq(tablet) {
                font-size: 25px;
              }
            }
          }
        }

        .accItem {
          &.accTitle {
            padding: 22px 0;
          }

          .itemImg {
            z-index: 4;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -2px;
          }

          .affBooster,
          .credBooster,
          .modBooster,
          .resourceBooster {
            top: 22px;
          }

          &.bonusItem {

            .affBooster,
            .credBooster,
            .modBooster,
            .resourceBooster {
              top: 3px
            }
          }
        }

        .accPrice {
          top: 0;
        }

        @include mq(tablet) {
          min-width: inherit !important;
          width: 100% !important;

          .accItem {
            width: 100% !important;

            .itemTitle {
              width: 100% !important;
            }
          }
        }

        .lastAccessory {
          border-right: 1px solid $gold;
        }

        .grid {
          //outline: 1px solid red;
          display: grid;
          grid-template-columns: repeat(5, minmax(0, 1fr));
          grid-template-rows: repeat(2, min-content);

          @include mq(tablet) {
            display: flex;
            flex-direction: column;
          }

          .accItem {
            float: none;
            width: auto;


            &:nth-child(1n + 6) {
              border-top: none;

              .itemImg {
                top: 4px;
                bottom: auto;

                img {
                  max-width: 118px;
                }
              }
            }

            .itemImg {
              @include mq(tablet) {
                top: 0;
                bottom: 0;
              }

              img {
                @include mq(tablet) {}
              }
            }

            .bonusText {}

            .itemTitle {}

          }

          .accTitle {
            grid-row: 1/3;
            grid-column: 1/2;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .accItem[ data-item-type="booster"] {
            //outline: 3px solid lime;
          }

          .bonusItem {
            .itemTitle {
              width: inherit;
              margin: 10px;
              right: 0;
              bottom: 0;
              left: 0;
              white-space: normal;

              &::after {
                content: "*";
              }
            }

            .bonusText {
              display: none;
            }
          }
        }

        .supplemental {
          text-align: center;
          margin-top: 40px;

          @include mq(tablet) {
            margin-top: 20px;
          }

          p {
            display: inline-block;
            color: white;
            padding: 10px 20px;
            background-color: rgba(black, 0.5);
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .primeDisclaimer {
    margin-top: 60px;
    line-height: 20px;
    color: white;
    position: relative;
    z-index: 10;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);

    a {
      color: $gold;

      &:hover {
        color: white;
        text-decoration: underline;
      }
    }
  }

  @include mq(tablet) {
    background: none;

    #mobilePAHeader {
      display: block;
      width: 100%;
      min-height: 343px;
      background-size: 100% auto;

      &.mobileHeader {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;

        //@include breakpoint-tablet-up {
        //    background-size: auto 100%;
        //}
      }
    }

    #primeAccessBottom {
      display: none;
    }

    #bgvid,
    #vidFallback {
      display: none;
    }

    #primeaccess #primeHeader {
      .logoContent {
        width: 100%;

        #primeLogo {
          width: 100%;
        }

        #logoText {
          color: #fff;

          .subtitle span {
            color: #fff;
          }
        }
      }
    }

    #primeaccess #packageContainer {
      min-width: inherit;

      .package {
        min-width: inherit;
        min-height: 190px;
        height: auto !important;
        width: 100%;
        margin-left: 0;

        @include breakpoint-tablet-up {
          max-height: 800px;
          overflow: hidden;
          height: 190px;
          margin-bottom: 20px;
        }

        .blackMeOut.showMe {
          display: none;
        }

        .title {
          border-right: 1px solid $gold;

          .titleContain {
            width: 100%;

            .titleImg {
              left: inherit;
            }
          }

          .titleRight {
            display: none;
          }
        }

        .value {
          display: none !important;

          &.mobile {
            display: block !important;
            font-size: 22px;
          }
        }

        .item,
        .item.plat {
          @include breakpoint-mobile-only {
            display: none;
          }
        }
      }

      #primeAccessories {
        min-width: inherit;
        margin-top: 0;

        .accBorderFix {
          display: none;
        }

        .accItem {
          width: 100%;
          height: 167px;
          background-image: none;
          float: none;
          background-color: rgb(4, 38, 51);
          background-color: rgba(4, 38, 51, 0.8);
          margin-left: 0 !important;
          position: relative;
          overflow: hidden;

          @include breakpoint-mobile-only {
            display: none;
          }

          &.paPackTitle {
            display: none;
          }

          &.accTitle {
            display: none;
          }

          .itemImg {
            position: absolute;
            top: inherit;

            &.credBooster,
            &.affBooster,
            &.modBooster,
            &.boosters,
            &.resourceBooster {
              top: 25px;
            }
          }

          .itemTitle {
            position: absolute;
            width: 100%;
            bottom: 20px;
            top: initial !important;
            left: initial !important;
            margin-left: 0 !important;

            &.airCharges {
              width: 100%;
            }
          }
        }

        .accPrice {
          width: 100%;

          .buyNow {
            width: 65%;

            @include mq(tablet) {
              width: 58%;
            }
          }

          .accPriceEnd {
            display: none;
          }
        }

        .accOverlay {
          display: none;
        }
      }
    }

    .paPackTitleEx {
      background-image: none;
      text-align: center;
      width: 100%;
      background-color: #042633;
      border-top: 1px solid $gold;
      border-left: 1px solid $gold;
      border-right: 1px solid $gold;
      height: 50px;
      font-size: 20px;
      line-height: 25px;
    }

    #colorbox {
      #cboxContent {
        .paymentMethods {
          .method {
            margin-top: 20px;
          }

          img {
            display: none;
          }
        }
      }
    }

    .primeDisclaimer {
      color: white;
    }
  }

  &.lang-de,
  &.lang-es,
  &.lang-pt-br,
  &.lang-it {
    .paPackTitleEx {
      font-size: 11px !important;
    }
  }

  @media (max-width: 1600px) {
    #bgvid {
      width: 1920px;
      min-height: 100%;
    }
  }

  @include breakpoint-mobile-only {
    #primeaccess {
      #packageContainer {
        .package {
          margin-bottom: 20px;

          .item .itemTitle {
            display: flex !important;
            flex-direction: column-reverse;
            padding: 0;
            background-color: rgba(0, 0, 0, 0);
          }
        }
      }
    }
  }

  @include mq(steambrowser) {

    .pistolMeleeImg,
    .pistolMeleePrimeImg,
    #primeAccessories .itemImg {
      //transform: scale(0.7) translateX(-15%);
    }
  }
}