body.new_payment_thank_you{
    #bgvid,#vidFallback{
        @include fullscreen-video-background;
    }

    #bgvid {
        min-width: 1920px;
    }

    #purchaseThankYou{
        position: relative;
        z-index: 2;
        text-align: center;
        background-color: rgba(7, 58, 78, 0.7);
        border: solid 2px $gold;
        overflow: hidden;
        text-shadow: none;
        width: 40%;
        margin: 15% auto;

        @include mq(tablet){
            width:95%;
        }

        .title{
            color:$gold;
            //font-family: 'michroma', open-sans, sans-serif;
            line-height: 38px;
            width: 100%;
            padding: 11px 0;
            background-color: #073a4e;
            border-bottom: solid 10px #052d3c;
            text-transform: uppercase;
            font-size: 31px;
        }

        p{
            color:#fff;
            padding:30px 40px;
            font-size: 19px;
            line-height: 26px;
        }
    }
}
