body.signup{
    #bgvid,#vidFallback{
        @include fullscreen-video-background;
    }
    #bgoverlay {
        content: "";
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.8);
        z-index: 2;
    }

    .content{
        padding:20px;
    }

    #submit-btn{
        margin-top: 15px;
    }

    .signupContent{
        position: relative;
        margin-top: 15px;
        h1{
            margin-bottom: 20px;
        }
        @include platform-selector;

        .contentHeaderContain{
            margin-top:20px;
            margin-bottom: 20px;
            .contentHeaderLines{
                width: 22%;
                border-color:#fff;
                height: 9px;

                @include mq(tablet){
                    display: none;
                }
            }
            .contentHeaderText{
                text-align: center;
                position: relative;
                top: -12px;
                font-size: 21px;
                padding: 0 21%;
            }
        }
    }

    #mainText {
        margin: 10rem 0 12rem;
        position: relative;
        z-index: 10;

        @include mq(hd){
            margin-top: 20%;
        }

        .text-content{
            text-align: center;
            color:#fff;
            padding-bottom: 20px;
            margin-bottom: 25px;

            .title{
                font-size: 42px;
                padding: 30px;
                text-shadow: 0px 0px 40px #fff;
                //font-family: $notoSansFont;

                @include mq(mobile){
                    img{
                        width:100%;
                    }
                }
            }

            form{
                .form-text{
                    background: none;
                    border-left: none;
                    border-right: none;
                    border-top: none;
                    color: #fff;

                    &::placeholder{
                        color: #fff;
                    }

                    &.error {
                      border-color: #FF2323 !important;
                    }

                    &:-webkit-autofill {
                        background: none;
                        color: #000;
                    }
                }

                label.error{
                    font-weight: bold;
                    color: #FF2323;
                    text-align: left;
                    margin-top: -5px;
                }

                fieldset{
                    &#opt-in-newsletter,&#agree,&#contest,&#read-rules{
                        label{
                            text-align: left;
                            margin-top: 3px;
                            position: relative;
                            left: 10px;
                            margin-bottom: 15px;
                            line-height: 17px;

                            a{
                                color:#fff;
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .recaptcha-check{
                    display: inline-block;
                    margin: 20px auto;
                }
            }

            p{
                width: 65%;
                margin: 0 auto;
                font-size: 20px;
                margin-bottom: 25px;
                line-height: 25px;
            }

            a:hover{
                color:#fff;
            }
        }
    }
}

.aliasOptions {
  .aliasOptionTitle {
    color: $pcRed;
  }
  p {
    margin-top: 5px;
  }
  .aliasOption {
    font-style: italic;
  }
}

//Contest Box
.contest-box {
    border: white 1px solid;
    border-radius: 5px;
    //background-color: rgba(53, 78, 75, 0.61);
    background-image: url("/images/promo/space-contest/overlay-bg_70per.png");
    margin: 0 0%;
    padding: 11px;

    .contest-title {
        font-weight: bold;
        font-size: 1.1em;
        margin: 5px 0 10px 0;
    }
    .fancy {
        line-height: 0.5;
        text-align: center;
    }
    .fancy span {
        display: inline-block;
        position: relative;
    }
    .fancy span:before,
    .fancy span:after {
        content: "";
        position: absolute;
        height: 3px;
        border-bottom: 1px solid white;
        top: 0;
        width: 100%;
        @include mq(mobile) {
            width: 25%;
        }
    }
    .fancy span:before {
        right: 100%;
        margin-right: 15px;
    }
    .fancy span:after {
        left: 100%;
        margin-left: 15px;
    }

    @include mq(tablet) {
        margin: 0 0;
    }

    @media (max-width: 320px) {
        border: white 1px solid;

        .contest-title {
            font-size: 18px;
        }
        #read-rules {
            width: 100%;

            label {
                font-size: 11px;
            }
        }
    }
}

@media (max-width: 1050px) {
    form [data-tip]{

        // Remove tip box itself as well as the pointer triangle 
        &::before,
        &::after{
            display: none !important; 
        }
    }
}