// Creators program pages
// todo: migrate to theme sheet when page is updated
body.creators {

    #creatorsHead {
        .logo {
            max-width: 120px;
            min-width: auto;
            width: 40%;
            margin: 0 auto;
            display: block;
        }

        .fansitesSlide {
            background: url('/images/community/creator_header.jpg');
            padding-top: 15px;
            height: 344px;
        }

        .partnertext {
            margin: 0 auto !important;
            margin-top: 20px !important;
            margin-bottom: 20px !important;
            line-height: 23px !important;
            color: #FFFFFF !important;
            padding: 0px !important;
            font-size: 14px !important;
            text-align: center;
        }

        .partnerDisclaimer {
            text-align: center;
            margin-top: 20px;
            font-size: 12px;
        }

        .headerTextContain {
            padding-top: 25px;
            max-width: 600px;
            text-align: center;
            margin: 0 auto;

            h1 {
                color: #FFFFFF;
            }

            p {
                font-size: 15px;
                margin-top: 15px;
            }
        }

        .headerContain {
            max-width: 990px;
            margin: 0 auto;
        }

        .slider, .cd-hero-slider {
            height: 349px;
        }

        .creatorsHeader {
            text-align: center;
            margin-bottom: 20px;

            h1 {
                margin-bottom: 20px;
            }

            hr {
                margin-top: 20px;
                margin-bottom: 20px;
                border-color: #b9b9b9;
            }
        }
    }

    .navContain {
        margin-bottom: 30px;

        .navTable {
            display: table;
            margin: 12px auto 0 auto;
        }

        @media (max-width: 605px) {
            .navTable {
                width: 35%;
            }
        }

        .mobilePartnerNav {
            display: none;
            clear: both;
        }

        .navBtn {
            padding: 15px 10px 10px 10px;
            margin: 10px;
            color: #000000;
            cursor: pointer;
            float: left;
            min-width: 175px;
            text-transform: uppercase;
            font-size: 18px;
            border: 1px solid #000000;
            text-align: center;

            .navIcon {
                height: 24px;
                width: 24px;
                display: inline-block;
                background-size: 100%;
                background-repeat: no-repeat;
            }

            &.watchNow .navIcon {
                background-image: url('/images/community/creators_icons/watch-now.png');
            }

            &.creators .navIcon {
                background-image: url('/images/community/creators_icons/creators.png');
            }

            &.apply .navIcon {
                background-image: url('/images/community/creators_icons/apply-now.png');
            }


            &.active, &:hover {
                background-color: $pcRed;
                color: #fff;
                border-color: $pcRed;

                &.watchNow .navIcon {
                    background-image: url('/images/community/creators_icons/watch-now_active.png');
                }

                &.creators .navIcon {
                    background-image: url('/images/community/creators_icons/creators_active.png');
                }

                &.apply .navIcon {
                    background-image: url('/images/community/creators_icons/apply-now_active.png');
                }
            }

            p {
                display: inline-block;
                position: relative;
                top: -6px;
                margin-left: 5px;
            }
        }
    }

    #creatorList {
        .miniHeader {
            text-align: center;
            margin-bottom: 50px;

            h2 {
                margin-bottom: 20px;
            }

            p {
                line-height: 20px;
            }
        }

        .creatorFilters {
            background-color: #ffffff;
            width: 100%;
            position: relative;
            padding: 12px 10px 0 0;
            font-size: 21px;
            color: #000000;
            margin-bottom: 50px;

            .icon {
                width: 32px;
                height: 32px;
                background-repeat: no-repeat;
                background-size: 100%;
                background-image: url('/images/community/creators_icons/filter.png');
                float: left;
                margin-left: 20px;

                @include mq(tablet) {
                    float: none;
                    margin: 0 auto;
                }
            }

            .filterList {
                float: left;
                margin: 6px 0 0 25px;
                width: 90%;
                text-align: center;

                @include mq(tablet) {
                    float: none;
                }

                .filter {
                    display: inline-block;
                    margin: 0 5% 0 0;

                    input[type="checkbox"], label {
                        display: inline-block;
                    }

                    input[type="checkbox"] {
                        margin-right: 10px;
                    }
                }
            }
        }

        .creatorGrid {
            .c {
                position: relative;
                width: 128px;
                height: 240px;
                margin-bottom: 15px;

                .name {
                    color: $pcRed;
                    text-align: center;
                    width: 100%;
                    margin-bottom: 5px;
                    overflow-wrap: break-word;
                }

                .avatar {
                    position: relative;
                    width: 128px;
                    height: 128px;
                    background-size: 100%;
                }

                .platformLinks {
                    text-align: center;
                    margin-top: 8px;

                    a {
                        width: 35px;
                        height: 35px;
                        display: inline-block;
                        background-size: 100%;

                        &.twitchIcon {
                            background-image: url('../images/icons/twitch.png');
                        }

                        &.ytIcon {
                            background-image: url('../images/icons/yt.png');
                        }

                        &.instaIcon {
                            background-image: url('../images/icons/insta.png');
                        }

                        &.twitterIcon {
                            background-image: url('../images/icons/twitter.png');
                        }

                        &.daIcon {
                            background-image: url('../images/icons/da.jpg');
                        }

                        &.tumblrIcon {
                            background-image: url('../images/icons/tumblr.png');
                        }

                        &.artstationIcon {
                            background-image: url('../images/icons/artstation.png');
                        }

                        &.siteIcon {
                            background-image: url('../images/icons/link.jpg');
                        }
                    }
                }
            }
        }
    }

    .creatorRegistration {
        margin: 0 auto;
        width: 80%;

        hr {
            margin-top: 20px;
            margin-bottom: 20px;
            border-color: #b9b9b9;
        }

        .creatorForm, .creatorCriteria {
            float: left;
            width: 50%;
        }

        .creatorCriteria, .creatorForm, .creatorsHeader {
            padding: 20px;
        }

        h3, h6, ul {
            margin-bottom: 10px;
        }

        h4 {
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 20px;
            text-transform: uppercase;
            letter-spacing: 0px;
        }

        h6 {
            text-decoration: underline;
            font-weight: bold;
        }

        ul {
            list-style: disc;

            li {
                margin-left: 20px;
                margin-bottom: 5px;
            }
        }

        p, li {
            line-height: 20px;
        }

        p {
            margin-bottom: 15px;
        }

        form fieldset .form-text {
            margin-top: 5px !important;
        }

        fieldset {
            line-height: 20px;
        }

        button {
            margin-top: 15px;
        }

        .button-wrap {
            color: #FFFFFF !important;
            border: none;
        }

        .creatorsHeader {
            width: 50%;
            margin: 0 auto;
            text-align: center;
            padding-top: 0px;

            h1, h2 {
                font-size: 28px;
                //font-family: "Open Sans", Helvetica, sans-serif;
            }

            h1 {
                margin-bottom: 20px;
            }
        }

        @media (max-width: 1750px) {
            .creatorsHeader {
                width: 80%;
            }
        }

        @media (max-width: 768px) {
            .creatorCriteria, .creatorForm, .creatorsHeader {
                width: 100%;
            }
            .creatorCriteria {
                padding-top: 0px;
            }
        }
    }

    .gridContainer {
        width: 100%;
        margin-bottom: 1.5%;
        padding-bottom: 34.6%;
        position: relative;
        float: left;
        background-color: #ececec;
        color: #FCFFF5;

        @include mq(tablet) {
            padding-bottom: 0;
        }
    }

    //@media (max-width:750px) {
    //    .gridContainer {
    //        width: 100%
    //    }
    //}

    #creatorWatchNow {
        .miniHeader {
            text-align: center;
            margin-bottom: 50px;

            h2 {
                margin-bottom: 20px;
            }

            p {
                line-height: 20px;
            }
        }
    }

    .item-body {
        display: flex;
        flex-direction: row;

        @include mq(tablet) {
            flex-direction: column;
        }
    }

    .youtubeEmbed {
        margin-right: 1.5%;

        .owl-carousel {
            width: 60%;
            position: absolute;
            margin: 0 auto;
            @include mq(tablet) {
                width: 100%;
            }

            .owl-video-tn {
                height: 100%;
                width: 100%;
                position: absolute;
                background-size: cover;
                background-position-y: 49.5%;
                right: 0.5px;
                box-shadow: 0px -75px 100px 0px #000000 inset
            }

            .owl-item {
                .owl-video-wrapper {
                    background: none;

                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                .owl-video-wrapper::after {
                    padding-top: 56.25%;
                    display: block;
                    content: '';
                }


                &.owl-video-playing {
                    .owl-video-wrapper {
                        padding-bottom: 0;
                    }

                    .youtubeOverlay {
                        display: none;
                    }

                    .youtubePagination {
                        display: none;
                    }
                }
            }

            .owl-nav {

                .owl-prev {
                    background: url('../images/../images/community/creators_icons/arrow-left.png') no-repeat;
                    width: 50px;
                    height: 100px;
                    position: absolute;
                    left: 1.5%;
                    top: 42.5%;
                    transform: skew(0deg);
                }

                .owl-next {
                    background: url('../images/../images/community/creators_icons/arrow-right.png') no-repeat;
                    width: 50px;
                    height: 100px;
                    position: absolute;
                    right: 0%;
                    top: 42.5%;
                    transform: skew(0deg);
                }

                @media (max-width: 850px) {
                    .owl-prev, .owl-next {
                        transform: scale(0.8);
                        top: 35%;
                    }
                }

                @media (max-width: 600px) {
                    .owl-prev, .owl-next {
                        transform: scale(0.8);
                        top: 25%;
                    }
                }
            }

            .owl-video-play-icon {
                background: url('../images/../images/community/creators_icons/play-button.png') no-repeat;
                opacity: 0.8;
                height: 100px;
                width: 75px;
                transform: scale(1.4, 1.3);

                &:hover {
                    transform: scale(1.5, 1.4);
                }
            }

            @media (max-width: 850px) {
                .owl-video-play-icon {
                    transform: scale(1.1);
                    top: 47%;

                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
        }

        .owl-video-frame {
            position: relative;
            height: 100%;
            width: 100%;
            padding-top: 0px;

            iframe {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
            }
        }

        .youtubeOverlay {
            width: 80%;
            z-index: 10;
            position: absolute;
            bottom: 17px;
            left: 17px;


            .youtubeTitle {
                color: #ffffff;
                //font-family: 'Open Sans', sans-serif;
                text-transform: capitalize;
                font-size: 25px;
                //letter-spacing: 0.15px;
                font-weight: 600
            }

            .youtubeChannelName {
                color: #ffffff;
                //font-family: 'Open Sans', sans-serif;
                text-transform: capitalize;
                font-size: 22px;
                //letter-spacing: 0.15px;
                margin-bottom: 1%;
            }

            @media (max-width: 1150px) {
                .youtubeTitle {
                    font-size: 19px;
                }
                .youtubeChannelName {
                    font-size: 16px;
                }
            }
        }

        .youtubePagination {
            color: #ffffff;
            font-family: 'Open-sans', sans-serif;
            font-size: 22px;
            font-weight: 600;
            letter-spacing: 0.25px;
            position: absolute;
            right: 17px;
            bottom: 17px;
        }

        @media (max-width: 1150px) {
            .youtubePagination {
                font-size: 19px;
            }
        }

        .column {
            height: 100%;
            width: 39%;
            position: absolute;
            right: 0;
            display: flex;
            flex-direction: column;


            @include mq(tablet) {
                width: 100%;
                margin-top: 60%;
                height: 500px;
                position: relative;
                left: 0;
                margin-left: 0;
            }
        }
    }

    .artEmbed {
        background-color: #000000;
        width: 100%;
        flex: 4;
        //width: 32.5%;
        //padding-bottom: 27.6%;

        .artFontPosition {
            padding: 20px;
            bottom: 0%;
            z-index: 5;
        }

        h1 {
            color: #ffffff;
            //font-family: 'Open Sans', sans-serif;
            text-transform: capitalize;
            font-size: 1.4rem;
            //letter-spacing: 0.25px;
        }

        h2 {
            color: #ffffff;
            //font-family: 'Open Sans', sans-serif;
            text-transform: capitalize;
            font-size: 2.2rem;
            //letter-spacing: 0.25px;
        }

        @media (max-width: 800px) {
            h1 {
                font-size: 1.1rem;
            }

            h2 {
                font-size: 1.8rem;
            }
        }
    }

    //@media (max-width: 950px) {
    //    .artEmbed {
    //        margin-top: 20%;
    //    }
    //}

    /*@media (max-width: 750px) {
        .artEmbed {
            margin: 21% 0 4% 0;
            width: 100%;
            padding-bottom: 80%;
        }
    }*/

    .podcastEmbed {
        background-color: #000000;
        margin: 10px 0;
        width: 100%;
        flex: 1;

        //padding-bottom: 45%;
        //background-color: #ffffff;
        //width: 32.5%;
        //padding-bottom: 8%;

        //.podcastThumbnail {
        //    position:absolute;
        //    align-content: center;
        //}

        .podcastIcon {
            filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(165deg) brightness(102%) contrast(100%);
            height: 20px;
            width: 13px;
            position: relative;
            float: left;
            margin-right: 5.5px;
        }

        .podcastText {
            padding: 20px;
            z-index: 5;
            width: 100%;
            //position: absolute;
            //margin: 13px 13px;
        }

        h1 {
            // color: #000000;
            color: #FFFFFF;
            //font-family: 'Open Sans', sans-serif;
            text-transform: uppercase;
            font-size: 20px;
            //letter-spacing: 0.25px;
            font-weight: 900;
        }

        .marquee {
            //color: #000000;
            color: #FFFFFF;
            //font-family: 'Open Sans', sans-serif;
            text-transform: uppercase;
            font-size: 18px;
            //letter-spacing: 0.25px;
            margin-top: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    /*@media (max-width: 750px) {
        .podcastEmbed {
            margin: 0 0 4% 0;
            width: 100%;
            padding-bottom: 80%;
            //margin-bottom: 4%;
            //width: 100%;
            //padding-bottom: 18%;
        }
    }*/

    .podcastContainer {
        display:flex;
    }

    .noStreamer {
        color: #d30000;
        //font-family: 'Open Sans', sans-serif;
        text-transform: uppercase;
        text-align: center;
        font-size: 30px;
    }

    .twitchContainer {
        padding: 0 3%;
        display: flex;
        justify-content: center;
    }

    #creatorTwitch {
        background-color: #ffffff;
        position: relative;
        padding-top: 20px;
        padding-bottom: 30px;
        margin-bottom: 50px;

        .miniHeader h3 {
            text-align: center;
            margin-bottom: 20px;
        }

        .streamerName {
            text-transform: uppercase;
            color: #000000;
            font-weight: bold;
            margin-top: 10px;
            color: #000000;
            text-align: left;
        }

        .streamerNum2 {
            font-weight: bold;
            color: #FFFFFF;
            background-color: #d30000;
            padding: 5.5px 20px;
            left: 24px;
            position: absolute;
            z-index: 5;
        }

        .streamerNum {
            font-weight: bold;
            color: #FFFFFF;
            background: #000000 url('../images/../images/community/creators_icons/viewers.png?1599599796') no-repeat 10.5px 6px;
            padding: 5px 15px 5px 30px;
            right: 0;
            bottom: 24px;
            position: absolute;
            z-index: 5;
        }

        .streamingIcon {
            position: absolute;
            z-index: 5;
            left: 0;
            top: 0px;
            height: 24px;
            width: 24px;
            background-color: #6441A4
        }

        .carouselContain {
            padding: 0 3%;
        }

        .owl-dots {
            margin-top: 10px;
            margin-bottom: -35px;
        }

        .owl-dot span {
            transform: skew(25deg);
        }

        .owl-dot active span {
            transform: skew(25deg);
        }
    }

    #partnerBlurb {
        .miniHeader {
            p {
                //font-family: 'Open Sans', sans-serif;
                text-align: center;
                margin-bottom: 50px;
            }
        }
    }
}
