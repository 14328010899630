#loginPage{
    .consoleLoginBtns{
        display: grid;
        grid-template-columns: repeat(2, 50%);

        a{
            height: 45px;
            padding-top: 8px;
            width: 98%;
            max-width: 800px;
            margin: 2px 2%;
            img {
                max-height: 27px;
            }

            &.psBtn, &.ps4Btn{
                background-color: #274368;
            }
            &.xb1Btn{
                background-color: #2f5212;
            }
            &.swiBtn{
                background-color: #e70012;
            }
            &.iosBtn {
                background-color: #000000;
            }
            &.androidBtn {

            }
            @include mq(tablet){
                margin-bottom: 5px;
                width: 100%;
            }
        }
    }
    .contentHeaderContain p{
        color:#ffffff;
    }
}

body.new_login,#loginPage {
    @include fullscreen-content-page;
    #bgoverlay {
        content: "";
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.8);
        z-index: 2;
    }

    .mainContent {
        color: #ffffff;
    }

    &.robotCheck {
        color: black;
    }

    @include mq(mobile) {
        .wrapper{
            background-image: inherit;

            .contentHeaderContain.console .contentHeaderLines{
                display: none;
            }

            .contentHeaderLines{
                width: 30% !important;
            }

            #submit-login [type="submit"]{
                width: 120%;
            }
        }
    }

    #mainText .text-content p{
        margin-bottom: 55px;
        line-height: 0px;

        @include mq(mobile){
            line-height: 22px;
        }
    }

    form#loginForm{
        width: 75%;
        margin: 0 auto;

        label.error {
            font-weight: bold;
            color: #FF2323;
        }

        input[type="text"],input[type="email"],input[type="password"]{
            color:#ffffff;
        }


        a {
            color: white;
            &:hover {
                color: #d30000;
            }
        }

        .form-text{
            background: none;
            border-left: none;
            border-right: none;
            border-top: none;

            &::placeholder{
                color:#fff;
            }

            &.error {
                border-color: #FF2323 !important;
            }
        }

        @include mq(mobile){
            width:85%;
        }
    }
}

#btnLogin.uses-recaptcha{
    @include breakpoint-mobile-only {
        padding: 0;
        width:100% !important;
    }
}