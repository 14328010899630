// Amazon Prime account linking
// todo: migrate to theme sheet when page is updated
@import "../utils/_mixins";
@import "../utils/_variables";

$btnColor: rgb(241, 240, 255);
$btnHover: rgb(255, 255, 255);
$hoverBlue: rgb(27, 185, 238);
$purple: rgb(145, 70, 255);
$white: #ffffff;

body.amazon, body.amazon_link, body.amazon_success {
    background-color: white;
    .wrapper {
        background: url('../images/../../images/promo/amazon/full-page_background.jpg') !important;
        background-repeat: no-repeat !important;
        background-position: center -10px !important;
        background-size: cover !important;

        @include mq(tablet) {
            background-position: unset !important;
            background-position-x: 40% !important;
            background-size: unset !important;
        }
    }

    #basicTextContain {
        background: none !important;
    }

    #amazon-link {
        position: relative;
        width: 100%;
        min-width: 435px;
        //left: 50%;
        top: 60%;
        margin: 40px 0;
        padding: 20px 40px;
        text-align: center;
        background: $purple;
        border-radius: 5px;

        @media (max-height: 800px) {
            //top: 0;
            //margin: 25px 0;
        }

        @include mq(tablet) {
            position: relative;
            width: 100%;
            left: unset;
            top: unset;
            padding: 20px 15px;
            min-width: unset;
        }

        #amazon-logo {
            display: block;
            margin: 100px auto 0 auto;
        }

        .amazon-button {
            margin: 0 auto;
            display: table;
        }

        img {
            margin: 5px 0 0 0;
            @include mq(tablet) {
                width: 80%;
            }
        }

        h2 {
            margin: 20px 0;
            letter-spacing: 6px;
            font-size: 15pt;
            color: white;
            text-align: center;
            line-height: 1.5em;
            @include mq(tablet) {
                font-size: 12pt;
            }
        }

        strong {
            color: white;
            margin: 40px 0 !important;
        }

        p {
            color: white;
            position: relative;
            margin: 3px auto !important;
            font-size: 16px;

            @include mq(tablet) {
            }

            a {
                color: white;
                text-decoration: underline;

                &:hover {
                    opacity: 80%;
                    color: white;
                }
            }
        }

        button, .button {
            display: table;
            margin: 30px auto 5px auto;
            background-color: $btnColor;
            height: auto;
            transform: none !important;
            @include mq(tablet) {
                padding: 0 10px;
            }

            img {
                vertical-align: middle;
                margin-top: -5px;
            }

            .button-wrap {
                color: rgb(66, 20, 94) !important;
                padding: 3px 0;
                transform: none !important;
            }

            &:hover {
                background-color: $btnHover;
            }
        }
    }
}
