// warframe.com/game - Game guide pages root
// todo: migrate to theme sheet when page is updated
$factionDesktop: (
    grineer:-104px,
    corpus:0,
    infested:-207px,
    sentient:-311px,
);

$factionMobile: (
    grineer:-72px,
    corpus:0,
    infested:-145px,
    sentient:-217px,
);

body.game{
    overflow-x: hidden;

    .seven.columns{
        width:48.666667%
    }

    h2{
        color: #FFFFFF;
        margin-bottom: 20px !important;
    }

    #keyart{
        background-image: url('/images/mainSiteAssets/gamePage/GamePage_Download_V3.jpg');
        background-position: center;
        text-align: center;
        overflow: hidden;
        background-color: #92abb2;
        height: 87%;
        position: relative;
        display: table;

        .container{
            padding-top: 11%;
        }

        h3,p{
            color: #000;
        }

        h2,p{
            text-align: center;
        }

        h2{
            color: $pcRed;
            margin-top: -50px !important;
        }
        h3{
            font-size: 16px !important;
        }
        a{
            display: inline-block;
        }
    }
    #quickstart{
        background: url('/images/mainSiteAssets/gamePage/quickstartBG.jpg') center center;
      display: inline-block !important;
        .rightText{
            width: 550px;
        }
    }

    #warframes{
        background: url('/images/mainSiteAssets/gamePage/GamesPageHeader.jpg');
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-attachment: fixed;

        .container{
            padding-top: 16% !important;
        }

        h2{
            color: $pcRed;
            margin-top: 0px !important;
        }

        p{
            color: #000;
        }
    }

    #factions{
        height: auto !important;
        padding-bottom: 0 !important;
        background-attachment: inherit;

        .container{
            padding-top: 7%;

            h2{
                line-height: 37px;
            }

            @media (min-width: 851px) {
                width: 40%;
                max-width: 600px !important;
                margin: 120px auto 0 50vw;
                padding: 40px 60px;
                height: auto;
                background-color: rgba(0,0,0,.7);
                h2 {
                    margin-top: 0;
                }
            }

        }

        .factionContain, .factionControls{
            overflow: hidden;
            width: 100%;
        }
        #factionTweenContain{
            position: relative;
        }
        .factionControls{
            position: absolute;
            z-index: 20;
        }

        .factionContain{
            background-repeat: no-repeat;
            background-position: center center;
            background-attachment: fixed !important;
            height: 100%;
            background-size: cover;
        }

        .grineer{
            background: url('/images/mainSiteAssets/gamePage/FactionsGrineer.jpg');
        }
        .corpus{
            background: url('/images/mainSiteAssets/gamePage/FactionsCorpusBG.jpg');
        }
        .infested{
            background: url('/images/mainSiteAssets/gamePage/Factions_Infested.jpg');
        }
        .sentient{
            background: url('/images/mainSiteAssets/gamePage/Factions_Sentient.jpg');
        }

        #factionIconContain{
            position: absolute;
            width: 100%;
            text-align: center;
            &::after,&::before{
                display: none;
            }

            #factionIcons{
                position: relative;
                padding: 20px;
                margin: 0 auto;
                display: inline-block;
                top: -210px;
                z-index: 30;
                background-color: transparent;

                .factionIcon{
                    //@include transform(none);
                    transform: none;
                    height: 104px;
                    width: 104px;
                    float: left;
                    margin: 10px;
                    cursor: pointer;
                    border: none;

                    &::before{
                        display: none;
                    }

                    &.selected .factionIconBG{
                        background-position-y: -104px;
                    }
                }

                .factionIconBG{
                    background-image: url('/images/mainSiteAssets/gamePage/factionIcons.png');
                    background-repeat: no-repeat;
                    height: 104px;
                    width: 104px;
                    display: block;
                    background-position-y:0;
                }
                @each $faction, $x in $factionDesktop{
                    ##{$faction}Icon .factionIconBG{
                        background-position-x: $x;
                    }
                }
            }
        }
    }

    section{
        background-size: cover !important;
        height: 100%;
        padding-bottom: 19%;
        background-attachment: fixed;
        background-repeat: no-repeat;

        .container{
            color: #fff;
            position: relative;
            text-align: center;
            padding-top: 10%;

            h2{
                margin: 0 auto;
                margin-top: 50px;
                font-size: $titleSize;
                text-transform: uppercase;
                text-align: left;
            }

            h3{
                //font-family: 'Open Sans', sans-serif !important;
                text-transform: uppercase;
                //letter-spacing: 0.2em;
                font-size: 27px;
                margin-bottom: 34px;
            }

            p{
                font-size: 16px;
                line-height: 27px;
                font-weight: 300;
                margin-bottom: 18px;
                letter-spacing: 0.04em;
                text-align: left;
            }
            button{
                float: left;
                margin-left: 25px;

                @include mq(mobile){
                    margin-left: -15px;
                }
            }
        }
    }

    .rightText{
        float: right;
        margin-top: 5%;
    }

    .cd-hero.slider{
        border-bottom: none;

        .slide{
            max-height:990px;
        }
    }

    .cd-hero-slider{
        height:990px;
    }

    @include mq(mobile) {
        .seven.columns{
            width: 100%;
        }

        #quickstart,#warframes{
            .row{
                position: relative;
                margin-top: 20%;
            }
        }

        #factions{
            .factionContain {
                background-position-y: center;

                .container{
                    background: rgba(0,0,0,0.7);
                    width: 100%;
                    height: 100%;
                    padding-top: 0 !important;
                }
            }
            .grineer{
                background-position-x: -120px;
            }
            .corpus{
                background-position-x: -330px;
            }
            .infested{
                background-position-x: -210px;
            }
            .sentient{
                background-position-x: -270px;
            }

            #factionIconContain{
                margin-bottom: 80px;

                #factionIcons{
                    position: relative;
                    padding: 20px;
                    margin: 0 auto;
                    display: inline-block;
                    z-index: 30;
                    background-color: transparent;
                    bottom: 60px;
                    top:0;

                    .factionIcon{
                        height: 74px;
                        width: 74px;
                        overflow: hidden;
                        margin: 3px;

                        &.selected .factionIconBG{
                            background-position-y: -72px;
                        }

                        @each $faction, $x in $factionMobile{
                            ##{$faction}Icon .factionIconBG{
                                background-position-x: $x;
                            }
                        }
                    }

                    .factionIconBG{
                        height: 73px;
                        width: 75px;
                        background-size: 387% !important;
                        overflow: hidden;
                        background-position-y: 0;
                    }
                }
            }
        }

        section{
            background-attachment: inherit;
            background-position-y: 0 !important;

            .container{
                p,h2{
                    text-align: center;
                }
                h2{
                    margin: 40px auto;
                    font-size: 42px;
                    text-transform: uppercase;
                }
                button{
                    float:none;
                }
            }
        }

        .rightText{
            float:none;
            width: auto !important;
        }
    }

    @include mq(tablet) {
        #warframes{
            background-position-x: -160px;
            background-position-y: 0 !important;

            .container{
                background: rgba(255,255,255,0.7);
                height: 100%;
                width: 100%;

                .seven.columns{
                    width:100%;
                    padding: 20px;

                    p,h2{
                        text-align: center;
                    }

                    button{
                        float:none;
                    }
                }
            }
        }

        #keyart .container{
            color: #fff;
            text-align: center;
        }

        section .container h2{
            font-size: 25px;
            position: relative;
        }

        #factions{
            background-attachment: inherit;

            .grineer {
                background-position-x: -130px;
            }

            .corpus {
                background-position-x: center;
            }

            .infested {
                background-position-x: -360px;
            }

            .sentient {
                background-position-x: -410px;
            }

        }

    }
}
@media (max-width: 1370px) and (min-width: 1360px) {
  #factions {
    .grineer {
      background-position-x: 0px !important;
      background-position-y: 120px !important;
    }
  }
}


@media (max-width: 1024px) and (min-width: 1000px) {
  #keyart{
    background-attachment: inherit;
    background-position-x: -90px !important;
    background-position-y: 0px !important;

    .container{
      padding-top: 15% !important;
    }
  }


  #factions {

    .container{
      position: absolute;
      bottom: 0px;
      background: rgba(0, 0, 0, 0.7);
      padding-top: 0px !important;
      margin-top: 0px;
      padding-bottom: 170px;
      width: 100%;
      height: auto !important;
    }

    #factionIcons {
      top: -150px !important;
    }

    .grineer{
      background-position-x: 0px !important;
      background-position-y: 120px !important;
    }
    .corpus{
      background-position-x: 0px !important;
      background-position-y: 90px !important;
    }
    .infested{
      background-position-x: 0px !important;
      background-position-y: 120px !important;
    }
    .sentient{
      background-position-x: 0px !important;
      background-position-y: 120px !important;
    }
  }

  #warframes{
    background-attachment: inherit !important;
  }
}

@media (max-width: 850px) {

  body.game{

    #keyart{
      padding-bottom: 0px;
      h2{
        margin-top: -10px !important;
      }

      .container{
        background: rgba(255, 255, 255, 0.7);
        width: 100%;
        padding: 10%;
      }
    }

    #warframes {
      padding-bottom: 0px;
      .container {
        padding-top: 20px !important;
        .row {
          margin-top: -10px;
        }
      }
    }

    #factions {
      .cd-hero-slider {
        //max-height: 590px;
      }

      .container{
        position: absolute;
        bottom: 0px;
        background: rgba(0, 0, 0, 0.7);
        padding-top: 0px !important;
        margin-top: 0px;
        padding-bottom: 170px;
        width: 100%;
        height: auto !important;
      }


      @include mq(mobilelandscape) {
        #factionIcons {
          top: -70px !important;
        }
        .factionIcon {
          width: 73px !important;
        }

        #grineerIcon .factionIconBG {
          background-position-x: -72px !important;
        }

        #infestedIcon .factionIconBG {
          background-position-x: -145px !important;
        }

        #sentientIcon .factionIconBG {
          background-position-x: -218px !important;
        }
      }

      .grineer{
        background-position-x: 0px !important;
        background-position-y: 120px !important;
      }
      .corpus{
        background-position-x: 0px !important;
        background-position-y: 90px !important;
      }
      .infested{
        background-position-x: 0px !important;
        background-position-y: 120px !important;
      }
      .sentient{
        background-position-x: 0px !important;
        background-position-y: 120px !important;
      }


    }
    #warframes{
     .container{
       min-height: 400px;
       .row{
         margin-top: 25px !important;
       }
     }
    }
    @include mq(mobilelandscape) {
      #quickstart {
        button {
          margin-left: 15px;
          margin-right: 15px;
        }
      }
    }
  }
}

@media (max-width: 823px) and (min-width: 820px){
#factions{
  #factionIcons{
    top: -150px !important;
  }
}
}


@media (max-width: 700px) {

    body.game{

        #keyart{
            padding-bottom: 0px;
            h2{
                margin-top: -10px !important;
            }

            .container{
                background: rgba(255, 255, 255, 0.7);
                width: 100%;
                padding: 10%;
            }
        }

        #warframes {
            padding-bottom: 0px;
            .container {
                padding-top: 20px !important;
                .row {
                    margin-top: -10px;
                }
            }
        }

        #factions {
            .cd-hero-slider {
                //max-height: 590px;
            }

            .container{
                position: absolute;
                bottom: 0px;
                background: rgba(0, 0, 0, 0.7);
                padding-top: 0px !important;
                margin-top: 0px;
                padding-bottom: 170px;
                width: 100%;
                height: auto !important;
            }

            .factionIcon {
                width: 73px !important;
            }

            #grineerIcon .factionIconBG {
                background-position-x: -72px !important;
            }

            #infestedIcon .factionIconBG {
                background-position-x: -145px !important;
            }

            #sentientIcon .factionIconBG {
                background-position-x: -218px !important;
            }


            .grineer{
                background-position-x: -30px !important;
                background-position-y: 120px !important;
            }
            .corpus{
                background-position-x: -270px !important;
                background-position-y: 30px !important;
            }
            .infested{
                background-position-x: -130px !important;
                background-position-y: 120px !important;
            }
            .sentient{
                background-position-x: -150px !important;
                background-position-y: 120px !important;
            }

          @include mq(mobilelandscape) {
            .grineer{
              background-position-x: 0px !important;
              background-position-y: 120px !important;
            }
            .corpus{
              background-position-x: 0px !important;
              background-position-y: 90px !important;
            }
            .infested{
              background-position-x: 0px !important;
              background-position-y: 120px !important;
            }
            .sentient{
              background-position-x: 0px !important;
              background-position-y: 120px !important;
            }
          }


        }


        #quickstart{
            //margin-top: -80px;

            .row{
                //margin-top: 21%;
            }
        }
    }
}

@media (max-width: 320px) {
    .game{
        #mainContent{
            min-width: 320px;
        }

        #factionIconContain{
          margin-bottom: 120px !important;
        }
    }
}


.lang-fr {
    #gamepage {
        #keyart {
            button {
                height: 60px !important;
                max-width: 300px !important;

                .button-wrap {
                    white-space: pre-line;
                    line-height: 21px;
                }
            }
        }
    }
}
