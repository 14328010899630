@import "../style-guide/_responsive";
@import "../style-guide/_variables";

.AuthModal {

    .signupContent {
        position: relative;
    }

    .lightboxForm {
        margin: 20% auto 0;

        @include breakpoint-mobile-only {
            margin: 0;
        }

        .restrictedSignup {
            color: white;
            text-align: center;
        }

        @media screen and (min-width: 48em) {
            margin-top: 0;
        }

        a {
            color: white;

            &:hover {
                color: #d30000;
            }
        }

        .readRulesBox {
            width: 60%;
            margin: 0 auto;
            margin-bottom: 30px;

            @include mq(tablet) {
                width: 100%;
            }
        }

        .signupContent {
            width: 100%;
            color: white;
            top: 0;

            @include platform-selector;

            .recaptcha-check {
                width: 310px;
                margin: 20px auto;
            }

            fieldset#opt-in-newsletter, fieldset#agree, fieldset#contest, fieldset#read-rules {
                width: 80%;
                margin: 0 auto;
                margin-bottom: 8px;
                line-height: 18px;
            }

            input::-webkit-input-placeholder {
                color: white;
            }

            input[type="text"], input[type="email"], input[type="password"] {
                background: transparent;
                border: none;
                border-bottom: 1px solid white;
                color: #fff;
                border-radius: 0 !important;
            }
        }
    }


    .lightboxHolder {
        width: 80%;
        margin-left: 10%;

        @media screen and (min-width: 48em) {
            width: 60%;
            margin-left: 20%;
        }
        @media screen and (min-width: 64em) {
            width: 50%;
            margin-left: 25%;
        }
        @media screen and (min-width: 90em) {
            &#signupLightbox, &#enterContestLightbox {
                width: 30%;
                margin-left: 35%;
            }
        }

        .contest-box {
            width: 90%;
            margin: 20px auto;

            div {
                margin-bottom: 10px;
            }
        }

        &#loginLightbox {

            @media screen and (min-width: 1400px) {
                .contestEnter {
                    width: 60%;
                    margin: 0 auto;
                }
            }

            p {
                color: white;
            }

            #loginPage #mainText {
                margin-top: 10%;

                .text-content {
                    p {
                        line-height: unset;
                        transform: translateY(-50%);
                        margin-bottom: 10px;
                    }
                }
            }

            .contentHeaderLines {
                width: 20% !important;
            }
        }
    }

    .consoleLoginBtns {
        text-align: center;

        a {
            width: 32%;
            height: 55px;
            padding-top: 8px;
            min-width: 130px;
            display: inline-block;
            text-align: center;

            img {
                width: auto !important;
                //margin-top: 6px;
            }

            @media (max-width: 600px) {
                width: 100%;
            }

            &.ps4Btn {
                background-color: #274368;
            }

            &.xb1Btn {
                background-color: #2f5212;
            }

            &.swiBtn {
                background-color: #e70012;
                position: relative;
                top: 1px;
                margin-bottom: 50px;
            }
        }
    }

    form {
        fieldset label.error {
            color: #ff2323;
            margin-bottom: 10px;
            font-weight: bold !important;
        }

        #email label, #email-login label, #confirm-email label, #alias label, #password label, #password-login label, #confirm-password label {
            display: none;
        }

        #opt-in-newsletter label, #contest label, #agree label, #read-rules label {
            display: inline;
            margin-bottom: .5rem;
            font-weight: 100;

            a {
                color: #fff;
                text-decoration: underline;
                font-family: inherit;
                text-decoration-color: #fff;
            }
        }

        #opt_in_newsletter_check {
            margin-bottom: 0;
        }

        #agreed {
            margin-bottom: 0 !important;
        }
    }

    input.error {
        border: 1px solid #FF2323 !important;
    }

    .consoleLines {
        margin: 15px 0;

        .contentHeaderText {
            color: white;
            text-align: center;
            position: relative;
            top: -17px;
            text-transform: uppercase;
            font-size: 24px;
            padding: 0 25%;
        }

        &.contentHeaderContain .contentHeaderLines {
            border-top: 1px solid #FFF;
            border-bottom: 1px solid #FFF;
            width: 22% !important;
            height: 3px;
            float: left;

            @include mq(tablet) {
                display: none;
            }
        }

        .contentLineRight {
            float: right !important;
        }
    }

    .lightboxLogo {
        position: relative;
        transform: translateX(-50%);
        left: 50%;
        width: 100%;
        max-width: 462px;
    }
}

@import "../account/_login";
